import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Grid } from "semantic-ui-react";
import {
  accordionTripQuest,
  addOrRemovePassenger,
  addOrRemoveTripLeg,
  newPassengerFieldValues,
} from "store/actions/ui/eas-book-trip";
import PassengerRow from "./PassengerRow";

let passengerRow = 1;

class WhoIsFlyingWithYou extends Component {
  componentDidMount() {
    const maxPassenger = this.props.maxPassenger;
    const steps = this.props.steps;
    if (steps[0] === "who") {
      this.props.openAccordion({ actionType: "RESET" });
    }
    if (this.props.fromStep === "legs") {
      passengerRow = 1;
      this.props.resetPassengerRequest(
        this.props.firstPassengerName,
        this.props.firstPassenger
      );
      if (maxPassenger > 1) {
        for (var i = 0; i < maxPassenger - 1; i++) {
          this.execAddOrRemovePassenger();
        }
      }
    }
  }

  execAddOrRemovePassenger() {
    passengerRow++;
    this.props.addOrRemovePassenger({
      actionType: "ADD",
      passengerRows: passengerRow,
      passengerName: "Passenger_" + passengerRow,
    });
  }

  addMorePassenger() {
    return () => {
      this.execAddOrRemovePassenger();
    };
  }
  render() {
    const passengerChildren = [];
    Object.keys(this.props.rowPassengers).forEach((rowPassenger, key) => {
      const passengerNumber = rowPassenger.substr(rowPassenger.length - 1);
      passengerChildren.push(
        <PassengerRow
          passengerOrder={key + 1}
          passengerNumber={passengerNumber}
          key={key}
          passengerName={rowPassenger}
          passengerProps={this.props}
        />
      );
    });
    return (
      <div className="tripWidth">
        <div className="passengerSection">
          <Grid.Row>
            <Container className="noPaddingTop">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div className="tripTitle">Who is flying with you?</div>
                  </Grid.Column>
                </Grid.Row>
                {passengerChildren}
                <div className="passengerWidth noMarginTop">
                  <button
                    className="button-wrapper addLegPass"
                    onClick={this.addMorePassenger()}
                  >
                    Add Passenger
                  </button>
                </div>
              </Grid>
              <Grid className="btnSubmit">
                <Grid.Row>
                  <Grid.Column className="right floated">
                    <Button
                      secondary
                      onClick={() => {
                        this.props.prevStep();
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.nextStep({ step: "summary", from: "who" });
                      }}
                      primary
                    >
                      Next
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

WhoIsFlyingWithYou.contextTypes = {
  router: PropTypes.object,
};

WhoIsFlyingWithYou.propTypes = {
  resetPassengerRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  let firstPassenger =
    Object.keys(state.ui.addPassenger.passengers).length > 0
      ? state.ui.addPassenger.passengers[
          Object.keys(state.ui.addPassenger.passengers)[0]
        ]
      : null;
  return {
    requestResult: state.services.easTripRequest.tripRequest.body.result,
    loggedInUser: state.services.easUsers.loggedIn.user,
    rowPassengers: state.ui.addPassenger.passengers,
    rowPassengersFields: state.ui.addPassenger.fields,
    steps: state.ui.steps,
    fromStep: state.ui.steps["from"],
    firstPassenger: firstPassenger,
    firstPassengerName:
      Object.keys(state.ui.addPassenger.passengers).length > 0
        ? Object.keys(state.ui.addPassenger.passengers)[0]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newPassengerFieldValues: (fieldAndValue) => {
    dispatch(newPassengerFieldValues(fieldAndValue));
  },
  addOrRemoveTripLeg: (payload) => {
    dispatch(addOrRemoveTripLeg(payload));
  },
  resetPassengerRequest: (passengerName, firstPassenger) => {
    dispatch(
      addOrRemovePassenger({
        passengerName: passengerName,
        actionType: "RESET",
      })
    );
  },
  addOrRemovePassenger: (payload) => {
    dispatch(addOrRemovePassenger(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhoIsFlyingWithYou);
