import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { execNoShadeGetUser } from "store/actions/ui/eas-users";
import ActivityRow from "./ActivityRow";
import withRouter from "components/withRouter";

const toSafeString = (input) => (input ? input : "");

class UserTransactions extends Component {
  componentDidMount() {
    this.props.fetchUserNoShade(this.props.userId);
  }

  sortDate(auditsDate) {
    return auditsDate.sort(
      (aNum, bNum) => new Date(bNum.dateUpdated) - new Date(aNum.dateUpdated)
    );
  }
  groupByDate(audits) {
    const dateGroup = {};
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const sortAudits = this.sortDate(audits);

    sortAudits.forEach((item) => {
      const dateupdated = new Date(item.dateUpdated);
      const currentYear = new Date().getFullYear();
      const auditYear = dateupdated.getFullYear();
      let monthAndYear = `${
        monthNames[dateupdated.getMonth()]
      } ${dateupdated.getDate()}`;

      if (auditYear < currentYear) {
        monthAndYear = `${
          monthNames[dateupdated.getMonth()]
        } ${dateupdated.getDate()}, ${auditYear}`;
      }
      if (typeof dateGroup[monthAndYear] == "undefined") {
        dateGroup[monthAndYear] = [];
      }
      dateGroup[monthAndYear].push(item);
    });
    return dateGroup;
  }

  renderActivityLogs(groupAudits) {
    return Object.keys(groupAudits).map((monthdate, key) => (
      <div key={key} className="activity-screen">
        <div className="date-group">
          <h3>{monthdate}</h3>
          {groupAudits[monthdate].map((log, logkey) => (
            <ActivityRow key={logkey} log={log} user={this.props.user} />
          ))}
        </div>
      </div>
    ));
  }

  render() {
    const user = this.props.user;

    if (user) {
      const audits = this.props.user.audit;
      const groupAudits = this.groupByDate(audits);

      return <div>{this.renderActivityLogs(groupAudits)}</div>;
    }
    return null;
  }
}

UserTransactions.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.object,
  fetchUserNoShade: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const easeUser = state.services.easUsers.current.user;

  return {
    userId: ownProps.router.params.userId,
    user: easeUser
      ? {
          audit: easeUser.audit,
          firstName: toSafeString(easeUser.firstName),
          lastName: toSafeString(easeUser.lastName),
          userSpecifiedAccount: toSafeString(easeUser.userSpecifiedAccount),
          emailAddress: toSafeString(easeUser.emailAddress),
        }
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserNoShade: (userId) => {
    dispatch(execNoShadeGetUser(userId));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserTransactions)
);
