import FileSaver from "file-saver";
import ical from "ical-generator";
import iconNoFlight from "images/ic_local_airport_no_flight.png";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import withRouter from "components/withRouter";
import { Link } from "react-router-dom";
import { paths } from "routes";

import {
  Button,
  Card,
  Container,
  Dropdown,
  Grid,
  Image,
  List,
  Loader,
  Menu,
  Modal,
  Segment,
} from "semantic-ui-react";
import * as actions from "store/actions/services/firebase-user";
import {
  closeConfirmedModal,
  execNotShadedGetUserFlightSurveys,
} from "store/actions/ui/eas-flight-surveys.js";
import {
  accordionFlightDetail,
  execNotShadedGetItineraryForTrip,
  execNotShadedSearchTrips,
  lightBoxIsOpen,
  selectFlightDetail,
} from "store/actions/ui/eas-trips";
import { execNoShadeGetUser } from "store/actions/ui/eas-users";
import { popGiveFeedback } from "store/actions/ui/popups";
import ContentFrame from "../../components/ContentFrame/ContentFrame";
import FlightDetailLoading from "./FlightDetailLoading";
import FlightListLoading from "./FlightListLoading";
import FlightRow from "./FlightRow";
import FlightDetail from "./FlightDetail";

let firstLegSelected = null;
let UPCOMING_MONTH = 3;
let PREVIOUS_MONTH = 6;
let LOADINGFLAG = true;
const UPCOMING = "upcoming";
const PREVIOUS = "previous";
let isLoadMore = false,
  triplegs = [],
  countLeg = 0,
  countLegData = 0,
  countAccount = 0,
  loadMoreTimer,
  isSelectedFlight = false;

class Flights extends Component {
  componentWillUnmount() {
    this.props.selectFlightDetail(null);
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedFlight,
      selectedLeg,
      mineOrAll,
      timeRange,
      isOpeningConfirmedModal,
      location,
      selectFlightDetail,
    } = this.props;

    let flightNumber = selectedFlight && selectedFlight.flightNumber;
    let legNumber = selectedLeg.legId;

    const query = queryString.parse((location && location.search) || "");

    if (flightNumber && legNumber) {
      this.goTo(mineOrAll, timeRange, {
        flight: flightNumber + "-" + legNumber,
      });
      if (query.flight) {
        var elem = document.getElementById(query.flight);
        if (elem && !isSelectedFlight && !isOpeningConfirmedModal) {
          elem.scrollIntoView();
        }
      }
    }

    if (
      mineOrAll !== prevProps.mineOrAll ||
      timeRange !== prevProps.timeRange
    ) {
      selectFlightDetail(null);
      this.resetLoadMonth();
      this.execGetTripLegs(timeRange, false, true);
    }
  }

  goTo = (mineOrAll, timeRange, query) => {
    const { router, location } = this.props;
    const queryStr = queryString.stringify(query);
    const search = queryStr ? `?${queryStr}` : undefined;

    console.log('..',this.props)
    if (
      router.params.mineOrAll === mineOrAll &&
      router.params.timeRange === timeRange &&
      router.location.search === search
    ) {
      return;
    }

    router.navigate({
      pathname: paths.build.flightsMineOrAllWithTime(mineOrAll, timeRange),
      search,
    });
  };

  switchTab = (tab) => {
    const { router, mineOrAll } = this.props;
    router.navigate(paths.build.flightsMineOrAllWithTime(mineOrAll, tab));
  };

  resetLoadMonth() {
    triplegs = [];
    UPCOMING_MONTH = 3;
    PREVIOUS_MONTH = 6;
    countLeg = 0;
    countLegData = 0;
    isLoadMore = false;
  }

  limitLoadMore(endYear, type) {
    if (type === PREVIOUS) {
      return (
        endYear >=
        moment
          .utc()
          .subtract(this.props.maximumHistoryPages, "year")
          .format("YYYY")
      );
    } else if (type === UPCOMING) {
      return (
        endYear <=
        moment.utc().add(this.props.maximumHistoryPages, "year").format("YYYY")
      );
    }
  }

  componentDidMount() {
    this.props.fetchFlightSurveys(this.props.userId);
    this.resetLoadMonth();
    this.execGetTripLegs(this.props.timeRange, 0, false);
    this.props.fetchUser(this.props.userId);
  }

  handleScroll(e) {
    if (
      LOADINGFLAG &&
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 300
    ) {
      if (loadMoreTimer) {
        window.clearTimeout(loadMoreTimer);
      }
      loadMoreTimer = setTimeout(() => {
        console.log("onscroll");
        this.execGetTripLegs(this.props.timeRange, 3, false);
      }, 100);
    }
  }

  execGetTripLegs(type, loadMoreMonth, isShade) {
    let isGetTrips = true;
    let startDate, endDate;
    if (loadMoreMonth > 0) {
      isLoadMore = true;
      LOADINGFLAG = false;
    }
    if (type === PREVIOUS) {
      startDate = moment().subtract(PREVIOUS_MONTH, "months");
      endDate = moment();
      if (loadMoreMonth > 0) {
        startDate = moment().subtract(PREVIOUS_MONTH + loadMoreMonth, "months");
        endDate = moment()
          .subtract(PREVIOUS_MONTH, "months")
          .subtract(1, "days");
        isGetTrips = this.limitLoadMore(startDate.format("YYYY"), type);
      }
    } else if (type === UPCOMING) {
      startDate = moment();
      endDate = moment().add(UPCOMING_MONTH, "months");
      if (loadMoreMonth > 0) {
        startDate = moment().add(UPCOMING_MONTH, "months").add(1, "days");
        endDate = moment().add(UPCOMING_MONTH + loadMoreMonth, "months");
        isGetTrips = this.limitLoadMore(endDate.format("YYYY"), type);
      }
    }
    if (isGetTrips) {
      this.props.searchTrips(
        this.props.userId,
        startDate.format("MM-DD-YYYY"),
        endDate.format("MM-DD-YYYY"),
        isLoadMore,
        false
      );
      if (loadMoreMonth > 0) {
        if (type === PREVIOUS) {
          PREVIOUS_MONTH = PREVIOUS_MONTH + loadMoreMonth;
        } else if (type === UPCOMING) {
          UPCOMING_MONTH = UPCOMING_MONTH + loadMoreMonth;
        }
      }
    }
  }

  countAssociatedWithAccount(accounts, astroUserIds) {
    let countAccount = 0;
    if (astroUserIds.length > 0) {
      astroUserIds.forEach((astroUser) => {
        astroUser.accounts.forEach((astroAccount) => {
          accounts.forEach((account) => {
            if (astroAccount.accountId === account.accountId) {
              countAccount++;
            }
          });
        });
      });
    }
    return countAccount;
  }

  detectAccountIsPassenger(accounts, passengers) {
    let isPassenger = false;
    if (accounts) {
      accounts.forEach((account) => {
        if (passengers) {
          passengers.forEach((passenger) => {
            if (account.astroUserId === passenger.userId) {
              isPassenger = true;
            }
          });
        }
      });
    }
    return isPassenger;
  }

  checkLegHasData(groupLegs) {
    if (groupLegs && Object.keys(groupLegs).length > 0) {
      Object.keys(groupLegs).forEach((departureDate, key) => {
        if (groupLegs[departureDate].length > 0) {
          countLeg++;
        }
      });
    }
    return countLeg;
  }

  displayConfirmedModal(groupLegs) {
    let isOpened = false;
    groupLegs.forEach((leg, legkey) => {
      if (leg.legId === this.props.tripLegId) {
        isOpened = true;
      }
    });
    return this.props.isOpeningConfirmedModal && isOpened;
  }

  viewFlightDetail(leg) {
    isSelectedFlight = true;
    //this.props.getItineraryForTrip()
    return this.props.selectFlightDetail(leg);
  }

  openFlightDetail = (leg) => {
    this.goTo(this.props.mineOrAll, this.props.timeRange, {
      flight: leg.flight
        ? leg.flight.flightNumber + "-" + leg.leg.legId
        : leg.leg.trip.astroId + "-" + leg.leg.legId,
    });
    this.viewFlightDetail(leg);
  };

  renderLegs(groupLegs, accounts) {
    countLegData = this.checkLegHasData(groupLegs);
    if (accounts) {
      countAccount = this.countAssociatedWithAccount(
        accounts,
        this.props.user.astroUserIds
      );
    }
    if (groupLegs && countLegData > 0 && Object.keys(groupLegs).length > 0) {
      return Object.keys(groupLegs).map((departureDate, key) => {
        //get 5 legs on view
        if (groupLegs[departureDate].length > 0) {
          const displayConfirmedModal = this.displayConfirmedModal(
            groupLegs[departureDate]
          );
          return (
            <div key={key}>
              {displayConfirmedModal && this.props.isConfirmedModal ? (
                <Card className="confirmedFeedback">
                  <Card.Content>
                    <Card.Header>
                      Thanks for your feedback
                      <span
                        className="closeConfirmBox"
                        onClick={() => this.props.closeConfirmedModal(false)}
                      ></span>
                    </Card.Header>
                    <Card.Description>
                      Your feedback is very valuable to us and we are committed
                      to making sure you’re a satisifed customer.
                    </Card.Description>
                  </Card.Content>
                </Card>
              ) : null}

              <List.Item key={key} className="trip-item">
                <Segment>
                  <h3>{departureDate}</h3>
                  {groupLegs[departureDate].map((leg, legkey) => {
                    if (leg) {
                      const isPassenger = this.detectAccountIsPassenger(
                        accounts,
                        leg.passenger
                      );
                      const flightSurveys = this.props.flightSurveys;
                      return (
                        <FlightRow
                          key={legkey}
                          leg={leg}
                          isSetIdentifier={true}
                          activedLegId={this.props.activedLegId}
                          surveyLegIds={this.props.surveyLegIds}
                          isPassenger={isPassenger}
                          flightSurveys={flightSurveys}
                          countAccount={countAccount}
                          openFeedBackPopup={(
                            fromCity,
                            toCity,
                            flightNumber,
                            tripLegId
                          ) =>
                            this.props.openFeedBackPopup(
                              fromCity,
                              toCity,
                              flightNumber,
                              tripLegId
                            )
                          }
                          openFlightDetail={this.openFlightDetail}
                        />
                      );
                    }
                    return null;
                  })}
                </Segment>
              </List.Item>
            </div>
          );
        }

        return null;
      });
    } else {
      if (this.props.timeRange === UPCOMING) {
        return (
          <Card className={"no-flight"}>
            {!this.props.isLoadMoreDone ? (
              <FlightListLoading className="flightListLoader" />
            ) : null}
            <Card.Content className="contentLoader">
              <Card.Header>
                <div className="title">
                  {this.props.isLoadMoreDone ? (
                    <div className="flightIcon">
                      <Image src={iconNoFlight} />
                      No Upcoming Flights
                    </div>
                  ) : null}
                </div>
              </Card.Header>
            </Card.Content>
          </Card>
        );
      } else if (this.props.timeRange === PREVIOUS) {
        return (
          <Card className={"no-flight"}>
            {!this.props.isLoadMoreDone ? (
              <FlightListLoading className="flightListLoader" />
            ) : null}
            <Card.Content className="contentLoader">
              <Card.Header>
                <div className="title">
                  {this.props.isLoadMoreDone ? (
                    <div className="flightIcon">
                      <Image src={iconNoFlight} />
                      No Previous Flights
                    </div>
                  ) : null}
                </div>
              </Card.Header>
            </Card.Content>
          </Card>
        );
      }
    }
  }

  sendMailModifyTripRequest(props, tripNumber) {
    const windowRef = window.open(
      "mailto:modify-trip@execairshare.com?subject=Trip Modification Request for Trip #" +
        tripNumber,
      "_blank"
    );
    windowRef.focus();

    setTimeout(function () {
      if (windowRef.document && windowRef.document.hasFocus()) {
        windowRef.close();
        props.handleResetPassWordModal(true);
      } else {
        windowRef.close();
      }
    }, 500);
  }

  insertEventToCalendar(props, leg) {
    let email = props.user.emailAddress;
    let startDate,
      endDate,
      location,
      title =
        "Flight to " +
        leg.destinationAirportCity +
        " (" +
        leg.trip.tripNumber +
        ")";
    leg.FBO.forEach((fbo) => {
      if (fbo.type === "origin") {
        location =
          fbo.name +
          ", " +
          fbo.address +
          ", " +
          fbo.city +
          ", " +
          fbo.state +
          ", " +
          fbo.postalCode +
          ", " +
          fbo.country;
      }
    });
    if (leg.trip.tripStatus === "Approved") {
      startDate = leg.departureTime;
      endDate = leg.arrivalTime;
    } else if (leg.trip.tripStatus === "Closed") {
      startDate = leg.flights[0].departureTime;
      endDate = leg.flights[0].arrivalTime;
    } else {
      // tripStatus == 'Confirmed'
      if (leg.flights[0]) {
        if (leg.flights[0].flightStatus === "Not Released") {
          startDate = leg.departureTime;
          endDate = leg.arrivalTime;
        } else if (leg.flights[0].flightStatus === "Released") {
          startDate = leg.flights[0].ETD;
          endDate = leg.flights[0].ETA;
        } else {
          startDate = leg.flights[0].departureTime;
          endDate = leg.flights[0].arrivalTime;
        }
      } else {
        startDate = leg.departureTime;
        endDate = leg.arrivalTime;
      }
    }
    const cal = ical();
    const event = cal.createEvent({
      start: startDate,
      end: endDate,
      summary: title,
      location: location,
      organizer: "Organizer's Name <" + email + ">",
    });
    event.createAlarm({ type: "display", trigger: 600 });
    const icsFile = new File([cal.toString()], title + " .ics", {
      type: "text/calendar;charset=utf-8",
    });
    FileSaver.saveAs(icsFile);


  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  render() {
    const { timeRange, mineOrAll, accounts } = this.props;

    const isBookerOrOwner = accounts
      ? accounts.some(
          (account) =>
            account.role.toLowerCase() === "booker" ||
            account.role.toLowerCase() === "owner"
        )
      : null;
      console.log(accounts)

    if (!["upcoming", "previous"].includes(timeRange)) {
      return <Navigate to={paths.build.root()} />;
    }

    if (mineOrAll === "all" && isBookerOrOwner === false) {
      return (
        <Navigate
          to={paths.build.flightsMineOrAllWithTime("mine", timeRange)}
        />
      );
    }

    const flightOptions = isBookerOrOwner
      ? [
          {
            key: "mine",
            value: "mine",
            text: "My Flights",
            selected: mineOrAll === "mine",
          },
          {
            key: "all",
            value: "all",
            text: "All Flights",
            selected: mineOrAll === "all",
          },
        ]
      : [
          {
            key: "mine",
            value: "mine",
            text: "My Flights",
            selected: true,
          },
        ];

    triplegs = [];
    let convertToBlob = null;

    triplegs = this.props.triplegs;
    if (this.props.itineraryFile) {
      convertToBlob = this.b64toBlob(
        this.props.itineraryFile,
        "application/pdf"
      );
    }

    return (
      <ContentFrame>
        <div className="flightScreen">
          <Modal
            className="forgotModal"
            size="tiny"
            open={this.props.isOpenModal}
            onClose={() => this.props.handleResetPassWordModal(false)}
          >
            <Modal.Header>No Mail Client Detected</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>Device does not contain a valid mail application.</p>
              </Modal.Description>
              <Button
                className="checkEmail"
                onClick={() => this.props.handleResetPassWordModal(false)}
              >
                CLOSE
              </Button>
            </Modal.Content>
          </Modal>
          <Container fluid className="flightHeader">
            <Grid.Row>
              <Container>
                <Grid columns={2} divided="vertically">
                  <Grid.Row>
                    <Grid.Column width={9}>
                      <Dropdown
                        defaultValue={mineOrAll}
                        options={flightOptions}
                        onChange={(event, data) => {
                          this.goTo(data.value, this.props.timeRange);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width={7}>
                      {isBookerOrOwner &&
                      this.props.user.status === "Authorized" ? (
                        <Link to={paths.build.bookATrip()}>
                          <Button className="right floated">New Trip</Button>
                        </Link>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Grid.Row>
          </Container>
          <Container className="paddingTop fill-height flex">
            <Grid.Row className="fill-height">
              <Grid columns={2} divided="vertically" className="flexContainer">
                <Grid.Row className="displayFlex">
                  <Grid.Column
                    className={
                      this.props.selectedFlight !== 0
                        ? "cardDetail"
                        : "cardDetail noMarginBottom hiddenLoading"
                    }
                    mobile={16}
                    computer={10}
                    tablet={11}
                  >
                    {this.props.selectedFlight !== 0 ? (
                      <FlightDetail
                        insertEventToCalendar={this.insertEventToCalendar}
                        convertToBlob={convertToBlob}
                        props={{
                          ...this.props,
                          goTo: this.goTo,
                          openFlightDetail: this.openFlightDetail,
                        }}
                        sendMailModifyTripRequest={
                          this.sendMailModifyTripRequest
                        }
                      />
                    ) : this.props.isLoadMoreDone ? (
                      <div className="noFlightSelected hideOnMobile">
                        No Flights Selected
                      </div>
                    ) : (
                      <div>
                        <FlightDetailLoading />
                      </div>
                    )}
                  </Grid.Column>
                  <div
                    className={
                      this.props.selectedFlight === 0
                        ? "flexColumn flightDetailHidden"
                        : "flexColumn hideFlightList"
                    }
                    onScroll={this.handleScroll.bind(this)}
                  >
                    <Grid.Column
                      mobile={16}
                      computer={6}
                      tablet={4}
                      className="noPaddingRight navMenu fill-height"
                    >
                      <Menu pointing secondary>
                        <Menu.Item
                          name="UPCOMING"
                          active={this.props.timeRange === "upcoming"}
                          onClick={() => {
                            firstLegSelected = null;
                            this.switchTab("upcoming");
                          }}
                        />
                        <Menu.Item
                          name="PREVIOUS"
                          active={this.props.timeRange === "previous"}
                          onClick={() => {
                            firstLegSelected = null;
                            this.switchTab("previous");
                          }}
                        />
                      </Menu>
                      <List className={"flightCards fill-height"}>
                        {this.renderLegs(triplegs, accounts)}
                        {this.props.isLoadMore ? (
                          <Loader className="moreLoader" active />
                        ) : null}
                      </List>
                    </Grid.Column>
                  </div>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Container>
        </div>
      </ContentFrame>
    );
  }
}

Flights.contextTypes = {
  router: PropTypes.object,
};

Flights.propTypes = {
  trips: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchFlightSurveys: PropTypes.func.isRequired,
  searchTrips: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  mineOrAll: PropTypes.string.isRequired,
  timeRange: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const flightSurvey = state.services.easFlightSurveys.surveys;
  
  const { mineOrAll, timeRange } = ownProps.router.params;

  return {
    user: state.services.easUsers.loggedIn.user,
    triplegs: state.services.easTrips.allTrip.list,
    flightSurveys: state.services.easFlightSurveys.surveys.list,
    accounts: state.services.easUsers.loggedInAccounts.list,
    loading: state.services.easTrips.allTrip.loading,
    failed: state.services.easTrips.allTrip.failed,
    userId: state.services.firebase.firebaseUser.response.uid,
    isLoadMoreDone: state.services.easTrips.allTrip.isLoadMoreDone,
    isLoadMore: state.services.easTrips.allTrip.isLoadMore,
    maximumHistoryPages: state.services.easTrips.allTrip.maximumHistoryPages,
    isOpeningConfirmedModal: flightSurvey.body
      ? state.services.easFlightSurveys.surveys.body
      : false,
    surveyLegIds: flightSurvey.body ? flightSurvey.surveyLegIds : [],
    isConfirmedModal: state.ui.flightSurveyForm.confirmedModal.isConfirmedModal,
    selectedLeg: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.leg
      : null,
    lightBoxOpen: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.isOpen
      : false,
    lightBoxType: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.lightBoxType
      : "",
    photoIndex: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.photoIndex
      : 0,
    selectedFlight: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.flight
      : null,
    aircraftImages: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.aircraftImages
      : [],
    activedLegId: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.activedLegId
      : 0,
    countAccount: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.countAccount
      : 0,
    accordionActive: state.ui.flights.cardDatail
      ? state.ui.flights.cardDatail.accordionActive
      : 0,
    mineOrAll,
    timeRange,
    itineraryFile: state.ui.itinerary.content,
    isOpenModal: state.ui.resetPassword.resetPasswordModal.isOpen,
    tripLegId: state.services.easFlightSurveys.surveys.tripLegId
      ? state.services.easFlightSurveys.surveys.tripLegId
      : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openFeedBackPopup: (fromCity, toCity, tripNumber, tripLegId) => {
    dispatch(popGiveFeedback({ fromCity, toCity, tripNumber, tripLegId }));
  },
  fetchUser: (userId) => {
    dispatch(execNoShadeGetUser(userId));
  },
  fetchFlightSurveys: (userId) => {
    dispatch(execNotShadedGetUserFlightSurveys(userId));
  },
  searchTrips: (userId, startDate, endDate, isLoadMore, isShade) => {
    const query = queryString.parse(ownProps.router.location.search);
    const { mineOrAll, timeRange } = ownProps.router.params;

    dispatch(
      execNotShadedSearchTrips({
        userId,
        startDate,
        endDate,
        isLoadMore,
        mineOrAll,
        timeRange,
      })
    ).then((result) => {
      if (!isLoadMore) {
        dispatch(lightBoxIsOpen(null));
        if (query.flight) {
          Object.keys(result).forEach((leg) => {
            result[leg].forEach((obj) => {
              const [flightNumber, legId] = query.flight
                ? query.flight.split("-")
                : null;
              if (
                obj.flights[0] &&
                obj.flights[0].flightNumber === flightNumber &&
                obj.legId === legId
              ) {
                firstLegSelected = obj;
              }
            });
          });
        }

        try {
          firstLegSelected = firstLegSelected
            ? firstLegSelected
            : result[Object.keys(result)[0]][0] || null;
        } catch (err) {
          console.log("First leg not found");
        }

        if (
          (!query.flight && firstLegSelected) ||
          (query.flight && query.flight !== "user-closed" && firstLegSelected)
        ) {
          dispatch(
            execNotShadedGetItineraryForTrip(firstLegSelected.trip.astroId)
          );
          if (firstLegSelected.aircraftImages.length > 0) {
            actions
              .getImageFromFireBase(firstLegSelected.aircraftImages)
              .then((urls) => {
                dispatch(actions.firebaseImageUrl(urls));
              });
          } else {
            dispatch(actions.firebaseImageUrl(null));
          }
          dispatch(
            selectFlightDetail({
              leg: firstLegSelected,
              flight:
                firstLegSelected.flights.length > 0
                  ? firstLegSelected.flights[0]
                  : null,
              countAccount: countAccount,
            })
          );
        }
      }
      LOADINGFLAG = true;
    });
  },
  searchAccount: (userId) => dispatch(execNotShadedGetItineraryForTrip(userId)),
  closeConfirmedModal: (isClosed) => dispatch(closeConfirmedModal(isClosed)),
  selectFlightDetail: (legObj) => {
    dispatch(lightBoxIsOpen(null));
    if (legObj) {
      dispatch(execNotShadedGetItineraryForTrip(legObj.leg.trip.astroId));
      if (legObj.leg.aircraftImages.length > 0) {
        actions.getImageFromFireBase(legObj.leg.aircraftImages).then((urls) => {
          dispatch(actions.firebaseImageUrl(urls));
        });
      } else {
        dispatch(actions.firebaseImageUrl(null));
      }
    }

    dispatch(selectFlightDetail(legObj));
  },
  openLightBox: (lightBox) => {
    dispatch(lightBoxIsOpen(lightBox));
  },
  openAccordionFlightDetail: (accordionIndex) => {
    dispatch(accordionFlightDetail(accordionIndex));
  },
  handleResetPassWordModal: (isOpen) => {
    dispatch(actions.passwordResetEmailModal(isOpen));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Flights)
);
