import ContentFrame from "components/ContentFrame/ContentFrame";
import imageUnderConstruction from "images/under_construction.png";
import { useNavigate } from "react-router";
import { paths } from "routes";

export default function UnderConstruction() {
  const history = useNavigate();

  const goToHomePage = () => {
    history(paths.home);
  };

  return (
    <ContentFrame>
      <div className="pending-screen">
        <div className="verify-block">
          <img src={imageUnderConstruction} alt="" />
          <b>Under Construction</b>
          <p>Flights will be here soon. </p>
          <button onClick={goToHomePage} className="buttonStyle align-center">
            Home page
          </button>
        </div>
      </div>
    </ContentFrame>
  );
}
