import dotEnv from "environment-config";
import { createAction } from "redux-actions";
import { v1 } from "uuid";
import { ASTRO_USER_ACCOUNTS } from "../types";
import { endAsync, startAsync } from "../ui/async-operation";

const astroUserUrl = (astroUserId) =>
  `${dotEnv.apiUrl}/astroUsers/${astroUserId}/accounts`;

export const astroUserAccounts = createAction(ASTRO_USER_ACCOUNTS);

export const execGetAstroUserAccounts = (astroUserId) => (
  dispatch,
  getState
) => {
  const opId = v1();

  dispatch(startAsync({ id: opId, needsShade: true }));
  dispatch(astroUserAccounts(astroUserId));
  return fetch(astroUserUrl(astroUserId), {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(new Error("Error getting astro user accounts"));
    })
    .then((json) => {
      const accountsForAstroId = json.accounts;

      dispatch(
        astroUserAccounts({ astroUserId, accounts: accountsForAstroId })
      );
      dispatch(endAsync({ id: opId }));
    })
    .catch((error) => {
      dispatch(endAsync({ id: opId, errorMessage: error.message }));
      return Promise.reject(error);
    });
};
