import { analyticsEvent } from "analytics/gtag";
import iconAirplane from "images/airplane.svg";
import iconLocalAirport from "images/ic_local_airport@3x.png";
import moment from "moment";
import { Button, Divider, Grid } from "semantic-ui-react";

const FlightItem = ({
  isSetIdentifier,
  openFeedBackPopup,
  countAccount,
  surveyLegIds,
  leg,
  isPassenger,
  activedLegId,
  flightSurveys,
  flight,
  openFlightDetail,
  calFlightTime,
}) => {
  let tripStatus = leg.trip.tripStatus;
  let departureTime,
    arrivalTime,
    arrivedCls = "",
    airPlanIcon = "",
    progressActive = undefined,
    progressComplete = "",
    progressPercent = undefined,
    tripNumber,
    onTimeText = "",
    titleTime = "",
    tailNumber = "",
    aircraftTypeName = "";
  let timeToHourMinute = (time) => {
    return moment(time).format("hh:mm A");
  };
  let calFlightProgress = (departureTime, arrivalTime) => {
    const totalTime =
      moment.utc(arrivalTime).valueOf() - moment.utc(departureTime).valueOf();
    let progressTime = 0;
    if (moment.utc(new Date()).isBefore(moment.utc(departureTime))) {
      progressTime =
        moment.utc(new Date()).valueOf() - moment.utc(departureTime).valueOf();
    }
    let left = progressTime <= totalTime ? (progressTime * 100) / totalTime : 0;
    if (left === 100) {
      progressComplete = "completed";
    }
    progressActive = { width: left + "%" };
    return { left: left >= 0 && left <= 100 ? left + "%" : 0 };
  };

  if (tripStatus === "Approved") {
    departureTime = timeToHourMinute(leg.departureTime);
    arrivalTime = timeToHourMinute(leg.arrivalTime);
  } else if (tripStatus === "Confirmed") {
    if (leg.flights.length > 0) {
      // airplane icon
      if (flight.flightStatus === "In Progress") {
        airPlanIcon = "in-progress";
        progressPercent = calFlightProgress(leg.departureTime, leg.arrivalTime);
      }
      // departureTime and arrivalTime
      if (flight.flightStatus === "Not Released") {
        departureTime = timeToHourMinute(leg.departureTime);
        arrivalTime = timeToHourMinute(leg.arrivalTime);
      } else if (flight.flightStatus === "Released") {
        departureTime = timeToHourMinute(flight.ETD);
        arrivalTime = timeToHourMinute(flight.ETA);
      } else if (
        ["In Progress", "Completed", "Closed"].includes(flight.flightStatus)
      ) {
        departureTime = timeToHourMinute(flight.departureTime);
        arrivalTime = timeToHourMinute(flight.arrivalTime);
      }
    } else {
      departureTime = timeToHourMinute(leg.departureTime);
      arrivalTime = timeToHourMinute(leg.arrivalTime);
    }
  }

  // setOnTime function
  let setOnTime = (ETDDate) => {
    let onTime = "";
    let totalMinutes = 0;
    let hours = moment.utc(ETDDate).hours();
    let currentHours = moment().hours();
    let currentMinutes = moment().minutes();
    let minutes = moment.utc(ETDDate).minutes();
    if (currentHours === hours) {
      if (currentMinutes === minutes) {
        onTime = "0 minutes.";
      } else {
        const min = minutes - currentMinutes;
        if (min === 1) {
          onTime = min + " minute.";
        } else {
          onTime = min + " minutes.";
        }
      }
    } else {
      if (currentHours > hours) {
        totalMinutes =
          currentHours * 60 + currentMinutes - (hours * 60 + minutes);
      } else {
        totalMinutes =
          hours * 60 + minutes - (currentHours * 60 + currentMinutes);
      }
      const hr = Math.floor(totalMinutes / 60);
      const min = totalMinutes % 60;
      if (hr === 1) {
        onTime = hr + " hour ";
      } else if (hr > 1) {
        onTime = hr + " hours ";
      }

      if (min === 1) {
        onTime += min + " minute";
      } else if (min === 0) {
        onTime += min + " minutes";
      } else {
        onTime += min + " minutes";
      }
    }
    return onTime;
  };

  //Compare ETD with current date
  let compareTimeWithCurrentTime = (date) => {
    return moment.utc().diff(moment(date), "minutes") <= 0 ? true : false;
  };
  //SET TripNumber
  if (leg.flights.length > 0) {
    tailNumber = flight.aircraftTailNumber;
    aircraftTypeName = flight.aircraftTypeName;
    if (["Not Released", "Released"].includes(flight.flightStatus)) {
      let today = moment().add(1, "days");
      let ETDDay = moment(flight.ETD);
      if (ETDDay.format("MM-DD-YYYY") >= today.format("MM-DD-YYYY")) {
        tripNumber = leg.trip.tripNumber;
      } else if (
        ETDDay.format("MM-DD-YYYY") <= today.format("MM-DD-YYYY") &&
        compareTimeWithCurrentTime(flight.ETD)
      ) {
        onTimeText = "Scheduled to depart in " + setOnTime(flight.ETD);
        if (
          moment.utc(leg.departureTimeUTC) >=
          moment.utc(flight.departureTimeUTC)
        ) {
          titleTime = "On Time";
          arrivedCls = "on-time";
        } else {
          titleTime = "Delayed";
        }
      } else {
        tripNumber = leg.trip.tripNumber;
      }
    } else if (flight.flightStatus === "In Progress") {
      titleTime = "In Progress";
      onTimeText = "Scheduled to arrive in " + setOnTime(flight.arrivalTimeUTC);
    } else if (["Completed", "Closed"].includes(flight.flightStatus)) {
      titleTime = "Arrived at ";
      arrivedCls = "arrived-at";
      onTimeText = moment.utc(flight.arrivalTime).format("hh:mm A");
    }
  } else {
    tripNumber = leg.trip.tripNumber;
  }

  // Check Flight Survey
  let isContainSurvey = false;
  if (flightSurveys) {
    if (flightSurveys.length > 0) {
      flightSurveys.forEach((survey) => {
        if (survey.tripLegId === leg.legId) {
          isContainSurvey = true;
        }
      });
    }
  }
  let isSurvey = false;
  let isObscurred = true;

  if (flight) {
    if (moment().diff(moment(flight.arrivalTime), "hours") > -10) {
      isObscurred = false;
    }
    if (
      moment().diff(moment(flight.arrivalTime), "minutes") > 30 &&
      moment().diff(moment(flight.arrivalTime), "hours") < 72
    ) {
      isSurvey = true;
    }
  }

  const identifier = isSetIdentifier
    ? (flight ? flight.flightNumber : leg.trip.tripNumber) + "-" + leg.legId
    : null;
  return (
    <div className="cardParent" id={identifier}>
      {countAccount >= 2 ? (
        <div className="account-card">{leg.trip.accountName}</div>
      ) : null}
      <div className="card-item">
        <div
          className={`wrapper ${activedLegId === leg.legId ? "active" : ""}`}
          onClick={() => {
            if (activedLegId !== leg.legId) {
              openFlightDetail({
                leg: leg,
                flight: flight,
                countAccount: countAccount,
              });
            }
          }}
        >
          <Grid columns={3} divided="vertically" className="card-ui">
            <Grid.Row className="gridRow top">
              <Grid.Column>
                <h4>{leg.originAirportCode}</h4>
                <div className="city">
                  {leg.originAirportCity},{" "}
                  {leg.originAirportState || leg.originAirportCountry}
                </div>
                <div className="time">
                  {departureTime} ({leg.departureTimeZone})
                </div>
              </Grid.Column>
              <Grid.Column className={airPlanIcon ? "noPaddingLeft" : ""}>
                <div className={"ic_flight " + airPlanIcon}>
                  {airPlanIcon ? (
                    <div className={"progressLine " + progressComplete}>
                      <div
                        className="progressActive"
                        style={progressActive}
                      ></div>
                      <i style={progressPercent}>
                        <img src={iconLocalAirport} alt="" />
                      </i>
                      <div className="gray-line"></div>
                    </div>
                  ) : (
                    <div className="progressLine">
                      <img className="airplaneImg" src={iconAirplane} alt="" />
                    </div>
                  )}
                  {calFlightTime ? (
                    <span className="hours">{calFlightTime}</span>
                  ) : null}
                </div>
              </Grid.Column>
              <Grid.Column className="noPaddingLeft">
                <h4>{leg.destinationAirportCode}</h4>
                <div className="city">
                  {leg.destinationAirportCity},{" "}
                  {leg.destinationAirportState || leg.destinationAirportCountry}
                </div>
                <div className="time">
                  {arrivalTime} ({leg.arrivalTimeZone})
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Grid columns={2} divided="vertically">
            <Grid.Row className="gridRow bottom">
              <Grid.Column width={16}>
                {tripNumber ? (
                  <div className="trip-row trip-number bRow">
                    <span>Trip number</span> - {tripNumber}
                  </div>
                ) : null}
                {titleTime && onTimeText ? (
                  <div className={arrivedCls + " trip-row bRow"}>
                    <span>{titleTime}</span> - {onTimeText}
                  </div>
                ) : null}
                {tailNumber && !isObscurred ? (
                  <div className="air trip-row">
                    <span>{tailNumber}</span> - {aircraftTypeName}{" "}
                  </div>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {isPassenger && !isContainSurvey && isSurvey ? (
          <div>
            {surveyLegIds && !surveyLegIds.includes(leg.legId) ? (
              <Grid className="grid-feeback">
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Button
                      className="give-feedback"
                      onClick={() => {
                        openFeedBackPopup(
                          leg.originAirportCity,
                          leg.destinationAirportCity,
                          leg.trip.tripNumber,
                          leg.legId
                        );
                        analyticsEvent("Flight_Card_Survey", {
                          event_category: "survey",
                          event_label:
                            "User accesses a flight survey from the Flight List page.",
                        });
                      }}
                    >
                      <i></i>Give Feedback
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const FlightRow = ({
  isSetIdentifier,
  openFeedBackPopup,
  countAccount,
  surveyLegIds,
  leg,
  activedLegId,
  isPassenger,
  flightSurveys,
  flight,
  openFlightDetail,
  calFlightTime,
}) => {
  if (leg.flights.length > 0) {
    return (
      <div>
        {leg.flights.map((flight, key) => {
          return (
            <FlightItem
              isSetIdentifier={isSetIdentifier}
              openFeedBackPopup={openFeedBackPopup}
              countAccount={countAccount}
              surveyLegIds={surveyLegIds}
              key={key}
              leg={leg}
              activedLegId={activedLegId}
              isPassenger={isPassenger}
              flightSurveys={flightSurveys}
              flight={flight}
              openFlightDetail={openFlightDetail}
              calFlightTime={calFlightTime}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <FlightItem
        isSetIdentifier={isSetIdentifier}
        openFeedBackPopup={openFeedBackPopup}
        countAccount={countAccount}
        surveyLegIds={surveyLegIds}
        leg={leg}
        flight={flight}
        activedLegId={activedLegId}
        isPassenger={isPassenger}
        flightSurveys={flightSurveys}
        openFlightDetail={openFlightDetail}
        calFlightTime={calFlightTime}
      />
    </div>
  );
};

export default FlightRow;
