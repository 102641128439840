import clone from "clone";
import { handleActions } from "redux-actions";
import {
  NEW_USER_FIELD_VALUE,
  PREP_USER_FOR_DETAILS_EDIT,
  SWITCH_USER_EDIT_FIELD,
} from "../../actions/types";

const editFieldsDefaultState = {
  editing: null,
  editedUser: null,
  callId: null,
};

export const toSafeString = (input) => (input ? input : "");

function convertUserForEditing(easUser) {
  return easUser
    ? {
        firstName: toSafeString(easUser.firstName),
        lastName: toSafeString(easUser.lastName),
        userSpecifiedAccount: toSafeString(easUser.userSpecifiedAccount),
        isEmployee: easUser.isEmployee,
        astroUserIds: easUser.astroUserIds ? easUser.astroUserIds : [],
        emailAddress: toSafeString(easUser.emailAddress),
        status: easUser.status,
        emailVerified: easUser.emailVerified,
      }
    : null;
}

export default handleActions(
  {
    [PREP_USER_FOR_DETAILS_EDIT]: {
      next(state, action) {
        if (action.payload) {
          return {
            editing: null,
            editedUser: convertUserForEditing(action.payload.user),
            callId: action.payload.callId,
          };
        }
        return editFieldsDefaultState;
      },
    },
    [SWITCH_USER_EDIT_FIELD]: {
      next(state, action) {
        const newState = clone(state);
        //Set our field back

        if (state.editing) {
          newState.editedUser[newState.editing] = newState.original;
          newState.editing = null;
          newState.original = null;
        }
        if (action.payload) {
          newState.editing = action.payload;
          newState.original = newState.editedUser[newState.editing];
        }
        return newState;
      },
    },
    [NEW_USER_FIELD_VALUE]: {
      next(state, action) {
        const newState = clone(state);

        if (action.payload === null) {
          console.warn("Got new field value without payload");
        } else {
          newState.editedUser[newState.editing] = action.payload;
        }
        return newState;
      },
    },
  },
  editFieldsDefaultState
);
