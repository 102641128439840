import gifAccountCreation from "images/EAS-account-creation-api.gif";

export default function SignUpLoadding() {
  return (
    <div className="firebase-ui login-page">
      <div className="loadingPage">
        <img src={gifAccountCreation} alt="" />
      </div>
    </div>
  );
}
