import networkMiddleware from "store/network-redux-middleware";
import reducer from "store/reducers";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, networkMiddleware))
);
