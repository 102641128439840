import { createAction } from "redux-actions";
import { execUpdateUser } from "../services/eas-users";
import {
  NEW_USER_FIELD_VALUE,
  PREP_USER_FOR_DETAILS_EDIT,
  SWITCH_USER_EDIT_FIELD,
} from "../types";
import { wrapAsyncThunk } from "./utilities";

export const ASTRO_ID_FIELD_PREFIX = "astroUserId_";
export const ASTRO_ID_PREFIX_LENGTH = ASTRO_ID_FIELD_PREFIX.length;
export const prepEditUser = createAction(PREP_USER_FOR_DETAILS_EDIT);
export const switchUserEditField = createAction(SWITCH_USER_EDIT_FIELD);
export const newUserFieldValue = createAction(NEW_USER_FIELD_VALUE);

export const updateEditedUserIfNeeded = () => (dispatch, getState) => {
  const serviceState = getState().services.easUsers.current;
  const editedUserCallId = getState().ui.userDetails.callId;

  if (serviceState.callId !== editedUserCallId) {
    dispatch(
      prepEditUser({ user: serviceState.user, callId: serviceState.callId })
    );
  }
};

export const saveAndClearField = () =>
  wrapAsyncThunk(
    (dispatch, getState) => {
      const serviceState = getState().services.easUsers.current;
      const fieldName = getState().ui.userDetails.editing;
      let fieldValue = getState().ui.userDetails.editedUser[fieldName];
      const userId = serviceState.user.systemUserId;
      if (typeof fieldValue === "string") {
        fieldValue = fieldValue.trim();
      }
      return dispatch(execUpdateUser(userId, { [fieldName]: fieldValue })).then(
        (user) => {
          dispatch(prepEditUser(user));
          Promise.resolve();
        }
      );
    },
    { name: "user-details-save-and-clear-field" }
  );

export const removeEditedAstroId = () =>
  wrapAsyncThunk(
    (dispatch, getState) => {
      const serviceState = getState().services.easUsers.current;
      const fieldName = getState().ui.userDetails.editing;
      const astroId = fieldName.substring(ASTRO_ID_PREFIX_LENGTH);
      const userId = serviceState.user.systemUserId;
      const astroUserIdsAfterDelete = getState()
        .ui.userDetails.editedUser.astroUserIds.filter(
          (astroUser) => astroUser.astroUserId !== astroId
        )
        .map((astroUser) => astroUser.astroUserId);

      return dispatch(
        execUpdateUser(userId, { astroUserIds: astroUserIdsAfterDelete })
      ).then((user) => {
        dispatch(prepEditUser(user));
        Promise.resolve();
      });
    },
    { name: "user-details-save-astro-ids-and-clear-edit" }
  );
