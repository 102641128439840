import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  FIREBASE_CREATE_ACCOUNT,
  FIREBASE_PASSWORD_RESET,
  FIREBASE_SIGNIN_AUTHENCATION,
  FIREBASE_USER_CHECK,
} from "../../actions/types";

const defaultFirebaseUserState = { loggedIn: "unknown", name: "--" };
const defaultFirebaseLogin = { errorCode: "", errorMessage: "" };
const defaultFirebaseCreateAccount = {
  emailField: { errorCode: "", errorMessage: "" },
  passwordField: { errorCode: "", errorMessage: "" },
};
const firebaseUser = handleAction(
  FIREBASE_USER_CHECK,
  {
    next(state, action) {
      return action.payload ? action.payload : defaultFirebaseUserState;
    },
    throw(state, action) {
      console.log(`Error checking auth: ${action.error}`);
      return defaultFirebaseUserState;
    },
  },
  defaultFirebaseUserState
);

const firebaseAuthentication = handleAction(
  FIREBASE_SIGNIN_AUTHENCATION,
  {
    next(state, action) {
      return action.payload
        ? {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
          }
        : defaultFirebaseLogin;
    },
    throw(state, action) {
      console.log(`Error checking auth: ${action.error}`);
      return defaultFirebaseLogin;
    },
  },
  defaultFirebaseLogin
);

const firebaseCreateAccount = handleAction(
  FIREBASE_CREATE_ACCOUNT,
  {
    next(state, action) {
      if (action.payload) {
        if (action.payload.errorCode) {
          const fieldName = action.payload.errorCode.includes("email")
            ? "emailField"
            : "passwordField";
          return {
            [fieldName]: {
              errorCode: action.payload.errorCode,
              errorMessage: action.payload.errorMessage,
            },
          };
        } else {
          return { result: true, body: "success" };
        }
      } else {
        return { result: false, body: "" };
      }
    },
    throw(state, action) {
      console.log(`Error checking auth: ${action.error}`);
      return defaultFirebaseCreateAccount;
    },
  },
  defaultFirebaseCreateAccount
);

const firebasePasswordReset = handleAction(
  FIREBASE_PASSWORD_RESET,
  {
    next(state, action) {
      return action.payload
        ? {
            errorCode: action.payload.errorCode,
            errorMessage: action.payload.errorMessage,
          }
        : defaultFirebaseLogin;
    },
    throw(state, action) {
      console.log(`Error checking auth: ${action.error}`);
      return defaultFirebaseLogin;
    },
  },
  defaultFirebaseLogin
);

export default combineReducers({
  firebaseUser,
  firebaseAuthentication,
  firebasePasswordReset,
  firebaseCreateAccount,
});
