import { analyticsEvent } from "analytics/gtag";
import moment from "moment";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Dropdown, Grid, TextArea } from "semantic-ui-react";
import {
  accordionTripQuest,
  addOrRemovePassenger,
  addOrRemoveTripLeg,
  execShadedPutTripRequest,
  newBookTripFieldValues,
  newPassengerFieldValues,
} from "store/actions/ui/eas-book-trip";
import CateringRow from "./CateringRow";
import GroundTransportRow from "./GroundTransportRow";
import LegRow from "./LegRow";
import PassengerRow from "./PassengerRow";

let tripLegRow = 1,
  passengerRow = 1,
  maxPassenger = 1,
  putPassengerObject = [];
class Summary extends Component {
  componentDidMount() {
    tripLegRow = Object.keys(this.props.rowTripLeg).length;
    if (Object.keys(this.props.rowPassengersFields).length > 0) {
      const lastPassenger = Object.keys(this.props.rowPassengersFields)[
        Object.keys(this.props.rowPassengersFields).length - 1
      ];
      passengerRow = lastPassenger.split("_")[1];
    }
  }
  addMoreTripLeg() {
    tripLegRow++;
    this.props.addOrRemoveTripLeg({
      actionType: "ADD",
      legNumber: tripLegRow,
      triplegRow: LegRow,
      tripLegName: "TripLeg_" + tripLegRow,
    });
    this.props.openAccordion({
      accordionName: "TripLeg_" + tripLegRow,
      forced: true,
    });
  }

  formatAMPM(time) {
    const [hour, min] = time.split(":");
    return (
      (hour % 12) + 12 * (hour % 12 === 0) + ":" + min, hour >= 12 ? "PM" : "AM"
    );
  }

  putPassengerToObject(passengerObj) {
    putPassengerObject = [];
    Object.keys(passengerObj).forEach((rowPassenger, key) => {
      const passengerKey = key + 1;
      putPassengerObject.push({
        name: passengerObj[rowPassenger].values.name
          ? passengerObj[rowPassenger].values.name
          : "Passenger " + passengerKey,
        email: passengerObj[rowPassenger].values.email,
      });
    });
    return putPassengerObject;
  }

  getMaxPassenger() {
    passengerRow = 1;
    maxPassenger = 1;
    Object.keys(this.props.rowTripLeg).forEach((rowLeg) => {
      maxPassenger =
        maxPassenger < this.props.rowTripLeg[rowLeg].values.passengerCount
          ? this.props.rowTripLeg[rowLeg].values.passengerCount
          : maxPassenger;
    });
  }

  reRenderPassenger() {
    if (maxPassenger > Object.keys(this.props.rowPassengersFields).length) {
      for (
        let i = Object.keys(this.props.rowPassengersFields).length + 1;
        i <= maxPassenger;
        i++
      ) {
        const lastPassenger = Object.keys(this.props.rowPassengersFields)[
          Object.keys(this.props.rowPassengersFields).length - 1
        ];
        passengerRow = lastPassenger ? lastPassenger.split("_")[1] : 0;
        passengerRow++;
        this.props.addOrRemovePassenger({
          actionType: "ADD",
          passengerRows: passengerRow,
          passengerName: "Passenger_" + passengerRow,
        });
      }
    } else {
      Object.keys(this.props.rowPassengersFields).forEach((passenger, key) => {
        if (maxPassenger <= key) {
          this.props.addOrRemovePassenger({
            actionType: "REMOVE",
            passengerName: passenger,
          });
        }
      });
    }
    this.props.openAccordion({ accordionName: "editPassenger", forced: false });
  }

  saveLeg(legName, tmpValues) {
    const tripLegFields = this.getValuesChanged(tmpValues);
    this.props.newBookTripFieldValues({
      tripLegName: legName,
      tripLegFields: tripLegFields,
      isChangedValue: {},
    });
    this.resetTmpValues(legName);
    this.getMaxPassenger();
    this.reRenderPassenger();
    analyticsEvent("Trip_Leg_Added", {
      event_category: "book_trip",
      event_label: "User completes adding a new trip leg.",
    });
    this.putPassengerToObject(this.props.rowPassengersFields);
  }
  getValuesChanged(tmpValues) {
    let tripLegFields = {};
    for (let field in tmpValues) {
      if (tmpValues.isChangedValue && tmpValues.isChangedValue[field]) {
        tripLegFields[field] = tmpValues[field] ? tmpValues[field] : "";
      }
    }

    return tripLegFields;
  }
  resetTmpValues(legName) {
    this.props.newBookTripFieldValues({
      tripLegName: legName,
      tripLegFields: {
        accountId: "",
        accountName: "",
        actionRequest: "",
        fromCity: "",
        airportOptionForfromCity: "",
        airportOptionFortoCity: "",
        fromAirport: "",
        toCity: "",
        toAirport: "",
        passengerCount: "",
        departureDate: "",
        departOrArrive: "",
        departTime: "",
        arriveTime: "",
        departOrArriveTime: "",
        passengers: "",
        preferredAircraft: "",
      },
      isChangedValue: {},
      saveToTmp: true,
    });
  }

  renderLegs() {
    return Object.keys(this.props.rowTripLeg).map((rowLeg, key) => {
      let legOrder = ["1st", "2nd", "3rd"];
      let legNumber = legOrder[key];
      let keyLeg = key + 1;
      const rowData = this.props.rowTripLeg[rowLeg].values;
      let passengerCount =
        rowData.passengerCount === "" ? 1 : rowData.passengerCount;
      let tmpValues = [],
        legValues = [];
      let rowLegFields = this.props.rowLegFields;
      if (rowLegFields[rowLeg]) {
        tmpValues = this.props.rowLegFields[rowLeg].tmpValues;
        legValues = this.props.rowLegFields[rowLeg].values;
      } else {
        tmpValues = this.props.rowTripLeg[rowLeg].tmpValues;
        legValues = this.props.rowTripLeg[rowLeg].values;
      }
      this.getValuesChanged(tmpValues);
      return (
        <div className="borderBottom" key={key}>
          <Grid
            className="marginLeft"
            style={
              this.props.accordionActive && this.props.accordionActive[rowLeg]
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            <Grid.Row className="paddingLeft clsBottom">
              <Grid.Column width={13}>
                <div className="tripAccount">
                  {legNumber ? legNumber : keyLeg + "th"} Leg -{" "}
                  {moment(rowData.departureDate).format("MMM, DD")}
                </div>
              </Grid.Column>
              <Grid.Column width={2}>
                <button
                  className="button-wrapper editTrip left"
                  onClick={() => {
                    this.props.openAccordion({ accordionName: rowLeg });
                    analyticsEvent("Edit_Flight_Details", {
                      event_category: "book_trip",
                      event_label:
                        "User edits the flight details of a trip leg.",
                    });
                  }}
                >
                  Edit
                </button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="paddingLeft title">
              <Grid.Column>
                <div className="flightTitle">Flight Details</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="paddingLeft right">
              <Grid.Column width={6} className="passengerList">
                <div className="title">Depart</div>
                <div className="subTitle">{rowData.fromCity}</div>
                <div className="airport">
                  {rowData.fromAirport ? rowData.fromAirport : "Any Airport"}
                </div>
              </Grid.Column>
              <Grid.Column width={7} className="passengerList">
                <div className="title">Arrive</div>
                <div className="subTitle">{rowData.toCity}</div>
                <div className="airport">
                  {rowData.toAirport ? rowData.toAirport : "Any Airport"}
                </div>
              </Grid.Column>
              <Grid.Column width={3} className="passengerList bottom">
                <div className="title">Depart At</div>
                <div className="sub">
                  {rowData.departTime}{" "}
                  {rowData.departTime
                    ? this.formatAMPM(rowData.departTime)
                    : null}
                </div>
              </Grid.Column>
              <Grid.Column width={6} className="passengerList">
                <div className="title">Preferred AirCraft</div>
                <div className="subTitle">{rowData.preferredAircraft}</div>
              </Grid.Column>
              <Grid.Column width={6} className="passengerList bottom">
                <div className="title">Passengers</div>
                <div className="subTitle">{passengerCount}</div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            className="borderBottom legRow"
            style={
              this.props.accordionActive && this.props.accordionActive[rowLeg]
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <LegRow
              saveToTmp={true}
              accountOptions={this.props.accountOptions}
              legNumber={key + 1}
              tripLegName={rowLeg}
              rowLegData={this.props}
            />
          </Grid>
          <Grid className="borderBottom legRow mobile">
            <Grid.Row
              className="btnSubmit"
              style={
                this.props.accordionActive && this.props.accordionActive[rowLeg]
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <Grid.Column className="right floated">
                <Button
                  secondary
                  onClick={() => {
                    this.props.openAccordion({
                      accordionName: rowLeg,
                      forced: false,
                    });
                    this.props.openAccordion({
                      accordionName: "groundTransportation" + rowLeg,
                      forced: false,
                    });
                    this.resetTmpValues(rowLeg);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !tmpValues.isChangedValue ||
                    (tmpValues.isChangedValue &&
                      Object.keys(tmpValues.isChangedValue).length < 1)
                  }
                  primary
                  onClick={() => {
                    this.saveLeg(rowLeg, tmpValues);
                    this.props.openAccordion({
                      accordionName: rowLeg,
                      forced: false,
                    });
                    this.props.openAccordion({
                      accordionName: "groundTransportation" + rowLeg,
                      forced: false,
                    });
                  }}
                >
                  Save Changes
                </Button>
              </Grid.Column>
            </Grid.Row>
            <div className="clsTransportation">
              <GroundTransportRow
                rowNumber={key + 1}
                tripLegName={rowLeg}
                isChangedValue={tmpValues.isChangedValue}
                tmpValues={tmpValues}
                legValues={legValues}
                saveToTmp={true}
              />
            </div>
            <Grid.Row
              className="btnSubmit"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["groundTransportation" + rowLeg]
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <Grid.Column className="right floated">
                <Button
                  secondary
                  onClick={() => {
                    this.props.openAccordion({
                      accordionName: rowLeg,
                      forced: false,
                    });
                    this.props.openAccordion({
                      accordionName: "groundTransportation" + rowLeg,
                      forced: false,
                    });
                    this.resetTmpValues(rowLeg);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    !tmpValues.isChangedValue ||
                    (tmpValues.isChangedValue &&
                      Object.keys(tmpValues.isChangedValue).length < 1)
                  }
                  primary
                  onClick={() => {
                    this.saveLeg(rowLeg, tmpValues);
                    this.props.openAccordion({
                      accordionName: rowLeg,
                      forced: false,
                    });
                    this.props.openAccordion({
                      accordionName: "groundTransportation" + rowLeg,
                      forced: false,
                    });
                  }}
                >
                  Save Changes
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            className="marginLeft"
            style={
              this.props.accordionActive &&
              this.props.accordionActive["showMore" + rowLeg]
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Grid
              className="widthCatery"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["groundTransportation" + rowLeg]
                  ? { display: "none" }
                  : { display: "flex" }
              }
            >
              <Grid.Row className="borderTransportation top"></Grid.Row>
              <Grid.Row className="paddingLeft title">
                <Grid.Column width={13}>
                  <div className="flightTitle">Ground Transportation</div>
                </Grid.Column>
                <Grid.Column width={2}>
                  <button
                    className="button-wrapper editTrip ground"
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: "groundTransportation" + rowLeg,
                      });
                      analyticsEvent("Edit_Transportation", {
                        event_category: "book_trip",
                        event_label:
                          "User edits the ground transportation for their trip leg.",
                      });
                    }}
                  >
                    Edit
                  </button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="paddingLeft right">
                <Grid.Column width={6} className="passengerList">
                  <div className="title">To the airport</div>
                  <div className="subTitle">
                    {legValues.toAirportTransportion}
                  </div>
                </Grid.Column>
                <Grid.Column width={10} className="passengerList">
                  <div className="title">From the Airport</div>
                  <div className="subTitle">
                    {legValues.fromAirportTransportion}
                  </div>
                </Grid.Column>
                <Grid.Column width={16} className="passengerList">
                  <div className="title">Additional Information</div>
                  <div className="subTitle">
                    {legValues.additionalInformation}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className="borderBottom legRow mobileBottom">
              <div className="clsTransportation">
                <CateringRow
                  rowNumber={key + 1}
                  tripLegName={rowLeg}
                  isChangedValue={tmpValues.isChangedValue}
                  tmpValues={tmpValues}
                  legValues={legValues}
                  saveToTmp={true}
                />
              </div>
              <Grid.Row
                className="btnSubmit"
                style={
                  this.props.accordionActive &&
                  this.props.accordionActive["catering" + rowLeg]
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <Grid.Column className="right floated">
                  <Button
                    secondary
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: rowLeg,
                        forced: false,
                      });
                      this.props.openAccordion({
                        accordionName: "catering" + rowLeg,
                        forced: false,
                      });
                      this.resetTmpValues(rowLeg);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !tmpValues.isChangedValue ||
                      (tmpValues.isChangedValue &&
                        Object.keys(tmpValues.isChangedValue).length < 1)
                    }
                    primary
                    onClick={() => {
                      this.saveLeg(rowLeg, tmpValues);
                      this.props.openAccordion({
                        accordionName: rowLeg,
                        forced: false,
                      });
                      this.props.openAccordion({
                        accordionName: "catering" + rowLeg,
                        forced: false,
                      });
                    }}
                  >
                    Save Changes
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid
              className="widthCatery"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["catering" + rowLeg]
                  ? { display: "none" }
                  : { display: "flex" }
              }
            >
              <Grid.Row className="borderTransportation"></Grid.Row>
              <Grid.Row className="paddingLeft title">
                <Grid.Column width={13}>
                  <div className="flightTitle">Catering</div>
                </Grid.Column>
                <Grid.Column width={2}>
                  <button
                    className="button-wrapper editTrip ground"
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: "catering" + rowLeg,
                      });
                      analyticsEvent("Edit_Catering", {
                        event_category: "book_trip",
                        event_label:
                          "User edits the catering details of a trip leg.",
                      });
                    }}
                  >
                    Edit
                  </button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="paddingLeft right">
                <Grid.Column className="passengerList">
                  <div className="title">Catering Order</div>
                  <div className="subTitle">{legValues.catering}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid>
          <Grid className="showMoreBtn">
            <Grid.Row className="noPaddingTop">
              <Grid.Column className="paddingLeft">
                <button
                  className={`button-wrapper ${
                    this.props.accordionActive &&
                    this.props.accordionActive["showMore" + rowLeg]
                      ? "showLess"
                      : "showMore"
                  }`}
                  onClick={() =>
                    this.props.openAccordion({
                      accordionName: "showMore" + rowLeg,
                    })
                  }
                >
                  {this.props.accordionActive &&
                  this.props.accordionActive["showMore" + rowLeg]
                    ? "Show less"
                    : "Show More"}
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    });
  }

  isChangedPassenger() {
    let isChanged = false;
    let passengerObject = this.props.rowPassengersFields;
    Object.keys(passengerObject).forEach((rowPassenger, key) => {
      const tmpValues = passengerObject[rowPassenger].tmpValues;
      if (Object.keys(this.getValuesChanged(tmpValues)).length > 0) {
        isChanged = true;
      }
    });
    return isChanged;
  }
  resetPassengerValues(rowPassenger, tmpValues) {
    this.props.newPassengerFieldValues({
      passengerName: rowPassenger,
      passengerFields: { name: "", email: "" },
      saveToTmp: true,
    });
  }
  savePassenger(passengerObject) {
    Object.keys(passengerObject).forEach((rowPassenger, key) => {
      const tmpValues = passengerObject[rowPassenger].tmpValues;
      this.props.newPassengerFieldValues({
        passengerName: rowPassenger,
        passengerFields: this.getValuesChanged(tmpValues),
      });
      this.resetPassengerValues(rowPassenger, tmpValues);
    });
    this.props.openAccordion({ accordionName: "editPassenger", forced: false });
  }
  renderPassengers() {
    let passengerObject = this.props.rowPassengersFields;
    return Object.keys(passengerObject).map((rowPassenger, key) => {
      const rowData = this.props.rowPassengersFields[rowPassenger].values;
      const passengerKey = key + 1;
      return (
        <Grid.Column width={5} key={key}>
          <div className="sub">
            {key + 1}.{" "}
            {rowData.name ? rowData.name : "Passenger " + passengerKey}
          </div>
        </Grid.Column>
      );
    });
  }

  editPassengers() {
    return Object.keys(this.props.rowPassengersFields).map(
      (rowPassenger, key) => {
        return (
          <PassengerRow
            saveToTmp={true}
            passengerOrder={key + 1}
            passengerNumber={key + 1}
            key={key}
            passengerName={rowPassenger}
            passengerProps={this.props}
          />
        );
      }
    );
  }

  execAddOrRemovePassenger(fromLink) {
    if (passengerRow <= maxPassenger && !fromLink) {
      passengerRow = maxPassenger;
    } else {
      passengerRow++;
    }
    this.props.addOrRemovePassenger({
      actionType: "ADD",
      passengerRows: passengerRow,
      passengerName: "Passenger_" + passengerRow,
    });
  }

  addMorePassenger(fromLink) {
    return () => {
      this.execAddOrRemovePassenger(fromLink);
    };
  }

  handleBookATrip(bookType, accountId) {
    let bookTripData = {
      accountId: this.props.accountId ? this.props.accountId : accountId,
      actionRequest: bookType,
      additionalInstructions: this.props.firstTripLeg.values.instructions,
      trip: {
        tripLegs: this.formatTripLeg(this.props),
      },
    };
    this.props.putTripRequest(bookTripData);
    this.props.nextStep({ step: "confirmation" });
  }

  formatTripLeg = (props) => {
    let tripLegs = [];
    let triplegsObject = props.rowLegFields;
    const passengers = this.putPassengerToObject(
      this.props.rowPassengersFields
    );
    Object.keys(triplegsObject).forEach((legItem) => {
      let tempObj = {
        departurePoint:
          triplegsObject[legItem].values.fromCity &&
          triplegsObject[legItem].values.fromAirport
            ? triplegsObject[legItem].values.fromCity +
              " " +
              triplegsObject[legItem].values.fromAirport
            : "Any Airport",
        arrivalPoint:
          triplegsObject[legItem].values.toCity &&
          triplegsObject[legItem].values.toAirport
            ? triplegsObject[legItem].values.toCity +
              " " +
              triplegsObject[legItem].values.toAirport
            : "Any Airport",
        tripLegDate: triplegsObject[legItem].values.departureDate,
        departureTime: triplegsObject[legItem].values.departTime,
        arrivalTime: triplegsObject[legItem].values.arriveTime,
        preferredAircraft: triplegsObject[legItem].values.preferredAircraft,
        destinationAirportCity: triplegsObject[legItem].values.toCity,
        passengers: passengers,
        arrivalGroundTransport: {
          type: triplegsObject[legItem].values.toAirportTransportion,
          comments: triplegsObject[legItem].values.additionalInformation,
        },
        departureGroundTransport: {
          type: triplegsObject[legItem].values.fromAirportTransportion,
          comments: triplegsObject[legItem].values.additionalInformation,
        },
        catering: triplegsObject[legItem].values.catering,
      };
      tripLegs.push(tempObj);
    });
    return tripLegs;
  };

  render() {
    return (
      <div className="tripSummary">
        <Grid.Row>
          <Container className="noPaddingTop top">
            <Grid>
              <Grid.Row className="legsDetail">
                <Grid.Column>
                  <div className="tripTitle">Trip Summary</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid
              className="borderBottom top"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["editAccount"]
                  ? { display: "none" }
                  : { display: "flex" }
              }
            >
              <Grid.Row className="paddingLeft top">
                <Grid.Column width={13}>
                  <div className="tripAccount">Account</div>
                  <div className="sub">
                    {this.props.firstTripLeg.values.accountName}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <button
                    className="button-wrapper editTrip"
                    onClick={() =>
                      this.props.openAccordion({ accordionName: "editAccount" })
                    }
                  >
                    Edit
                  </button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid
              className="borderBottom"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["editAccount"]
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <Grid.Row className="paddingLeft">
                <Grid.Column className="dropdowntop">
                  <div className="titleTop">
                    Which account do you want to use for your trip?
                  </div>
                  <Dropdown
                    value={
                      this.props.tmpValues[this.props.firstLegName].tmpValues
                        .accountId
                        ? this.props.tmpValues[this.props.firstLegName]
                            .tmpValues.accountId
                        : this.props.firstTripLeg.values.accountId
                    }
                    selection
                    options={this.props.accountOptions}
                    onChange={(event, data) => {
                      this.props.newBookTripFieldValues({
                        tripLegName: this.props.firstLegName,
                        tripLegFields: {
                          accountId: data.value,
                          accountName: event.target.textContent,
                        },
                        saveToTmp: true,
                      });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="btnSubmit">
                <Grid.Column className="right floated">
                  <Button
                    secondary
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: "editAccount",
                      });
                      this.props.newBookTripFieldValues({
                        tripLegName: this.props.firstLegName,
                        tripLegFields: {
                          accountId: this.props.firstTripLeg.values.accountId,
                          accountName: this.props.firstTripLeg.values
                            .accountName,
                        },
                        saveToTmp: true,
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      this.props.newBookTripFieldValues({
                        tripLegName: this.props.firstLegName,
                        tripLegFields: {
                          accountId: this.props.tmpValues[
                            this.props.firstLegName
                          ].tmpValues.accountId,
                          accountName: this.props.tmpValues[
                            this.props.firstLegName
                          ].tmpValues.accountName,
                        },
                      });
                      this.props.openAccordion({
                        accordionName: "editAccount",
                      });
                    }}
                    disabled={
                      this.props.tmpValues[this.props.firstLegName].tmpValues
                        .accountId &&
                      this.props.firstTripLeg.values.accountId !==
                        this.props.tmpValues[this.props.firstLegName].tmpValues
                          .accountId
                        ? false
                        : true
                    }
                  >
                    Save Changes
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {this.renderLegs()}
            <Grid.Row className="paddingLeft rowBottom">
              <Grid.Column>
                <button
                  className="button-wrapper addLegPass"
                  onClick={() => {
                    this.addMoreTripLeg();
                    analyticsEvent("Add_Trip_Leg", {
                      event_category: "book_trip",
                      event_label: "User begins adding a new trip leg.",
                    });
                  }}
                >
                  Add Leg
                </button>
              </Grid.Column>
            </Grid.Row>
            <Grid
              className="borderBottom passengerInfo"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["editPassenger"]
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <Grid.Row className="paddingLeft">
                <Grid.Column width={13}>
                  <div className="tripAccount">
                    {Object.keys(this.props.rowPassengersFields).length > 1
                      ? "Passengers"
                      : "Passenger"}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <button
                    className="button-wrapper editTrip"
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: "editPassenger",
                      });
                      analyticsEvent("Edit_Passengers", {
                        event_category: "book_trip",
                        event_label:
                          "User edits the passenger details of a trip leg.",
                      });
                    }}
                  >
                    Edit
                  </button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="paddingLeft right">
                {this.renderPassengers()}
              </Grid.Row>
            </Grid>

            <Grid
              className="borderBottom passengerSection"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["editPassenger"]
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="editSection">
                <Grid.Row className="paddingLeft">
                  <Grid.Column width={16}>
                    <div className="legTitle">
                      {Object.keys(this.props.rowPassengersFields).length > 1
                        ? "Passengers"
                        : "Passenger"}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                {this.editPassengers()}
              </div>

              <Grid.Row className="paddingLeft button passengerSubmit">
                <Grid.Column width={6}>
                  <button
                    className="button-wrapper addLegPass noPaddingTop"
                    onClick={this.addMorePassenger(true)}
                  >
                    Add Passenger
                  </button>
                </Grid.Column>
                <Grid.Column width={10} className=" btnSubmit right floated">
                  <Button
                    secondary
                    onClick={() => {
                      this.props.openAccordion({
                        accordionName: "editPassenger",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!this.isChangedPassenger()}
                    primary
                    onClick={() => {
                      this.savePassenger(this.props.rowPassengersFields);
                    }}
                  >
                    Save Changes
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className="borderBottom">
              <Grid.Row className="paddingLeft">
                <Grid.Column>
                  <div className="flightTitle">Additional Instructions</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="paddingLeft parentTextArea">
                <Grid.Column>
                  <TextArea
                    style={{ minHeight: 64 }}
                    className="textarea"
                    onChange={(event) => {
                      this.props.newBookTripFieldValues({
                        tripLegName: this.props.firstLegName,
                        field: "instructions",
                        value: event.target.value,
                      });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid className="btnSubmit">
              <Grid.Row>
                <Grid.Column className="right floated">
                  <Button
                    secondary
                    onClick={() => {
                      this.handleBookATrip("Quote");
                      analyticsEvent("Request_Quote", {
                        event_category: "book_trip",
                        event_label:
                          "User selects the Request a Quote option when completing a Trip Request.",
                      });
                    }}
                  >
                    Get a quote
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      this.handleBookATrip("Book");
                      analyticsEvent("Book_Trip", {
                        event_category: "book_trip",
                        event_label:
                          "User selects the Book a Trip option when completing a Trip Request.",
                      });
                    }}
                  >
                    Book Trip
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Grid.Row>
      </div>
    );
  }
}

Summary.contextTypes = {
  router: PropTypes.object,
};

Summary.propTypes = {};

const mapStateToProps = (state) => {
  let firstLeg =
    Object.keys(state.ui.bookTrip.fields).length > 0
      ? state.ui.bookTrip.fields[Object.keys(state.ui.bookTrip.fields)[0]]
      : null;
  let firstPassenger =
    Object.keys(state.ui.addPassenger.passengers).length > 0
      ? state.ui.addPassenger.passengers[
          Object.keys(state.ui.addPassenger.passengers)[0]
        ]
      : null;
  return {
    rowTripLeg: state.ui.bookTrip.newTripLeg,
    rowLegFields: state.ui.bookTrip.fields,
    rowPassengers: state.ui.addPassenger.passengers,
    rowPassengersFields: state.ui.addPassenger.fields,
    accountId: firstLeg ? firstLeg.values.accountId : 0,
    accounts: state.services.easUsers.loggedInAccounts.list,
    loggedInUser: state.services.easUsers.loggedIn.user,
    firstTripLeg: firstLeg,
    firstLegName:
      Object.keys(state.ui.bookTrip.newTripLeg).length > 0
        ? Object.keys(state.ui.bookTrip.newTripLeg)[0]
        : null,
    accordionActive:
      Object.keys(state.ui.bookTrip.accordion).length > 0
        ? state.ui.bookTrip.accordion
        : null,
    tmpValues: state.ui.bookTrip.fields,
    firstPassenger: firstPassenger,
    firstPassengerName:
      Object.keys(state.ui.addPassenger.passengers).length > 0
        ? Object.keys(state.ui.addPassenger.passengers)[0]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(newBookTripFieldValues(fieldAndValue));
  },
  putTripRequest: (initialTripRequestData) => {
    dispatch(execShadedPutTripRequest(initialTripRequestData));
  },
  newPassengerFieldValues: (fieldAndValue) => {
    dispatch(newPassengerFieldValues(fieldAndValue));
  },
  addOrRemoveTripLeg: (payload) => {
    dispatch(addOrRemoveTripLeg(payload));
  },
  addOrRemovePassenger: (payload) => {
    if (payload.actionType === "REMOVE") {
      dispatch(
        accordionTripQuest({ accordionName: "editPassenger", forced: false })
      );
    }
    dispatch(addOrRemovePassenger(payload));
  },
  resetPassengerRequest: (passengerName, firstPassenger) => {
    dispatch(
      addOrRemovePassenger({
        passengerName: passengerName,
        actionType: "RESET",
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
