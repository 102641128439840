import clone from "clone";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { SEARCH_AIRPORTS } from "../../actions/types";

//We don't know the airports fro any latitude+longitude yet
const searchAirportsDefault = {};
//noinspection JSUnusedLocalSymbols
const searchAiports = handleActions(
  {
    [SEARCH_AIRPORTS]: {
      next(state, action) {
        let newState = clone(state);
        if (!action.payload.existing) {
          newState[
            "currentSearch"
          ] = `${action.payload.latitude},${action.payload.longitude}`;
          if (action.payload.airports) {
            newState[
              `${action.payload.latitude},${action.payload.longitude}`
            ] = { status: "success", airports: action.payload.airports };
          } else {
            newState[
              `${action.payload.latitude},${action.payload.longitude}`
            ] = { status: "loading", timestamp: new Date().getTime() };
          }
        } else {
          newState[
            "currentSearch"
          ] = `${action.payload.latitude},${action.payload.longitude}`;
        }
        return newState;
      },
      throw(state, action) {
        let newState = clone(state);
        newState[`${action.payload.latitude},${action.payload.longitude}`] = {
          status: "failed",
        };
        return newState;
      },
    },
  },
  searchAirportsDefault
);

export default combineReducers({ searchAiports });
