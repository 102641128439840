import { analyticsEvent } from "analytics/gtag";
import imageAirshareWhiteHorizontal from "images/airshare_white_horizontal.svg";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { paths } from "routes";

export const TopNavigation = ({ isAdmin, easUserPending, toggle }) => {
  if (easUserPending.toLowerCase() !== "pending") {
    return (
      <div className="light-show">
        <ul className="list-item">
          <li>
            <NavLink
              className="flight"
              activeclassname="active"
              to={paths.build.flights()}
              onClick={toggle.closeDropdown}
            >
              <span>Flights</span>
            </NavLink>
          </li>
          {isAdmin ? (
            <li>
              <NavLink
                className="admin"
                activeclassname="active"
                to={paths.build.admin()}
                onClick={toggle.closeDropdown}
              >
                <span>Admin</span>
              </NavLink>
            </li>
          ) : null}
        </ul>
      </div>
    );
  }
  return null;
};

const Header = ({
  userName,
  visibility,
  logout,
  toggle,
  isAdmin,
  easUserPending,
  envConfig,
}) => (
  <div>
    <header>
      <div className="show-desktop">
        <div className="innerHeader">
          <img className="Avatar" src={imageAirshareWhiteHorizontal} alt="" />
          <div
            className={`menu ${visibility.menuDropdown ? "active" : ""}`}
            onClick={toggle.menuDropdown}
          ></div>
          <div className="contactShow">
            <button className="borderBottom" onClick={toggle.aircareDropdown}>
              Contact Owner Services
            </button>
            <div
              className={`displayContact ${
                visibility.aircareDropdown ? "active" : ""
              }`}
            >
              <div className="arrowUp"></div>
              <div className="showBottom">
                <div className="item">
                  <a
                    href="tel: 877.946.4900"
                    className="phone"
                    onClick={() => {
                      analyticsEvent("Call_AirCare_Request", {
                        event_category: "book_trip",
                        event_label:
                          "User calls AirCare from the Trip Request success page.",
                      });
                    }}
                  >
                    Phone (877.946.4900)
                  </a>
                </div>
                <div className="item">
                  <a
                    href={
                      "mailto:" +
                      envConfig.emailAircare +
                      "?subject=Owner Services Wep App Support Request"
                    }
                    className="email"
                    onClick={() => {
                      analyticsEvent("Call_AirCare_Request", {
                        event_category: "book_trip",
                        event_label:
                          "User calls AirCare from the Trip Request success page.",
                      });
                    }}
                  >
                    Email
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="top">
            <div className="innerMenu">
              <div
                className={`admin-show ${
                  visibility.userDropdown ? "active" : ""
                }`}
                onClick={toggle.userDropdown}
              >
                <div className="img-user" id="imgShow">
                  <img
                    src={
                      "https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg"
                    }
                    alt=""
                  />
                </div>
                <div className="text" id="nameShow">
                  {userName}
                </div>
                <div
                  className="sub-menu"
                  style={{
                    display: visibility.userDropdown ? "block" : "none",
                  }}
                >
                  <button onClick={logout}>Log out</button>
                </div>
              </div>
              <TopNavigation
                isAdmin={isAdmin}
                easUserPending={easUserPending}
                toggle={toggle}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="popup_menu"
        style={{ display: visibility.menuDropdown ? "block" : "none" }}
      >
        <div className="top">
          <div className="innerMenu">
            <div className="admin-show" onClick={toggle.userDropdown}>
              <div className="img-user" id="imgShow1"></div>
              <div className="text" id="nameShow1">
                {userName}
              </div>
              <div
                className="sub-menu"
                style={{ display: visibility.userDropdown ? "block" : "none" }}
              >
                <button onClick={logout}>Log out</button>
              </div>
            </div>
            <TopNavigation
              isAdmin={isAdmin}
              easUserPending={easUserPending}
              toggle={toggle}
            />
          </div>
        </div>
      </div>
    </header>
  </div>
);

TopNavigation.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  easUserPending: PropTypes.string.isRequired,
  toggle: PropTypes.object.isRequired,
};

Header.propTypes = {
  user: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  easUserPending: PropTypes.string.isRequired,
  toggle: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  visibility: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};
export default Header;
