import { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import {
  accordionTripQuest,
  newBookTripFieldValues,
} from "store/actions/ui/eas-book-trip";
import CateringRow from "./CateringRow";
import GroundTransportRow from "./GroundTransportRow";

class GroundTransportAndCatering extends Component {
  resetAdditionnalValues(accordionName, values) {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      tripLegFields: values,
    });
    this.props.openAccordion({
      accordionName: accordionName + this.props.tripLegName,
      forced: false,
    });
  }
  render() {
    const isChangedValue = this.props.isChangedValue;
    const tmpValues = this.props.tmpValues;
    const legValues = this.props.legValues;
    return (
      <div className="clsTransportation">
        <Grid.Row
          className={"borderBottom forSummary row_" + this.props.rowNumber}
        ></Grid.Row>
        {this.props.step === "legs" ? (
          <Grid.Row
            className="marginTop customMarginTransport"
            style={
              this.props.accordionActive &&
              this.props.accordionActive[
                "groundTransportation" + this.props.tripLegName
              ]
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            <Grid.Column>
              <button
                className="button-wrapper addRow transportation"
                onClick={() =>
                  this.props.openAccordion({
                    accordionName:
                      "groundTransportation" + this.props.tripLegName,
                  })
                }
              >
                Add Ground Transportation
              </button>
            </Grid.Column>
          </Grid.Row>
        ) : null}

        <GroundTransportRow
          isChangedValue={isChangedValue}
          tmpValues={tmpValues}
          legValues={legValues}
          tripLegName={this.props.tripLegName}
        />

        <Grid.Row className="borderBottom forSummary"></Grid.Row>
        {this.props.step === "legs" ? (
          <Grid.Row>
            <Grid.Column
              className="marginTop customMarginTransport"
              style={
                this.props.accordionActive &&
                this.props.accordionActive["catering" + this.props.tripLegName]
                  ? { display: "none" }
                  : { display: "flex" }
              }
            >
              <button
                className="button-wrapper addRow catering"
                onClick={() =>
                  this.props.openAccordion({
                    accordionName: "catering" + this.props.tripLegName,
                  })
                }
              >
                Add Catering
              </button>
            </Grid.Column>
          </Grid.Row>
        ) : null}

        <CateringRow
          isChangedValue={isChangedValue}
          tmpValues={tmpValues}
          legValues={legValues}
          tripLegName={this.props.tripLegName}
        />

        <Grid.Row className="borderBottom forSummary"></Grid.Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accordionActive:
      Object.keys(state.ui.bookTrip.accordion).length > 0
        ? state.ui.bookTrip.accordion
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(
      newBookTripFieldValues(
        Object.assign(fieldAndValue, { isChangedValues: true })
      )
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroundTransportAndCatering);
