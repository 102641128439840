import PropTypes from "prop-types";

const ActivityRow = ({ log, user }) => {
  let astroUserIdsImpact = "",
    statusText = "";
  let loguser = "";
  if (log.user) {
    loguser = log.user.substring(0, 1);
  } else if (log.actions.firstName && log.actions.lastName) {
    loguser =
      log.actions.firstName.substring(0, 1) +
      log.actions.lastName.substring(0, 1);
  } else {
    loguser = user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  }
  const actions = log.actions,
    hours = 12,
    iconName =
      log.firstName && log.lastName
        ? `${log.firstName.substring(0, 1)} ${log.lastName.substring(0, 1)}`
        : loguser,
    impactUser = `${user.firstName} ${user.lastName}`,
    logdate = new Date(log.dateUpdated),
    pmOrAm = logdate.getHours() > hours ? " PM" : " AM",
    time = `${logdate.getHours()}:${logdate.getMinutes()}${pmOrAm}`;
  if (actions) {
    if (actions.status === "Pending") {
      statusText = " registered an account.";
    } else if (actions.status) {
      statusText = `${actions.status.toLowerCase()} ${impactUser}`;
    } else if (actions.astroUserIds.Added) {
      statusText = `associated ${impactUser} to the following Astro UserID: `;
      astroUserIdsImpact = actions.astroUserIds.Added;
    } else if (actions.astroUserIds.Removed) {
      statusText = `disassociated ${impactUser} to the following Astro UserID: `;
      astroUserIdsImpact = actions.astroUserIds.Removed;
    } else if (actions.firstName) {
      statusText = `changed ${impactUser}'s First name`;
    } else if (actions.lastName) {
      statusText = `changed ${impactUser}'s Last name`;
    } else if (actions.userSpecifiedAccount) {
      statusText = `changed ${impactUser}'s Organization`;
    } else if (actions.isEmployee) {
      statusText = `made ${impactUser} an Owner Services employee.`;
    } else if (!actions.isEmployee) {
      statusText = `removed ${impactUser}'s Owner Services employee status.`;
    }
  }
  return (
    <div className="item-change">
      <div className="icon">{iconName}</div>
      <div className="log">
        <div className="middle">
          {log.firstName && log.lastName
            ? `${log.firstName} ${log.lastName}`
            : log.user}{" "}
          {statusText}
        </div>
      </div>
      <div className="time">
        <div className="middle">{time}</div>
      </div>
      <div className="clear"></div>
      <ul className="astro-list">
        {astroUserIdsImpact
          ? astroUserIdsImpact.map((astro, key) => <li key={key}>{astro}</li>)
          : ""}
      </ul>
    </div>
  );
};

ActivityRow.propTypes = {
  log: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
export default ActivityRow;
