import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { paths } from "routes";
import PendingScreen from "./PendingScreen";
import SignUp from "./SignUp";
import SignUpLoadding from "./SignUpLoadding";
import SignUpModal from "./SignUpModal";

export const CreateEasAccount = () => {
  const history = useNavigate();
  const signUpResult = useSelector((state) => state.services.signUp.result);
  const loggedInUser = useSelector((state) => state.services.easUsers.loggedIn);
  const logout = useCallback(() => {
    history(paths.build.logout());
  }, [history]);

  const easUserExists = loggedInUser.exists;
  const easUserStatus =
    loggedInUser.user && loggedInUser.exists === "yes"
      ? loggedInUser.user.status
      : "";

  if (signUpResult.loading === "unknown") {
    return <SignUpLoadding />;
  } else if (signUpResult.isOpen) {
    return (
      <SignUpModal
        isOpenModal={signUpResult.isOpen}
        typeOfModal={signUpResult.typeOfModal}
        logout={logout}
      />
    );
  } else if (easUserExists === "no") {
    return <SignUp />;
  } else if (
    easUserStatus &&
    !["authorized", "partial"].includes(easUserStatus.toLowerCase())
  ) {
    return <PendingScreen />;
  }

  return <Navigate to={paths.build.root()} />;
};
