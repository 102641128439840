import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import { ASTRO_USER_ACCOUNTS } from "../../actions/types";

const defaultAstroAccountSearchValue = {
  loading: false,
  failed: false,
  astroUserId: null,
  accounts: [],
};

const userAccounts = handleAction(
  ASTRO_USER_ACCOUNTS,
  {
    next(state, action) {
      let result = {};

      if (typeof action.payload === "string") {
        let accounts = [];
        //If we didn't switch to a new astro user id, don't clear the [] of accounts. Right choice? not sure

        if (action.payload === state.astroUserId) {
          accounts = state.accounts;
        }
        result = {
          loading: true,
          failed: false,
          astroUserId: action.payload,
          accounts,
        };
      } else if (typeof action.payload === "object") {
        result = {
          loading: false,
          failed: false,
          astroUserId: action.payload.astroUserId,
          accounts: action.payload.accounts,
        };
      }
      return result;
    },
    throw(state, action) {
      return {
        loading: false,
        failed: true,
        astroUserId: state.astroUserId,
        accounts: [],
      };
    },
  },
  defaultAstroAccountSearchValue
);

export default combineReducers({ userAccounts });
