import ContentFrame from "components/ContentFrame/ContentFrame";
import UserRow from "components/UserRow";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import withRouter from "components/withRouter";
import { paths } from "routes";
import {
  execShadedSearchUsers,
  execShadedUpdateUserStatus,
} from "store/actions/ui/eas-users";
import {
  popUserAuthorization,
  popUserStatusChange,
} from "store/actions/ui/popups";

const USER_STATUS = { pending: "Reject", partial: "Authorized" };

class PendingUsers extends Component {
  componentDidMount() {
    this.props.searchUsers("status=Pending,Partial");
  }

  handleKeyPress(event) {
    const { router } = this.props;
    if (event.key === "Enter") {
      router.navigate(paths.build.userSearch(this.query.value));
    }
  }

  render() {
    const { router } = this.props;
    let countPendingUser = 0;

    return (
      <ContentFrame>
        <div className="page-user">
          <section className="showUser">
            <div className="displayuser">
              <div className="head ">
                <input
                  type="search"
                  onKeyPress={this.handleKeyPress.bind(this)}
                  ref={(search) => {
                    this.query = search;
                  }}
                  placeholder="Search for a person"
                />
                <p>Access Requests</p>
              </div>
              <div className="content">
                <div className="Heading">
                  <div className="Cell">
                    <p>Name</p>
                  </div>
                  <div className="Cell">
                    <p>Email</p>
                  </div>
                  <div className="Cell">
                    <p>Organization</p>
                  </div>
                  <div className="Cell">
                    <p>Email Verified?</p>
                  </div>
                  <div className="Cell">
                    <p>Account Status</p>
                  </div>
                  <div className="Cell">
                    <p></p>
                  </div>
                </div>
                {this.props.users
                  ? this.props.users.map((item, index) => {
                      const currentStatus = item.status.toLowerCase();

                      countPendingUser += 1;
                      return (
                        <UserRow
                          user={item}
                          manageUser={() =>
                            router.navigate(
                              paths.build.userDetails(
                                item.systemUserId,
                                "details"
                              )
                            )
                          }
                          popAuthorize={this.props.popAuthorize(
                            item.systemUserId
                          )}
                          authorizeUserStatus={this.props.authorizeUserStatus(
                            item.systemUserId,
                            USER_STATUS[currentStatus]
                          )}
                          popReject={this.props.popUserStatus({
                            userId: item.systemUserId,
                            existingStatus: item.status,
                            newStatus: USER_STATUS[currentStatus],
                          })}
                          key={index}
                        />
                      );
                    })
                  : null}
              </div>
              {this.props.users && countPendingUser === 0 ? (
                <div className="noResult">
                  <p>No New Access Requests</p>
                </div>
              ) : null}
            </div>
          </section>
          {this.props.children}
        </div>
      </ContentFrame>
    );
  }
}

PendingUsers.contextTypes = { router: PropTypes.object };

PendingUsers.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  searchUsers: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  popAuthorize: PropTypes.func.isRequired,
  popUserStatus: PropTypes.func.isRequired,
  authorizeUserStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const easUsers = state.services.easUsers;

  return {
    users: easUsers.all.list,
    loading: easUsers.all.loading,
    failed: easUsers.all.failed,
    userId: state.services.firebase.firebaseUser.response.uid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchUsers: (searchString) => dispatch(execShadedSearchUsers(searchString)),
  popAuthorize: (userId) => () => {
    dispatch(popUserAuthorization(userId));
  },
  popUserStatus: (userStatus) => () => {
    dispatch(popUserStatusChange(userStatus));
  },
  authorizeUserStatus: (currentUserId, userStatus) => () => {
    dispatch(execShadedUpdateUserStatus(currentUserId, userStatus)).then(() => {
      dispatch(execShadedSearchUsers("status=Pending,Partial"));
    });
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingUsers)
);
