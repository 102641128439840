import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import withRouter from "components/withRouter";
import { paths } from "routes";
import { Button, Container, Dropdown, Grid } from "semantic-ui-react";
import {
  accordionTripQuest,
  addOrRemovePassenger,
  addOrRemoveTripLeg,
  execShadedPutTripRequest,
  newBookTripFieldValues,
} from "store/actions/ui/eas-book-trip";
import LegRow from "./LegRow";

let tripLegRow = 1;
class Legs extends Component {
  componentDidMount() {
    const steps = this.props.steps;
    if (steps[0] === "legs") {
      if (Object.keys(this.props.rowLegFields).length > 0) {
        const lastLegFields = Object.keys(this.props.rowLegFields)[
          Object.keys(this.props.rowLegFields).length - 1
        ];
        tripLegRow = lastLegFields.split("_")[1];
      }
      this.props.resetPassengerRequest(
        "Passenger_1",
        this.props.firstPassenger,
        steps[0]
      );
      Object.keys(this.props.rowLegFields).forEach((rowLeg) => {
        if (
          !this.props.rowLegFields[rowLeg].values.fromAirportTransportion ||
          !this.props.rowLegFields[rowLeg].values.toAirportTransportion ||
          !this.props.rowLegFields[rowLeg].values.additionalInformation
        ) {
          this.props.openAccordion({
            accordionName: "groundTransportation" + rowLeg,
            forced: false,
          });
        }
        if (!this.props.rowLegFields[rowLeg].values.catering) {
          this.props.openAccordion({
            accordionName: "catering" + rowLeg,
            forced: false,
          });
        }
      });
    }
  }
  addMoreTripLeg() {
    return () => {
      tripLegRow++;
      this.props.tripProps.addOrRemoveTripLeg({
        actionType: "ADD",
        legNumber: tripLegRow,
        triplegRow: LegRow,
        tripLegName: "TripLeg_" + tripLegRow,
      });
    };
  }
  render() {
    return (
      <div className="tripWidth">
        {
          <Grid.Row>
            <Container className="noPaddingTop">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div className="tripTitle">Where are you headed?</div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="chooseAccounts">
                    <div className="subTitle">
                      Which account do you want to use for your trip?
                    </div>
                    <Dropdown
                      value={this.props.firstTripLeg.values.accountId}
                      placeholder="Select account"
                      search
                      selection
                      options={this.props.accountOptions}
                      onChange={(event, data) => {
                        this.props.newBookTripFieldValues({
                          tripLegName: this.props.firstLegName,
                          tripLegFields: {
                            accountId: data.value,
                            accountName: event.target.textContent,
                          },
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Container className="noPaddingTop customMargin">
                {this.props.legChildren}
                <button
                  className="button-wrapper addLeg"
                  onClick={this.addMoreTripLeg()}
                >
                  Add leg
                </button>
              </Container>
              <Grid className="btnSubmit">
                <Grid.Row>
                  <Grid.Column className="right floated">
                    <Button
                      secondary
                      onClick={() => {
                        this.props.resetBookTripRequest(
                          this.props.firstLegName,
                          this.props.firstTripLeg
                        );
                        this.props.push(paths.build.flights());
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.nextStep({ step: "who", from: "legs" });
                      }}
                      primary
                      disabled={!this.props.accountId}
                    >
                      Next
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Row>
        }
      </div>
    );
  }
}

Legs.contextTypes = {
  router: PropTypes.object,
};

Legs.propTypes = {};

const mapStateToProps = (state) => {
  let firstLeg =
    Object.keys(state.ui.bookTrip.fields).length > 0
      ? state.ui.bookTrip.fields[Object.keys(state.ui.bookTrip.fields)[0]]
      : null;
  return {
    loggedInUser: state.services.easUsers.loggedIn.user,
    rowLegFields: state.ui.bookTrip.fields,
    steps: state.ui.steps,
    firstTripLeg: firstLeg,
    accountId: firstLeg ? firstLeg.values.accountId : 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(newBookTripFieldValues(fieldAndValue));
  },
  putTripRequest: (initialTripRequestData) => {
    dispatch(execShadedPutTripRequest(initialTripRequestData)).then(() => {});
  },
  addOrRemoveTripLeg: (payload) => {
    dispatch(addOrRemoveTripLeg(payload));
  },
  resetPassengerRequest: (passengerName, firstPassenger, step) => {
    dispatch(
      addOrRemovePassenger({
        passengerName: passengerName,
        actionType: "RESET",
        step: step,
      })
    );
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Legs));
