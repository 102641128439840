import { generatePath } from "react-router";

/**
 * All of the available routes in the app
 */
const routeDefinitions = {
  root: "/",
  construction: "/construction",
  admin: "/admin",
  userDetails: "/user/:userId/:detailsOrTransactions",
  userSearch: "/user-search/:encodedSearchString",
  flights: "/flights",
  flightsMineOrAll: "/flights/:mineOrAll",
  flightsMineOrAllWithTime: "/flights/:mineOrAll/:timeRange",
  bookATrip: "/book-a-trip",
  createUser: "/create-user",
  createEasAccount: "/create-eas-account",
  notFound: "/not-found",
  logout: "/logout",
};

type Definitions = typeof routeDefinitions;

const asPathBuilder = <
  T extends {
    [K in keyof Definitions | keyof T]: K extends keyof Definitions
      ? unknown
      : never;
  }
>(
  pathBuilder: T
) => pathBuilder;

/**
 * Builds a route URL
 */
const pathBuilder = asPathBuilder({
  root: () => generatePath(routeDefinitions.root),
  construction: () => generatePath(routeDefinitions.construction),
  admin: () => generatePath(routeDefinitions.admin),
  userDetails: (userId: string, detailsOrTransactions: string) =>
    generatePath(routeDefinitions.userDetails, {
      userId,
      detailsOrTransactions,
    }),
  userSearch: (encodedSearchString: string) =>
    generatePath(routeDefinitions.userSearch, { encodedSearchString }),
  flights: () => generatePath(routeDefinitions.flights),
  flightsMineOrAll: (mineOrAll: string) =>
    generatePath(routeDefinitions.flightsMineOrAll, { mineOrAll }),
  flightsMineOrAllWithTime: (mineOrAll: string, timeRange: string) =>
    generatePath(routeDefinitions.flightsMineOrAllWithTime, {
      mineOrAll,
      timeRange,
    }),
  bookATrip: () => generatePath(routeDefinitions.bookATrip),
  createUser: () => generatePath(routeDefinitions.createUser),
  createEasAccount: () => generatePath(routeDefinitions.createEasAccount),
  notFound: () => generatePath(routeDefinitions.notFound),
  logout: () => generatePath(routeDefinitions.logout),
});

export const paths = {
  build: pathBuilder,
  definition: routeDefinitions,
};
