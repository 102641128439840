import PropTypes from "prop-types";
import { analyticsEvent } from "analytics/gtag";
import { connect } from "react-redux";
import { execSignUp, newSignUpFieldValue } from "store/actions/ui/sign-up";
import iconWhiteStacked from "images/airshare_white_stacked.svg";
import { Component } from "react";

class SignUp extends Component {
  componentDidMount() {
    const { loggedInUser } = this.props;
    const userId = loggedInUser.user ? loggedInUser.user.systemUserId : null;

    // Tracking signup screen
    analyticsEvent("new_login", {
      event_category: "authorization",
      event_label: userId,
    });

    this.props.newFieldValues({
      emailAddress: this.props.emailAddress,
      firstName: this.props.initialFirstName,
      lastName: this.props.initialLastName,
    });
  }

  textFieldInput(fieldName, placeholder) {
    return (
      <input
        type="text"
        name={fieldName}
        placeholder={placeholder}
        value={this.props[fieldName]}
        onChange={(event) => {
          this.props.newFieldValues({ [fieldName]: event.target.value });
        }}
        className={`styleInput ${this.props.valid[fieldName] ? "" : " error"}`}
        ref={fieldName}
      />
    );
  }

  nextButton() {
    return (
      <button
        className="button-style"
        disabled={!this.props.valid.firstName || !this.props.valid.lastName}
        onClick={this.props.nextSaveUser}
      >
        Next
      </button>
    );
  }

  render() {
    return (
      <div className="firebase-ui login-page">
        <div className="signupBox">
          <div className="logoMain">
            <img src={iconWhiteStacked} alt="" />
          </div>
          <div className="signup-page">
            <div className="inner">
              <h3>What&#x27;s your name and organization?</h3>
              <div className="row">
                {this.textFieldInput("firstName", "First Name")}
              </div>
              <div className="row">
                {this.textFieldInput("lastName", "Last Name")}
              </div>
              <div className="row">
                {this.textFieldInput("userSpecifiedAccount", "Organization")}
              </div>
              <div className="button-group">{this.nextButton()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  valid: PropTypes.object.isRequired,
  initialFirstName: PropTypes.string.isRequired,
  initialLastName: PropTypes.string.isRequired,
  newFieldValues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const firebaseUser = state.services.firebase.firebaseUser;
  let nameSplit = "",
    emailAddress = "";
  if (firebaseUser) {
    nameSplit = firebaseUser.response.displayName
      ? firebaseUser.response.displayName.split(/\s/)
      : null;
    emailAddress = firebaseUser.response.email;
  }
  return {
    loggedInUser: state.services.easUsers.loggedIn,
    emailAddress,
    firstName: state.ui.signUp.fields.values.firstName,
    valid: state.ui.signUp.fields.valid,
    lastName: state.ui.signUp.fields.values.lastName,
    initialFirstName: nameSplit
      ? nameSplit.length > 0
        ? nameSplit[0]
        : ""
      : "",
    initialLastName: nameSplit
      ? nameSplit.length > 1
        ? nameSplit[1]
        : ""
      : "",
  };
};

const mapDispatchToProps = (dispatch) => ({
  nextSaveUser: () => dispatch(execSignUp()),
  newFieldValues: (fieldAndValue) => {
    dispatch(newSignUpFieldValue(fieldAndValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
