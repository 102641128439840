import { Component } from "react";

export class ErrorCatch extends Component {
  state = {
    hasError: null,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    return <div>We're sorry an error occured. Try to refresh the page.</div>;
  }
}
