import { FC, createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, RouteProps } from "react-router";
import { paths } from "routes";

type AppState = any;


function AuthenticationRoute({ children }: { children: JSX.Element }) {
    const loggedInUser = useSelector(
      // User will have 2 status: pending and authorized
      (state: AppState) => state.services.easUsers.loggedIn

    );


    if (loggedInUser.exists === "yes") {
      if (loggedInUser.user.isEmployee) {
        return <Navigate to={paths.build.admin()} />;
      } else {
        return (
          <Navigate
            to={paths.build.flightsMineOrAllWithTime("mine", "upcoming")}
          />
        );
      }
    }

  return children;
}
export default AuthenticationRoute;
