import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, RouteProps, useLoaderData, useLocation, useMatches, useParams } from "react-router";
import { paths } from "routes";

type AppState = any;

function FlightsMineOrAllRoute({ children }: { children: JSX.Element }) {

    let { mineOrAll } = useParams();
    
    if (["mine", "all"].includes(mineOrAll ?? "")) {
        return (
            <Navigate
                to={paths.build.flightsMineOrAllWithTime(
                    mineOrAll ?? "mine",
                    "upcoming"
                )}
            />
        );
    }
    return <Navigate to={paths.build.root()} />;
}

export default FlightsMineOrAllRoute;
