import { combineReducers } from "redux";
import { LOGOUT } from "store/actions/types";
import network from "./network";
import services from "./services";
import ui from "./ui";
import userAstroIdsEdit from "./user-astro-ids-edit";

const reducer = combineReducers({
  network,
  userAstroIdsEdit,
  services,
  ui,
});

export default function rootReducer(state, action) {
  if (action.type === LOGOUT) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
}
