const CallAirCarePopup = ({ closeCallAirCare }) => {
  return (
    <section className="popup callaircare-popup">
      <div className="bg_overlay"></div>
      <div className="inner">
        <div className="content">
          <h2>800-946-4900</h2>
        </div>
        <div className="footer">
          <a href="tel: 877.946.4900" className="buttonStyle Call">
            Call
          </a>
          <button className="buttonStyle Cancel" onClick={closeCallAirCare}>
            Cancel
          </button>
        </div>
      </div>
    </section>
  );
};

export default CallAirCarePopup;
