import ContentLoader from "react-content-loader";

const FlightDetailLoading = () => {
  return (
    <div className="cardDetailLoader">
      <div className="flightCardsLoader">
        <div className="cardBanner">
          <ContentLoader
            height={200}
            width={600}
            speed={2}
            backgroundColor="#cccccf"
          >
            <rect x="25" y="125" rx="0" ry="0" width="267" height="23" />
            <rect x="25" y="162" rx="0" ry="0" width="114" height="12" />
          </ContentLoader>
        </div>
        <div className="cardInformation">
          <ContentLoader height={225} width={600} speed={2}>
            <rect x="25" y="23" rx="0" ry="0" width="34" height="12" />
            <rect x="25" y="42" rx="0" ry="0" width="88" height="12" />
            <rect x="25" y="61" rx="0" ry="0" width="108" height="12" />
            <rect x="467" y="23" rx="0" ry="0" width="34" height="12" />
            <rect x="467" y="42" rx="0" ry="0" width="88" height="12" />
            <rect x="467" y="61" rx="0" ry="0" width="108" height="12" />
            <rect x="24" y="100" rx="0" ry="0" width="552" height="1" />
            <rect x="25" y="120" rx="0" ry="0" width="12" height="12" />
            <rect x="25" y="140" rx="0" ry="0" width="12" height="12" />
            <rect x="45" y="120" rx="0" ry="0" width="140" height="12" />
            <rect x="45" y="140" rx="0" ry="0" width="140" height="12" />
            <rect x="0" y="173" rx="0" ry="0" width="600" height="1" />
            <rect x="17" y="192" rx="0" ry="0" width="12" height="12" />
            <rect x="37" y="192" rx="0" ry="0" width="60" height="12" />
            <rect x="260" y="192" rx="0" ry="0" width="12" height="12" />
            <rect x="280" y="192" rx="0" ry="0" width="60" height="12" />
            <rect x="504" y="192" rx="0" ry="0" width="12" height="12" />
            <rect x="524" y="192" rx="0" ry="0" width="60" height="12" />
            <rect x="524" y="192" rx="0" ry="0" width="60" height="12" />
          </ContentLoader>
        </div>
      </div>
      <div className="itineraryCardLoader">
        <ContentLoader height={206} width={600} speed={2}>
          <rect x="23" y="18" rx="0" ry="0" width="100" height="12" />
          <rect x="23" y="58" rx="0" ry="0" width="16" height="16" />
          <rect x="54" y="59" rx="0" ry="0" width="34" height="12" />
          <rect x="54" y="78" rx="0" ry="0" width="212" height="12" />
          <rect x="54" y="96" rx="0" ry="0" width="142" height="12" />
          <rect x="23" y="134" rx="0" ry="0" width="16" height="16" />
          <rect x="54" y="135" rx="0" ry="0" width="34" height="12" />
          <rect x="54" y="154" rx="0" ry="0" width="212" height="12" />
          <rect x="54" y="173" rx="0" ry="0" width="142" height="12" />
        </ContentLoader>
      </div>
      <div className="passengerCardLoader">
        <ContentLoader height={270} width={600} speed={2}>
          <rect x="23" y="18" rx="0" ry="0" width="100" height="12" />
          <circle cx="39" cy="82" r="20" />
          <rect x="71" y="76" rx="0" ry="0" width="152" height="12" />
          <circle cx="39" cy="154" r="20" />
          <rect x="71" y="148" rx="0" ry="0" width="152" height="12" />
          <circle cx="39" cy="226" r="20" />
          <rect x="71" y="220" rx="0" ry="0" width="152" height="12" />
        </ContentLoader>
      </div>
      <div className="airCardLoader">
        <ContentLoader height={315} width={600} speed={2}>
          <rect x="23" y="18" rx="0" ry="0" width="170" height="22" />
          <rect x="23" y="47" rx="0" ry="0" width="152" height="12" />
          <rect x="23" y="75" rx="0" ry="0" width="80" height="80" />
          <rect x="105" y="75" rx="0" ry="0" width="80" height="80" />
          <rect x="187" y="75" rx="0" ry="0" width="80" height="80" />
          <rect x="269" y="75" rx="0" ry="0" width="80" height="80" />
          <rect x="351" y="75" rx="0" ry="0" width="80" height="80" />
          <rect x="433" y="75" rx="0" ry="0" width="80" height="80" />
          <circle cx="39" cy="203" r="20" />
          <rect x="71" y="197" rx="0" ry="0" width="152" height="12" />
          <circle cx="39" cy="275" r="20" />
          <rect x="71" y="269" rx="0" ry="0" width="152" height="12" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default FlightDetailLoading;
