import dotEnv from "environment-config";
import { createAction } from "redux-actions";
import { v1 } from "uuid";
import { PUT_TRIP_REQUEST } from "../types";

const putTripRequestUrl = (tripRequestId) =>
  `${dotEnv.apiUrl}/tripRequests/${tripRequestId}`;
export const putTripRequest = createAction(PUT_TRIP_REQUEST);

export const execPutTripRequest = (initialTripRequestData) => (
  dispatch,
  getState
) => {
  const url = putTripRequestUrl(v1().toUpperCase());
  const obj = {
    method: "PUT",
    body: JSON.stringify(initialTripRequestData),
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  dispatch(putTripRequest());
  return fetch(url, obj)
    .then((response) => {
      if (response.ok) {
        dispatch(putTripRequest({ result: response.ok }));
        return Promise.resolve();
      }
      return Promise.reject(new Error("Error putting trip request"));
    })
    .catch((error) => {
      dispatch(putTripRequest(error));
      return Promise.reject(error);
    });
};
