import PropTypes from "prop-types";

const ConfirmSendResetPassword = ({ emailAddress, closeResetPopup }) => (
  <section className="popup reset-popup">
    <div className="bg_overlay"></div>
    <div className="inner">
      <div className="content">
        <h2>Reset Password</h2>
        <p>
          We’ve sent an email to {emailAddress}. Click the link in the email to
          reset password.
        </p>
      </div>
      <div className="footer">
        <button className="buttonStyle Cancel" onClick={closeResetPopup}>
          Close
        </button>
      </div>
    </div>
  </section>
);

ConfirmSendResetPassword.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  closeResetPopup: PropTypes.func.isRequired,
};
export default ConfirmSendResetPassword;
