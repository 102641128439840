import { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Grid, TextArea } from "semantic-ui-react";
import {
  accordionTripQuest,
  newBookTripFieldValues,
} from "store/actions/ui/eas-book-trip";

class GroundTransportRow extends Component {
  resetAdditionnalValues(accordionName, values) {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      tripLegFields: values,
    });
    this.props.openAccordion({
      accordionName: accordionName + this.props.tripLegName,
      forced: false,
    });
  }
  render() {
    let airports = [
      { key: "1", value: "Rental Car", text: "Rental Car" },
      {
        key: "2",
        value: "Chauffeur/Car Service",
        text: "Chauffeur/Car Service",
      },
      { key: "3", value: "Taxi", text: "Taxi" },
      { key: "4", value: "Self-arranged", text: "Self-arranged" },
      { key: "5", value: "Other", text: "Other" },
    ];
    const isChangedValue = this.props.isChangedValue;
    const tmpValues = this.props.tmpValues;
    const legValues = this.props.legValues;
    return (
      <Grid
        className="transportCol"
        style={
          this.props.accordionActive &&
          this.props.accordionActive[
            "groundTransportation" + this.props.tripLegName
          ]
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <Grid.Row className="borderTransportation"></Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <button
              className="button-wrapper removeLeg"
              onClick={() =>
                this.resetAdditionnalValues("groundTransportation", {
                  toAirportTransportion: "",
                  fromAirportTransportion: "",
                  additionalInformation: "",
                })
              }
            ></button>
            <div className="legTitle">Ground Transportation</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="chooseAirPort last" columns={2}>
          <Grid.Column className="col tenCol">
            <Grid.Row>
              <span className="clsTitleL">To the airport</span>
              <Grid>
                <Grid.Row className="noPaddingLR">
                  <Grid.Column className="col">
                    <Dropdown
                      value={
                        isChangedValue &&
                        isChangedValue["toAirportTransportion"]
                          ? tmpValues.toAirportTransportion
                          : legValues.toAirportTransportion
                      }
                      placeholder="Select a service"
                      selection
                      options={airports}
                      onChange={(event, data) => {
                        this.props.newBookTripFieldValues({
                          tripLegName: this.props.tripLegName,
                          field: "toAirportTransportion",
                          value: data.value,
                          saveToTmp: this.props.saveToTmp,
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className="noPaddingR col tenCol">
            <Grid.Row>
              <span className="clsTitleL">From the airport</span>
              <Grid>
                <Grid.Row className="noPaddingLR">
                  <Grid.Column className="col">
                    <Dropdown
                      value={
                        isChangedValue &&
                        isChangedValue["fromAirportTransportion"]
                          ? tmpValues.fromAirportTransportion
                          : legValues.fromAirportTransportion
                      }
                      placeholder="Select a service"
                      selection
                      options={airports}
                      onChange={(event, data) => {
                        this.props.newBookTripFieldValues({
                          tripLegName: this.props.tripLegName,
                          field: "fromAirportTransportion",
                          value: data.value,
                          saveToTmp: this.props.saveToTmp,
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="chooseAirPort additional">
          <Grid.Column className="noPaddingR col tenCol">
            <Grid.Row>
              <span className="noTop">Additional Information</span>
              <Grid>
                <Grid.Row className="noPaddingLR">
                  <Grid.Column className="col">
                    <TextArea
                      value={
                        isChangedValue &&
                        isChangedValue["additionalInformation"]
                          ? tmpValues.additionalInformation
                          : legValues.additionalInformation
                      }
                      style={{ minHeight: 64 }}
                      className="textarea"
                      onChange={(event) => {
                        this.props.newBookTripFieldValues({
                          tripLegName: this.props.tripLegName,
                          field: "additionalInformation",
                          value: event.target.value,
                          saveToTmp: this.props.saveToTmp,
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accordionActive:
      Object.keys(state.ui.bookTrip.accordion).length > 0
        ? state.ui.bookTrip.accordion
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(
      newBookTripFieldValues(
        Object.assign(fieldAndValue, { isChangedValues: true })
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GroundTransportRow);
