import ContentFrame from "components/ContentFrame/ContentFrame";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
// import { useMatch } from "react-router";
import withRouter from "components/withRouter";
import { paths } from "routes";
import { Container, Grid } from "semantic-ui-react";
import { putTripRequest } from "store/actions/services/eas-book-trip";
import { useMatch } from "react-router-dom";
import {
  addOrRemovePassenger,
  addOrRemoveTripLeg,
  newBookTripFieldValues,
  nextStep,
  previousStep,
  resetTripStep,
} from "store/actions/ui/eas-book-trip";
import TripRequestConfirmation from "./TripRequestConfirmation";
import LegRow from "./LegRow";
import Legs from "./Legs";
import Summary from "./Summary";
import WhoIsFlyingWithYou from "./WhoIsFlyingWithYou";

const BookATrip = (props) => {
  const matchRoute = useMatch({
    path: paths.definition.bookATrip,
    exact: true,
  });

  useEffect(() => {
    return () => {
      if (!matchRoute) {
        props.resetBookTripRequest(props.firstLegName, props.firstTripLeg);
      } else {
        props.resetStep();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const legChildren = [];
  let maxPassenger = 1;
  Object.keys(props.rowTripLeg).forEach((rowLeg, key) => {
    legChildren.push(
      <LegRow
        legNumber={key + 1}
        key={key}
        tripLegName={rowLeg}
        rowLegData={props}
      />
    );
    maxPassenger =
      maxPassenger < props.rowTripLeg[rowLeg].values.passengerCount
        ? props.rowTripLeg[rowLeg].values.passengerCount
        : maxPassenger;
  });
  let accountOptions = [];
  let accounts = props.accounts;
  if (accounts) {
    accounts.forEach((account, key) => {
      if (account.role.toLowerCase() !== "passenger") {
        accountOptions.push({
          key: key,
          value: account.accountId,
          text: account.accountName,
          selected: key === 0,
        });
      }
    });
  }
  let steps = props.steps;
  let currentStep = [];
  if (steps[0] === "legs") {
    currentStep = (
      <Legs
        accountOptions={accountOptions}
        nextStep={props.nextStep}
        prevStep={props.prevStep}
        firstLegName={props.firstLegName}
        firstTripLeg={props.firstTripLeg}
        tripProps={props}
        maxPassenger={maxPassenger}
        legChildren={legChildren}
        resetBookTripRequest={props.resetBookTripRequest}
      />
    );
  } else if (steps[0] === "who") {
    currentStep = (
      <WhoIsFlyingWithYou
        nextStep={props.nextStep}
        prevStep={props.prevStep}
        firstLegName={props.firstLegName}
        firstTripLeg={props.firstTripLeg}
        maxPassenger={maxPassenger}
      />
    );
  } else if (steps[0] === "summary") {
    currentStep = (
      <Summary
        accountOptions={accountOptions}
        nextStep={props.nextStep}
        prevStep={props.prevStep}
      />
    );
  } else if (steps[0] === "confirmation") {
    currentStep = (
      <TripRequestConfirmation
        firstLegName={props.firstLegName}
        firstTripLeg={props.firstTripLeg}
        resetBookTripRequest={props.resetBookTripRequest}
      />
    );
  }
  return (
    <ContentFrame>
      <div className="flightScreen displayFlex">
        <div className="bookATrip">
          <Container fluid className="flightHeader">
            <Grid.Row>
              <Container>
                <Grid columns={2} divided="vertically">
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <h4>Trip Request</h4>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Grid.Row>
          </Container>
          <div className="hasScroll">
            <Container className={"tripRequest " + steps[0]}>
              {currentStep}
            </Container>
          </div>
        </div>
      </div>
    </ContentFrame>
  );
};

BookATrip.contextTypes = {
  router: PropTypes.object,
};

BookATrip.propTypes = {
  resetBookTripRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  let firstLeg =
    Object.keys(state.ui.bookTrip.fields).length > 0
      ? state.ui.bookTrip.fields[Object.keys(state.ui.bookTrip.fields)[0]]
      : null;
  return {
    requestResult: state.services.easTripRequest.tripRequest.body.result,
    loggedInUser: state.services.easUsers.loggedIn.user,
    rowTripLeg: state.ui.bookTrip.newTripLeg,
    rowLegFields: state.ui.bookTrip.fields,
    rowPassengers: state.ui.addPassenger.passengers,
    steps: state.ui.steps,
    accounts: state.services.easUsers.loggedInAccounts.list,
    accountId: firstLeg ? firstLeg.values.accountId : 0,
    firstTripLeg: firstLeg,
    firstLegName:
      Object.keys(state.ui.bookTrip.newTripLeg).length > 0
        ? Object.keys(state.ui.bookTrip.newTripLeg)[0]
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addOrRemoveTripLeg: (payload) => {
    dispatch(addOrRemoveTripLeg(payload));
  },
  resetBookTripRequest: (legName, firstLeg) => {
    dispatch(putTripRequest({ body: null, loading: false }));
    dispatch(addOrRemoveTripLeg({ tripLegName: legName, actionType: "RESET" }));
    dispatch(
      newBookTripFieldValues({
        tripLegName: legName,
        tripLegFields: {
          accountId: "",
          accountName: "",
          actionRequest: "",
          fromCity: "",
          fromAirport: "Any Airport",
          toCity: "",
          toAirport: "Any Airport",
          passengerCount: "",
          departureDate: moment().format("YYYY-MM-DD"),
          departOrArrive: "Depart by",
          departOrArriveTime: "",
          departTime: "",
          arriveTime: "",
          passengers: [],
          preferredAircraft: "",
          isChangedValue: {},
        },
        actionType: "RESET",
      })
    );
    dispatch( 
      addOrRemovePassenger({ passengerName: "Passenger1", actionType: "RESET" })
    );
    dispatch(resetTripStep());
  },
  nextStep: (step) => {
    dispatch(nextStep(step));
  },
  prevStep: () => {
    dispatch(previousStep());
  },
  resetStep: () => {
    dispatch(resetTripStep());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookATrip)
);
