import { createAction } from "redux-actions";
import {
  execGetItineraryForTrip,
  execSearchTrips,
} from "../services/eas-trips";
import {
  ACCORDION_FLIGHT_DETAIL,
  LIGHT_BOX_IS_OPEN,
  SELECT_FLIGHT_DETAIL,
} from "../types";
import { wrapAsyncThunk } from "./utilities";

export const selectFlightDetail = createAction(SELECT_FLIGHT_DETAIL);
export const accordionFlightDetail = createAction(ACCORDION_FLIGHT_DETAIL);
export const lightBoxIsOpen = createAction(LIGHT_BOX_IS_OPEN);
export const execNotShadedSearchTrips = ({
  userId,
  startDate,
  endDate,
  isLoadMore,
  mineOrAll,
  timeRange,
}) => {
  return wrapAsyncThunk(
    execSearchTrips({
      userId,
      startDate,
      endDate,
      isLoadMore,
      mineOrAll,
      timeRange,
    }),
    { needsShade: false, name: "shaded-search-trips" }
  );
};

export const execNotShadedGetItineraryForTrip = (astroId) => {
  return wrapAsyncThunk(execGetItineraryForTrip(astroId), {
    needsShade: false,
    name: "no-shade-get-itinerary-for-trip",
  });
};
