import { createAction } from "redux-actions";
import {
  AIRCARE_DROPDOWN,
  CLOSE_DROPDOWN,
  MENU_DROPDOWN,
  USER_DROPDOWN,
} from "../types";

export const userDropdown = createAction(USER_DROPDOWN);
export const menuDropdown = createAction(MENU_DROPDOWN);
export const closeDropdown = createAction(CLOSE_DROPDOWN);
export const aircareDropdown = createAction(AIRCARE_DROPDOWN);
