import clone from "clone";
import moment from "moment";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  ACCORDION_TRIP_REQUEST,
  ADD_OR_REMOVE_TRIP_LEG,
  NEW_BOOK_TRIP_FIELD_VALUES,
} from "../../actions/types";

const values = {
  accountId: "",
  accountName: "",
  actionRequest: "",
  fromCity: "",
  airportOptionForfromCity: [],
  fromAirport: "",
  airportOptionFortoCity: [],
  toCity: "",
  toAirport: "",
  passengerCount: "",
  departureDate: moment().format("YYYY-MM-DD"),
  departOrArrive: "Depart by",
  departTime: "",
  arriveTime: "",
  departOrArriveTime: "",
  passengers: [],
  setFocusFields: {},
  preferredAircraft: "",
  toAirportTransportion: "",
  fromAirportTransportion: "",
  additionalInformation: "",
  catering: "",
};
const defaultBookTripState = {
  values: values,
  valid: {
    actionRequest: false,
    fromCity: false,
    fromAirport: false,
    toCity: false,
    toAirport: false,
    departOrArriveTime: false,
    preferredAircraft: false,
  },
  tmpValues: {
    accountId: "",
    accountName: "",
    actionRequest: "",
    fromCity: "",
    airportOptionForfromCity: [],
    fromAirport: "",
    airportOptionFortoCity: [],
    toCity: "",
    toAirport: "",
    passengerCount: "",
    departureDate: "",
    departOrArrive: "",
    departTime: "",
    arriveTime: "",
    departOrArriveTime: "",
    passengers: "",
    preferredAircraft: "",
    isChangedValue: {},
    toAirportTransportion: "",
    fromAirportTransportion: "",
    additionalInformation: "",
    catering: "",
  },
};

let tripLegRows = { TripLeg_1: defaultBookTripState };
let getLastState = {};

const fields = handleAction(
  NEW_BOOK_TRIP_FIELD_VALUES,
  {
    next(state, action) {
      let newState = clone(state);
      const newValues = clone(tripLegRows[action.payload.tripLegName].values);
      const tmpValues = clone(
        tripLegRows[action.payload.tripLegName].tmpValues
      );
      if (action.payload.tripLegFields && !action.payload.saveToTmp) {
        Object.assign(newValues, action.payload.tripLegFields);
      }
      if (action.payload.saveToTmp) {
        if (
          action.payload.tripLegFields &&
          Object.keys(action.payload.tripLegFields).length > 0
        ) {
          tmpValues.isChangedValue = {};
          Object.assign(tmpValues, action.payload.tripLegFields);
        } else {
          if (
            ![
              "setFocusFields",
              "latLngForfromCity",
              "airportOptionForfromCity",
              "latLngFortoCity",
              "airportOptionFortoCity",
            ].includes(action.payload.field)
          ) {
            tmpValues.isChangedValue[action.payload.field] = true;
            Object.assign(tmpValues, {
              [action.payload.field]: action.payload.value,
            });
          } else {
            console.log(5);
            Object.assign(newValues, {
              [action.payload.field]: action.payload.value,
            });
          }
        }
      } else {
        if (action.payload.flag && action.payload.field) {
          Object.assign(tmpValues, {
            isChangedValue: action.payload.isChangedValue
              ? action.payload.isChangedValue
              : {},
          });
          Object.assign(tmpValues, newValues);
        } else {
          Object.assign(newValues, {
            [action.payload.field]: action.payload.value,
          });
          Object.assign(tmpValues, {
            isChangedValue: action.payload.isChangedValue
              ? action.payload.isChangedValue
              : {},
          });
        }
      }
      const valid = clone(tripLegRows[action.payload.tripLegName].valid);
      Object.assign(valid, {
        toCity: Boolean(newValues.toCity).valueOf(),
        fromAirport: Boolean(newValues.fromAirport).valueOf(),
        toAirport: Boolean(newValues.toAirport).valueOf(),
        departureDate: Boolean(newValues.departureDate).valueOf(),
        departOrArrive: Boolean(newValues.departOrArrive).valueOf(),
        departOrArriveTime: Boolean(newValues.departOrArriveTime).valueOf(),
        preferredAircraft: Boolean(newValues.preferredAircraft).valueOf(),
      });
      tripLegRows[action.payload.tripLegName] = {
        values: newValues,
        valid,
        tmpValues: tmpValues,
      };
      if (action.payload.actionType === "RESET") {
        newState = {};
      }
      Object.assign(newState, tripLegRows);
      getLastState = newState;
      return newState;
    },
  },
  tripLegRows
);

const valid = handleAction(
  NEW_BOOK_TRIP_FIELD_VALUES,
  {
    next(state, action) {
      const newState = clone(state);
      return newState;
    },
  },
  tripLegRows
);

const newTripLeg = handleAction(
  ADD_OR_REMOVE_TRIP_LEG,
  {
    next(state, action) {
      let newState = {};
      let newLastState = clone(tripLegRows);
      if (Object.keys(getLastState).length > 0) {
        newLastState = getLastState;
      }
      if (action.payload.actionType === "ADD") {
        const lastLeg =
          newLastState[
            Object.keys(newLastState)[Object.keys(newLastState).length - 1]
          ];
        const newValues = clone(lastLeg.values);
        const tmpValues = clone(lastLeg.tmpValues);
        Object.assign(newValues, {
          fromCity: lastLeg.values.toCity,
          fromAirport: lastLeg.values.toAirport,
          airportOptionForfromCity: lastLeg.values.airportOptionFortoCity
            ? lastLeg.values.airportOptionFortoCity
            : [],
          airportOptionFortoCity: [],
          toCity: "",
          toAirport: "Any Airport",
        });
        newLastState[action.payload.tripLegName] = {
          values: newValues,
          valid: {},
          tmpValues: tmpValues,
        };
        Object.assign(newState, newLastState);
      } else if (action.payload.actionType === "REMOVE") {
        delete newLastState[action.payload.tripLegName];
        Object.assign(newState, newLastState);
      } else if (action.payload.actionType === "RESET") {
        getLastState = { [action.payload.tripLegName]: defaultBookTripState };
        Object.assign(newState, getLastState);
      }
      tripLegRows = newState;
      return newState;
    },
  },
  tripLegRows
);

let accordionActived = {};
const accordion = handleAction(
  ACCORDION_TRIP_REQUEST,
  {
    next(state, action) {
      if (action.payload) {
        let actived = clone(state);
        if (action.payload.actionType === "RESET") {
          accordionActived = {};
        } else if (action.payload.forced != null) {
          accordionActived[action.payload.accordionName] =
            action.payload.forced;
        } else if (action.payload.accordionName) {
          accordionActived[action.payload.accordionName] = !accordionActived[
            action.payload.accordionName
          ];
        }
        Object.assign(actived, accordionActived);
        return actived;
      }
    },
  },
  accordionActived
);

export default combineReducers({ fields, valid, newTripLeg, accordion });
