export enum EnvType {
  Local = "local",
  Dev = "dev",
  Staging = "staging",
  Prod = "prod",
}

interface AppConfig {
  envType: EnvType;
  apiUrl: string;
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  storageBucket: string;
  messagingSenderId: string;
  emailAircare: string;
  projectId?: string;
}

const environment: Record<EnvType, AppConfig> = {
  local: {
    envType: EnvType.Local,
    apiUrl: "https://apistg.execairshare.com",
    apiKey: "AIzaSyAEKnWzbLeqVPkRTqSOGG802Nkm56txM_A",
    authDomain: "flyairshare-development.firebasestorage.app",
    databaseURL: "https://flyairshare-development-default-rtdb.firebaseio.com",
    storageBucket: "flyairshare-development.firebasestorage.app",
    messagingSenderId: "679612099832",
    emailAircare: "mock-eas-contact-aircare+dev@engagemobile.com",
    projectId: "flyairshare-development",
  },
  dev: {
    envType: EnvType.Dev,
    apiUrl: "https://apidev.execairshare.com",
    apiKey: "AIzaSyAEKnWzbLeqVPkRTqSOGG802Nkm56txM_A",
    authDomain: "flyairshare-development.firebasestorage.app",
    databaseURL: "https://flyairshare-development-default-rtdb.firebaseio.com",
    storageBucket: "flyairshare-development.firebasestorage.app",
    messagingSenderId: "679612099832",
    emailAircare: "mock-eas-contact-aircare@engagemobile.com",
  },
  staging: {
    envType: EnvType.Staging,
    apiUrl: "https://apistg.execairshare.com",
    apiKey: "AIzaSyAEKnWzbLeqVPkRTqSOGG802Nkm56txM_A",
    authDomain: "flyairshare-development.firebasestorage.app",
    databaseURL: "https://flyairshare-development-default-rtdb.firebaseio.com",
    storageBucket: "flyairshare-development.firebasestorage.app",
    messagingSenderId: "679612099832",
    emailAircare: "mock-eas-contact-aircare@engagemobile.com",
    projectId: "flyairshare-development",
  },
  prod: {
    envType: EnvType.Prod,
    apiUrl: "https://api.execairshare.com",
    apiKey: "AIzaSyAEKnWzbLeqVPkRTqSOGG802Nkm56txM_A",
    authDomain: "flyairshare-development.firebasestorage.app",
    databaseURL: "https://flyairshare-development-default-rtdb.firebaseio.com",
    storageBucket: "flyairshare-development.firebasestorage.app",
    messagingSenderId: "679612099832",
    emailAircare: "schedule@flyairshare.com",
    projectId: "flyairshare-development",
  },
};

const config = environment[process.env.REACT_APP_ENV];

console.log("config loaded:", process.env.REACT_APP_ENV);

if (!config) {
  throw new Error(
    `unknown REACT_APP_ENV variable: ${process.env.REACT_APP_ENV}`
  );
}

export default config;
