import { analyticsEvent } from "analytics/gtag";
import iconPhenom100 from "images/phenom100.svg";
import iconPhenom300 from "images/phenom300.svg";
import { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { connect } from "react-redux";
import { Dropdown, Grid, Image, Input } from "semantic-ui-react";
import { execNotShadedSearchAirports } from "store/actions/ui/airports";
import {
  accordionTripQuest,
  newBookTripFieldValues,
} from "store/actions/ui/eas-book-trip";
import GroundTransportAndCatering from "./GroundTransportAndCatering";

let isLoading = {},
  latLngFromAddress = "",
  isDisable = {},
  airportOps = {},
  isOpenDropdown = false;

class TripLegRow extends Component {
  handleChangeCity = (field, address) => {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      field: field,
      value: address,
      saveToTmp: this.props.saveToTmp,
    });
  };
  //set Rad = 25
  handleSelectCity = (field, address) => {
    // reset the values and set focus for airport fields
    const forField = field === "fromCity" ? "fromAirport" : "toAirport";
    document
      .getElementById("airport_" + this.props.tripLegName + forField)
      .focus();
    isDisable[this.props.tripLegName + "_latLngFor_" + forField] = true;
    isLoading[this.props.tripLegName + "_latLngFor_" + forField] = "loading";
    isOpenDropdown = false;
    //set value for the focus fields
    this.setValueForLocation(
      "setFocusFields",
      {
        ["airport_" + this.props.tripLegName + "_latLngFor_" + forField]: true,
      },
      this.props.saveToTmp
    );
    //set defaultValue after user select city fields
    this.setValueForLocation(forField, "Any Airport", this.props.saveToTmp);
    //set value address to reducer
    this.setValueForLocation(field, address, this.props.saveToTmp);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log("Success", latLng);
        latLngFromAddress = `${latLng.lat},${latLng.lng}`;
        this.setValueForLocation(
          "latLngFor" + field,
          latLngFromAddress,
          this.props.saveToTmp
        );
        this.props.searchAiports(
          this.props.tripLegName,
          "airportOptionFor" + field,
          this.props.saveToTmp,
          latLng.lat,
          latLng.lng,
          25
        );
      })
      .catch((error) => console.error("Error", error));
  };

  setValueForLocation(field, address, saveToTmp) {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      field: field,
      value: address,
      saveToTmp: saveToTmp,
    });
  }

  renderPlaceSearching(field, isChangedValue, tmpValues, legValues, cityValue) {
    const searchOptions = {
      types: ["(cities)"],
    };

    return (
      <PlacesAutocomplete
        value={cityValue}
        onChange={(address) => this.handleChangeCity(field, address)}
        onSelect={(address) => this.handleSelectCity(field, address)}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <Input
              icon="search"
              {...getInputProps({
                placeholder: "Select a City",
                className: "location-search-input",
                onBlur: (address) => {
                  let suggestionArray = [];
                  if (suggestions.length > 0) {
                    suggestions.forEach((suggestion) => {
                      const isInernational =
                        suggestion.terms &&
                        suggestion.terms.some(
                          (country) => country.value.toUpperCase() === "USA"
                        );
                      let suggestionText = suggestion.description;
                      if (isInernational) {
                        suggestionText =
                          suggestion.formattedSuggestion.mainText +
                          ", " +
                          suggestion.formattedSuggestion.secondaryText.replace(
                            ", USA",
                            ""
                          );
                      }
                      suggestionArray.push(suggestionText);
                    });
                    if (!suggestionArray.includes(address)) {
                      this.handleChangeCity(field, suggestionArray[0]);
                      this.handleSelectCity(field, suggestionArray[0]);
                    }
                  }
                },
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.length > 0 ? (
                <div className="autocomplete-box">
                  {suggestions.map((suggestion) => {
                    const isInernational =
                      suggestion.terms &&
                      suggestion.terms.some(
                        (country) => country.value.toUpperCase() === "USA"
                      );
                    let suggestionText = suggestion.description;
                    if (isInernational) {
                      suggestionText =
                        suggestion.formattedSuggestion.mainText +
                        ", " +
                        suggestion.formattedSuggestion.secondaryText.replace(
                          ", USA",
                          ""
                        );
                    }
                    suggestion["description"] = suggestionText;
                    const className =
                      suggestion.active || suggestion.index === 0
                        ? "suggestion-item--active"
                        : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: "rgba(0, 0, 0, .03)",
                          cursor: "pointer",
                        }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestionText}</span>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  resetAdditionnalValues(accordionName, values) {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      tripLegFields: values,
    });
    this.props.openAccordion({
      accordionName: accordionName + this.props.tripLegName,
      forced: false,
    });
  }

  pushDataToAirport(airportsResult, airportOptions, field) {
    if (airportOptions) {
      airportOptions.forEach((airport, key) => {
        airportOps[field].push({
          key: key + 1,
          value: airport.Name,
          text: airport.Name,
        });
      });
      isLoading[this.props.tripLegName + "_latLngFor_" + field] = "loading";
      isDisable[this.props.tripLegName + "_latLngFor_" + field] = true;
      if (airportsResult && airportsResult.status === "success") {
        isLoading[this.props.tripLegName + "_latLngFor_" + field] =
          airportsResult.status;
        isDisable[this.props.tripLegName + "_latLngFor_" + field] = false;
      }
    }
    return airportOps;
  }

  renderAirportOptions(
    field,
    latLngField,
    airportValue,
    airportOptions,
    cityValue,
    setFocusFields
  ) {
    airportOps = {
      [field]: [{ key: 0, value: "Any Airport", text: "Any Airport" }],
    };
    if (latLngField && cityValue) {
      const airportsResult = this.props.getAiports[latLngField];
      if (!airportOptions) {
        isDisable[this.props.tripLegName + "_latLngFor_" + field] = true;
        if (airportsResult) {
          airportOps = this.pushDataToAirport(
            airportsResult,
            airportsResult.airports,
            field
          );
        }
      } else {
        airportOps = this.pushDataToAirport(
          airportsResult,
          airportOptions,
          field
        );
      }
    }
    isOpenDropdown =
      airportOps[field].length > 1 &&
      Object.keys(setFocusFields).length > 0 &&
      !isDisable[this.props.tripLegName + "_latLngFor_" + field] &&
      setFocusFields[
        "airport_" + this.props.tripLegName + "_latLngFor_" + field
      ];
    return (
      <Dropdown
        open={isOpenDropdown && isOpenDropdown ? true : false}
        id={"airport_" + this.props.tripLegName + field}
        value={airportValue}
        loading={
          isLoading[this.props.tripLegName + "_latLngFor_" + field] ===
          "loading"
            ? true
            : false
        }
        placeholder="Any Airport"
        selection
        options={airportOps[field]}
        onChange={(event, data) => {
          this.setValueForLocation(
            "setFocusFields",
            {
              ["airport_" +
              this.props.tripLegName +
              "_latLngFor_" +
              field]: false,
            },
            this.props.saveToTmp
          );
          this.setValueForLocation(field, data.value, this.props.saveToTmp);
        }}
        onOpen={(event, data) => {
          this.setValueForLocation(
            "setFocusFields",
            {
              ["airport_" +
              this.props.tripLegName +
              "_latLngFor_" +
              field]: true,
            },
            this.props.saveToTmp
          );
        }}
        disabled={isDisable[this.props.tripLegName + "_latLngFor_" + field]}
        style={{ backgroundColor: "#fafafa", cursor: "pointer" }}
      />
    );
  }

  render() {
    let tripLegRow = Object.keys(this.props.rowTripLeg).length;
    let legValues = [],
      tmpValues = [];
    let rowLegFields = this.props.rowLegFields;
    if (rowLegFields[this.props.tripLegName]) {
      legValues = this.props.rowLegFields[this.props.tripLegName].values;
      tmpValues = this.props.rowLegFields[this.props.tripLegName].tmpValues;
    } else {
      legValues = this.props.rowTripLeg[this.props.tripLegName].values;
      tmpValues = this.props.rowTripLeg[this.props.tripLegName].tmpValues;
    }

    let departByOptions = [
      { key: "1", value: "Depart by", text: "Depart by", selected: true },
      { key: "2", value: "Arrive at", text: "Arrive at" },
    ];
    let passengerCounts = [];
    for (var i = 1; i <= 10; i++) {
      passengerCounts.push({ key: i, value: i, text: i === 10 ? "10+" : i });
    }
    let preferredOptions = [
      {
        key: "1",
        value: "Phenom 100",
        text: (
          <span className="spanImage">
            <Image src={iconPhenom100} /> Phenom 100
          </span>
        ),
      },
      {
        key: "4",
        value: "Phenom 300",
        text: (
          <span className="spanImage">
            <Image src={iconPhenom300} /> Phenom 300
          </span>
        ),
      },
      {
        key: "6",
        value: "Other (Managed)",
        text: <span className="spanOther"> Other (Managed)</span>,
      },
    ];
    const isChangedValue = tmpValues.isChangedValue;
    const legOrder = ["1st", "2nd", "3rd"];
    const legNumber = legOrder[this.props.legNumber - 1];
    const passengerCount =
      legValues.passengerCount === "" ? 1 : legValues.passengerCount;
    const departOrArrive = tmpValues.departOrArrive
      ? tmpValues.departOrArrive
      : legValues.departOrArrive;
    const departTime = tmpValues.departTime
      ? tmpValues.departTime
      : legValues.departTime;
    const arriveTime = tmpValues.arriveTime
      ? tmpValues.arriveTime
      : legValues.arriveTime;
    const latLngForFromCity = tmpValues.latLngForfromCity
      ? tmpValues.latLngForfromCity
      : legValues.latLngForfromCity;
    const latLngForToCity = tmpValues.latLngFortoCity
      ? tmpValues.latLngForfromCity
      : legValues.latLngFortoCity;
    const fromCity =
      isChangedValue && isChangedValue["fromCity"]
        ? tmpValues.fromCity
        : legValues.fromCity;
    const toCity =
      isChangedValue && isChangedValue["toCity"]
        ? tmpValues.toCity
        : legValues.toCity;
    const fromAirport = tmpValues.fromAirport
      ? tmpValues.fromAirport
      : legValues.fromAirport;
    const toAirport = tmpValues.toAirport
      ? tmpValues.toAirport
      : legValues.toAirport;
    const fromAirportOptions = legValues.airportOptionForfromCity;
    const toAirportOptions = legValues.airportOptionFortoCity;
    const setFocusFields = legValues.setFocusFields;
    return (
      <div className="chooseSection">
        <Grid>
          <Grid.Row className="clsBottom">
            <Grid.Column>
              {tripLegRow >= 2 ? (
                <button
                  onClick={() => {
                    this.props.rowLegData.addOrRemoveTripLeg({
                      actionType: "REMOVE",
                      tripLegName: this.props.tripLegName,
                    });
                    this.props.openAccordion({
                      accordionName: this.props.tripLegName,
                      actionType: "REMOVE",
                    });
                    analyticsEvent("Remove_Trip_Leg", {
                      event_category: "book_trip",
                      event_label:
                        "User removes a trip leg from a trip request.",
                    });
                  }}
                  className="button-wrapper removeLeg"
                ></button>
              ) : null}
              <div className="tripAccount">
                {" "}
                {legNumber ? legNumber : this.props.legNumber + "th"} Leg
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="chooseAirPort" columns={2}>
            <Grid.Column className="col tenCol">
              <Grid.Row columns={2}>
                <span className="clsTitleL">From</span>
                <Grid>
                  <Grid.Row columns={2} className="noPaddingLR">
                    <Grid.Column className="col">
                      {this.renderPlaceSearching(
                        "fromCity",
                        isChangedValue,
                        tmpValues,
                        legValues,
                        fromCity
                      )}
                    </Grid.Column>
                    <Grid.Column className="col fromAirport">
                      {this.renderAirportOptions(
                        "fromAirport",
                        latLngForFromCity,
                        fromAirport,
                        fromAirportOptions,
                        fromCity,
                        setFocusFields
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column className="noPaddingR col tenCol">
              <Grid.Row columns={2}>
                <span className="clsTitleL">To</span>
                <Grid>
                  <Grid.Row columns={2} className="noPaddingLR padding">
                    <Grid.Column className="col">
                      {this.renderPlaceSearching(
                        "toCity",
                        isChangedValue,
                        tmpValues,
                        legValues,
                        toCity
                      )}
                    </Grid.Column>
                    <Grid.Column className="col">
                      {this.renderAirportOptions(
                        "toAirport",
                        latLngForToCity,
                        toAirport,
                        toAirportOptions,
                        toCity,
                        setFocusFields
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="chooseAirPort" columns={2}>
            <Grid.Column className="col tenCol">
              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Row columns={2} className="noPaddingLR paddingMobile">
                    <Grid.Column className="col date">
                      <span>Departure Date</span>
                      <Input
                        icon="calendar"
                        iconPosition="left"
                        type="date"
                        defaultValue={
                          tmpValues.departureDate
                            ? tmpValues.departureDate
                            : legValues.departureDate
                        }
                        onChange={(event) => {
                          this.props.newBookTripFieldValues({
                            tripLegName: this.props.tripLegName,
                            field: "departureDate",
                            value: event.target.value,
                            saveToTmp: this.props.saveToTmp,
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column className="col time">
                      <span>Departure/Arrival Time</span>
                      <Grid>
                        <Grid.Row
                          columns={2}
                          className="noPaddingLR departureDate"
                        >
                          <Grid.Column className="col" width={8}>
                            <Dropdown
                              defaultValue={departOrArrive}
                              selection
                              options={departByOptions}
                              onChange={(event, data) => {
                                this.props.newBookTripFieldValues({
                                  tripLegName: this.props.tripLegName,
                                  field: "departOrArrive",
                                  value: data.value,
                                  saveToTmp: this.props.saveToTmp,
                                });
                                data.value === "Depart by"
                                  ? this.props.newBookTripFieldValues({
                                      tripLegName: this.props.tripLegName,
                                      field: "departTime",
                                      value: tmpValues.departTime
                                        ? tmpValues.departTime
                                        : legValues.departTime,
                                      saveToTmp: this.props.saveToTmp,
                                    })
                                  : this.props.newBookTripFieldValues({
                                      tripLegName: this.props.tripLegName,
                                      field: "arriveTime",
                                      value: tmpValues.arriveTime
                                        ? tmpValues.arriveTime
                                        : legValues.arriveTime,
                                      saveToTmp: this.props.saveToTmp,
                                    });
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column className="col" width={8}>
                            <Input
                              type="time"
                              placeholder="Time"
                              value={
                                departOrArrive === "Depart by"
                                  ? departTime
                                  : arriveTime
                              }
                              onChange={(event) => {
                                this.props.newBookTripFieldValues({
                                  tripLegName: this.props.tripLegName,
                                  field: "departOrArriveTime",
                                  value: event.target.value,
                                  saveToTmp: this.props.saveToTmp,
                                });
                                departOrArrive === "Depart by"
                                  ? this.props.newBookTripFieldValues({
                                      tripLegName: this.props.tripLegName,
                                      field: "departTime",
                                      value: event.target.value,
                                      saveToTmp: this.props.saveToTmp,
                                    })
                                  : this.props.newBookTripFieldValues({
                                      tripLegName: this.props.tripLegName,
                                      field: "arriveTime",
                                      value: event.target.value,
                                      saveToTmp: this.props.saveToTmp,
                                    });
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column className="noPaddingR col tenCol">
              <Grid.Row columns={2}>
                <Grid>
                  <Grid.Row columns={2} className="noPaddingLR last">
                    <Grid.Column className="col">
                      <span>Passenger Count</span>
                      <Dropdown
                        value={
                          tmpValues.passengerCount && this.props.saveToTmp
                            ? tmpValues.passengerCount
                            : passengerCount
                        }
                        selection
                        options={passengerCounts}
                        onChange={(event, data) => {
                          this.props.newBookTripFieldValues({
                            tripLegName: this.props.tripLegName,
                            field: "passengerCount",
                            value: data.value,
                            saveToTmp: this.props.saveToTmp,
                          });
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column className="col">
                      <span>Preferred Aircraft</span>
                      <Dropdown
                        value={
                          tmpValues.preferredAircraft
                            ? tmpValues.preferredAircraft
                            : legValues.preferredAircraft
                        }
                        placeholder="Aircraft"
                        selection
                        options={preferredOptions}
                        onChange={(event, data) => {
                          this.props.newBookTripFieldValues({
                            tripLegName: this.props.tripLegName,
                            field: "preferredAircraft",
                            value: data.value,
                            saveToTmp: this.props.saveToTmp,
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {this.props.steps[0] === "legs" ? (
          <GroundTransportAndCatering
            step={this.props.steps[0]}
            tripLegName={this.props.tripLegName}
            isChangedValue={isChangedValue}
            tmpValues={tmpValues}
            legValues={legValues}
            saveToTmp={this.props.saveToTmp}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rowTripLeg: state.ui.bookTrip.newTripLeg,
    rowLegFields: state.ui.bookTrip.fields,
    accordionActive:
      Object.keys(state.ui.bookTrip.accordion).length > 0
        ? state.ui.bookTrip.accordion
        : null,
    steps: state.ui.steps,
    getAiports: state.services.airports.searchAiports,
    currentSearch: state.services.airports.searchAiports.currentSearch,
  };
};
const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  searchAiports: (
    tripLegName,
    field,
    saveToTmp,
    latitude,
    longitude,
    radiusNm
  ) => {
    dispatch(
      execNotShadedSearchAirports(
        tripLegName,
        field,
        saveToTmp,
        latitude,
        longitude,
        radiusNm
      )
    );
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(
      newBookTripFieldValues(
        Object.assign(fieldAndValue, { isChangedValues: true })
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TripLegRow);
