import clone from "clone";
import { handleActions } from "redux-actions";
import {
  NEXT_STEP_TRIP_REQUEST,
  PREVIOUS_STEP_TRIP_REQUEST,
  RESET_STEP_TRIP_REQUEST,
} from "../../actions/types";

const defaultSteps = ["legs"];
const steps = handleActions(
  {
    [NEXT_STEP_TRIP_REQUEST]: (state, action) => {
      const previousState = clone(state);
      const steps = action.payload.step
        ? [action.payload.step, ...previousState]
        : previousState;
      Object.assign(previousState, steps, { from: action.payload.from });
      return previousState;
    },
    [PREVIOUS_STEP_TRIP_REQUEST]: (state, action) => {
      let previousState = clone(state);
      const steps = previousState.slice(1);
      return steps;
    },
    [RESET_STEP_TRIP_REQUEST]: (state, action) => {
      let newStep = clone(state);
      newStep = defaultSteps;
      return newStep;
    },
  },
  defaultSteps
);

export default steps;
