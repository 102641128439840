export const FIREBASE_USER_CHECK = "FIREBASE_USER_CHECK";
export const NETWORK_FAILURE = "NETWORK_FAILURE";
export const NETWORK_SUCCESS = "NETWORK_SUCCESS";
export const USER_DROPDOWN = "USER_DROPDOWN";
export const MENU_DROPDOWN = "MENU_DROPDOWN";
export const CLOSE_DROPDOWN = "CLOSE_DROPDOWN";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const START_ASYNC_OP = "START_ASYNC_OP";
export const END_ASYNC_OP = "END_ASYNC_OP";
export const POP_USER_AUTHORIZATION = "POP_USER_AUTHORIZATION";
export const CLOSE_USER_AUTHORIZATION = "CLOSE_USER_AUTHORIZATION";
export const PREP_USER_FOR_DETAILS_EDIT = "PREP_USER_FOR_DETAILS_EDIT";
export const SWITCH_USER_EDIT_FIELD = "SWITCH_USER_EDIT_FIELD";
export const NEW_USER_FIELD_VALUE = "NEW_USER_FIELD_VALUE";
export const EDITED_ASTRO_IDS_TO_ADD = "EDITED_ASTRO_IDS_TO_ADD";
export const ASTRO_USER_ACCOUNTS = "ASTRO_USER_ACCOUNTS";
export const GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER";
export const NEW_SIGN_UP_FIELD_VALUES = "NEW_SIGN_UP_FIELD_VALUES";
export const POP_USER_STATUS_CHANGE = "POP_USER_STATUS_CHANGE";
export const CLOSE_USER_STATUS_CHANGE = "CLOSE_USER_STATUS_CHANGE";
export const POP_USER_RESET_PASSWORD = "POP_USER_RESET_PASSWORD";
export const CLOSE_USER_RESET_PASSWORD = "CLOSE_USER_RESET_PASSWORD";
export const SEARCH_TRIPS = "SEARCH_TRIPS";
export const GET_FLIGHT = "GET_FLIGHT";
export const GET_MORE_FLIGHTS = "GET_MORE_FLIGHTS";
export const GET_ACCOUNTS_FOR_LOGGED_IN_USER =
  "GET_ACCOUNTS_FOR_LOGGED_IN_USER";
export const POP_CALL_AIRCARE = "POP_CALL_AIRCARE";
export const CLOSE_CALL_AIRCARE = "CLOSE_CALL_AIRCARE";
export const GET_USER_FLIGHT_SURVEYS = "GET_USER_FLIGHT_SURVEYS";
export const POP_GIVE_FEEDBACK = "POP_GIVE_FEEDBACK";
export const CLOSE_GIVE_FEEDBACK = "CLOSE_GIVE_FEEDBACK";
export const NEW_SURVEY_FIELD_VALUES = "NEW_SURVEY_FIELD_VALUES";
export const PUT_FLIGHT_SURVEY = "PUT_FLIGHT_SURVEY";
export const CLOSE_CONFIRMED_MODAL = "CLOSE_CONFIRMED_MODAL";
export const RESET_SURVEY_FIELD_VALUES = "RESET_SURVEY_FIELD_VALUES";
export const SELECT_FLIGHT_DETAIL = "SELECT_FLIGHT_DETAIL";
export const ACCORDION_FLIGHT_DETAIL = "ACCORDION_FLIGHT_DETAIL";
export const FIREBASE_IMAGE_STORAGE = "FIREBASE_IMAGE_STORAGE";
export const LIGHT_BOX_IS_OPEN = "LIGHT_BOX_IS_OPEN";
export const SEARCH_AIRPORTS = "SEARCH_AIRPORTS";
export const NEW_BOOK_TRIP_FIELD_VALUES = "NEW_BOOK_TRIP_FIELD_VALUES";
export const PUT_TRIP_REQUEST = "PUT_TRIP_REQUEST";
export const ADD_OR_REMOVE_TRIP_LEG = "ADD_OR_REMOVE_TRIP_LEG";
export const ADD_OR_REMOVE_PASSENGER = "ADD_OR_REMOVE_PASSENGER";
export const NEW_PASSENGER_FIELD_VALUES = "NEW_PASSENGER_FIELD_VALUES";
export const NEXT_STEP_TRIP_REQUEST = "NEXT_STEP_TRIP_REQUEST";
export const PREVIOUS_STEP_TRIP_REQUEST = "PREVIOUS_STEP_TRIP_REQUEST";
export const RESET_STEP_TRIP_REQUEST = "RESET_STEP_TRIP_REQUEST";
export const ACCORDION_TRIP_REQUEST = "ACCORDION_TRIP_REQUEST";
export const GET_ITINERARY_FOR_TRIP = "GET_ITINERARY_FOR_TRIP";
export const FIREBASE_SIGNIN_EMAIL_PASSWORD = "FIREBASE_SIGNIN_EMAIL_PASSWORD";
export const FIREBASE_SIGNIN_AUTHENCATION = "FIREBASE_SIGNIN_AUTHENCATION";
export const FIREBASE_SIGNIN_GOOGLE_SIGN = "FIREBASE_SIGNIN_GOOGLE_SIGN";
export const SEND_EMAIL_RESET_PASSWORD = "SEND_EMAIL_RESET_PASSWORD";
export const FIREBASE_PASSWORD_RESET = "FIREBASE_PASSWORD_RESET";
export const PASSWORD_RESET_EMAIL_MODAL = "PASSWORD_RESET_EMAIL_MODAL";
export const AIRCARE_DROPDOWN = "AIRCARE_DROPDOWN";
export const FIREBASE_CREATE_ACCOUNT = "FIREBASE_CREATE_ACCOUNT";
export const FIREBASE_SIGNUP_EMAIL_PASSWORD = "FIREBASE_SIGNUP_EMAIL_PASSWORD";
export const FIREBASE_SIGNUP_RESULT = "FIREBASE_SIGNUP_RESULT";
export const LOGOUT = "LOGOUT";
