import clone from "clone";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import { NEW_SIGN_UP_FIELD_VALUES } from "../../actions/types";

const defaultSingUpState = {
  values: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    userSpecifiedAccount: "",
  },
  valid: {
    firstName: false,
    middleName: true,
    lastName: false,
    userSpecifiedAccount: true,
  },
};

const fields = handleAction(
  NEW_SIGN_UP_FIELD_VALUES,
  {
    next(state, action) {
      const newValues = clone(state.values);

      Object.assign(newValues, action.payload);
      const valid = clone(state.valid);

      Object.assign(valid, {
        firstName: Boolean(newValues.firstName).valueOf(),
        lastName: Boolean(newValues.lastName).valueOf(),
      });
      return { values: newValues, valid };
    },
  },
  defaultSingUpState
);

const valid = handleAction(
  NEW_SIGN_UP_FIELD_VALUES,
  {
    next(state, action) {
      const newState = clone(state);

      Object.assign(newState, action.payload);
      return newState;
    },
  },
  defaultSingUpState
);

export default combineReducers({ fields, valid });
