import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route,  RouteProps } from "react-router";
import { paths } from "routes";

type AppState = any;

function AuthorizedRoute({ children }: { children: JSX.Element }) {
    const isLoggedIn = useSelector(
      (state: AppState) => state.services.firebase.firebaseUser.loggedIn
    );


  return isLoggedIn === "yes" ? (children) : ( <Navigate to={paths.build.root()} />);
}

export default AuthorizedRoute;
