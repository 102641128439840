import { createAction } from "redux-actions";
import {
  execCreateUser,
  execGetAstroAccountsForLoggedInUser,
  execGetLoggedInUser,
  execGetUser,
  execGetUsers,
  execSearchUsers,
  execUpdateUser,
} from "../services/eas-users";
import {
  excecCreateUserWithEmailAndPassword,
  excecPasswordResetEmail,
  excecSignInWithEmailAndPassword,
  execSendPasswordResetEmail,
  watchFirebaseUser,
} from "../services/firebase-user";
import {
  FIREBASE_SIGNIN_EMAIL_PASSWORD,
  FIREBASE_SIGNUP_EMAIL_PASSWORD,
  SEND_EMAIL_RESET_PASSWORD,
} from "../types";
import { wrapAsyncThunk } from "./utilities";

export const newFirebaseSignInFieldValue = createAction(
  FIREBASE_SIGNIN_EMAIL_PASSWORD
);

export const newFirebaseSignUpFieldValue = createAction(
  FIREBASE_SIGNUP_EMAIL_PASSWORD
);

export const newSendEmailResetPassword = createAction(
  SEND_EMAIL_RESET_PASSWORD
);

export const execShadedGetUsers = () =>
  wrapAsyncThunk(execGetUsers(), { name: "shaded-get-users" });

export const execShadedGetUser = (userId) =>
  wrapAsyncThunk(execGetUser(userId), { name: "shaded-get-user" });

export const execNoShadeGetUser = (userId) =>
  wrapAsyncThunk(execGetUser(userId), {
    needsShade: false,
    name: "no-shade-get-user",
  });

export const execShadedCreateUser = (initialUserData) =>
  wrapAsyncThunk(execCreateUser(initialUserData), {
    name: "shaded-create-user",
  });

export const execShadedGetLoggedInUser = () =>
  wrapAsyncThunk(execGetLoggedInUser(), { name: "shaded-get-logged-in-user" });

export const execNotShadedGetLoggedInUser = () =>
  wrapAsyncThunk(execGetLoggedInUser(), {
    name: "no-shade-get-logged-in-user",
    shaded: false,
  });

export const execShadedUpdateUserStatus = (userId, userStatus) =>
  wrapAsyncThunk(execUpdateUser(userId, { status: userStatus }), {
    name: "shaded-update-user",
  });

export const execNotShadedUpdateUserStatus = (userId, userStatus) =>
  wrapAsyncThunk(execUpdateUser(userId, { status: userStatus }), {
    needsShade: false,
    name: "not-shaded-update-user",
  });

export const execShadedSearchUsers = (searchTerm) =>
  wrapAsyncThunk(execSearchUsers(searchTerm), { name: "shaded-search-users" });

export const execShadedRefreshSearchUsers = () =>
  wrapAsyncThunk(execSearchUsers(), { name: "shaded-refresh-search-users" });

export const execShadedSendPasswordResetEmail = (emailAddress) =>
  wrapAsyncThunk(execSendPasswordResetEmail(emailAddress), {
    name: "shaded-send-password",
  });

export const execShadedFireBaseSignInEmailPassword = (emailAddress, password) =>
  wrapAsyncThunk(excecSignInWithEmailAndPassword(emailAddress, password), {
    name: "shaded-sign-in-with-email-and-password",
  });

export const execShadedFireBaseSendPasswordResetEmail = (emailAddress) =>
  wrapAsyncThunk(excecPasswordResetEmail(emailAddress), {
    name: "shaded-send-password-Reset-Email",
  });

export const execShadedFireBaseCreateAccount = (emailAddress, password) =>
  wrapAsyncThunk(excecCreateUserWithEmailAndPassword(emailAddress, password), {
    name: "shaded-create-account",
  });

export const execBootstrapLoggedInUser = () =>
  wrapAsyncThunk(
    (dispatch) => {
    console.log(`DISPATCH:`)
      return dispatch(watchFirebaseUser()).then(({ loggedIn }) => {
        if (loggedIn === "yes") {
          return Promise.all([
            dispatch(execGetLoggedInUser()),
            dispatch(execGetAstroAccountsForLoggedInUser()),
          ]);
        }
      });
    },
    { needsShade: false, name: "no-shade-bootstrap-logged-in-user" }
  );
