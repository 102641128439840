import { createAction } from "redux-actions";
import {
  FIREBASE_CREATE_ACCOUNT,
  FIREBASE_IMAGE_STORAGE,
  FIREBASE_PASSWORD_RESET,
  FIREBASE_SIGNIN_AUTHENCATION,
  FIREBASE_USER_CHECK,
  PASSWORD_RESET_EMAIL_MODAL,
} from "../types";
import { execBootstrapLoggedInUser } from "../ui/eas-users";
import app, { firebaseAuth, fireBaseStorage } from "./eas-firebase-app";
import { logoutUser } from "./eas-users";
import {getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword} from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const firebaseUserCheck = createAction(FIREBASE_USER_CHECK);
export const firebaseImageUrl = createAction(FIREBASE_IMAGE_STORAGE);
export const firebaseAuthentication = createAction(
  FIREBASE_SIGNIN_AUTHENCATION
);
export const firebasePasswordResetEmail = createAction(FIREBASE_PASSWORD_RESET);
export const passwordResetEmailModal = createAction(PASSWORD_RESET_EMAIL_MODAL);
export const firebaseCreateAccount = createAction(FIREBASE_CREATE_ACCOUNT);

function getTokenAndUpdate(user, dispatch) {
  return user
    .getIdToken()
    .then((accessToken) => {
      const firebaseResult = {
        loggedIn: "yes",
        name: user.displayName ? user.displayName : user.email,
        response: { ...user, accessToken },
      };

      dispatch(firebaseUserCheck(firebaseResult));
      return Promise.resolve(firebaseResult);
    })
    .catch((error) => Promise.reject(error));
}

export const watchFirebaseUser = () => (dispatch) =>
  new Promise((resolve, reject) => {
    firebaseAuth.onAuthStateChanged(
      (user) => {
        if (user) {
          // There is a user logged in using firebase
          //resolve(getTokenAndUpdate(user, dispatch));
          getTokenAndUpdate(user, dispatch)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          // There is no logged in user
          const firebaseResult = { loggedIn: "no", name: "--" };

          dispatch(firebaseUserCheck(firebaseResult));
          resolve(firebaseResult);
        }
      },
      (error) => {
        dispatch(firebaseUserCheck(error));
        reject(error);
      }
    );
  });

export const execSendPasswordResetEmail = (emailAddress) => (dispatch) =>
  new Promise((resolve, reject) => {
    
    sendPasswordResetEmail(firebaseAuth, emailAddress)
      .then(
        () => {
          // Email sent.
          resolve();
        },
        (error) => {
          // An error happened.
          reject(error);
        }
      );
  });


export const getImageFromFireBase = (imageUrl) =>
  new Promise((resolve, reject) => {
    const imageStorage = [];

    if (imageUrl.length > 0) {
      imageUrl.map((imgUrl) => {
        const storage = getStorage();
        const starsRef = ref(storage, imgUrl);

        imageStorage.push(getDownloadURL(starsRef).then((url) => url));
        return null;
      });
    }
    return Promise.all(imageStorage).then((imgUrls) => {
      resolve(imgUrls);
    });
  });

export const firebaseLogout = (dispatch) => {
  firebaseAuth
    .signOut()
    .then(() => {
      dispatch(logoutUser());
      // FIX: this is just to prevent loading screen after logout
      dispatch(execBootstrapLoggedInUser());
    });
};

export const excecSignInWithEmailAndPassword = (emailAddress, password) => (
  dispatch
) =>
    signInWithEmailAndPassword(firebaseAuth, emailAddress, password)
    .then((user) => Promise.resolve(user))
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      dispatch(firebaseAuthentication({ errorCode, errorMessage }));
      return Promise.reject(error);
    });

export const excecCreateUserWithEmailAndPassword = (email, password) => (
  dispatch
) => {
  dispatch(firebaseCreateAccount(false));

  
  return createUserWithEmailAndPassword(firebaseAuth, email, password)
    .then((result) => {
      if (result && result.emailVerified === false) {
        result.sendEmailVerification().then(() => {
          console.log("email verification sent to user");
        });
      }
      result
        ? dispatch(firebaseCreateAccount(true))
        : dispatch(firebaseCreateAccount(false));
      return Promise.resolve(result);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      dispatch(firebaseCreateAccount({ errorCode, errorMessage }));
      return Promise.reject(error);
    });
};

export const excecSignInWithGoogleSignIn = (provider) => (dispatch) => {
  firebaseAuth
    .signInWithRedirect(provider)
    .then((result) => {
      const token = result.credential ? result.credential.accessToken : null;
      const user = result.user;
      const firebaseResult = {
        loggedIn: "yes",
        name: user.displayName,
        response: { ...user, token },
      };

      dispatch(firebaseUserCheck(firebaseResult));
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      dispatch(firebaseAuthentication({ errorCode, errorMessage }));
    });
};

export const excecPasswordResetEmail = (emailAddress) => (dispatch) =>
  
    sendPasswordResetEmail(firebaseAuth, emailAddress)
    .then(() => {
      dispatch(firebasePasswordResetEmail({ errorCode: "success" }));
      return Promise.resolve("success");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      dispatch(firebasePasswordResetEmail({ errorCode, errorMessage }));
      return Promise.reject(error);
    });