import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { paths } from "routes";
import {
  execShadedGetUser,
  execShadedUpdateUserStatus,
} from "store/actions/ui/eas-users";
import {
  popUserAuthorization,
  popUserStatusChange,
} from "store/actions/ui/popups";

const toSafeString = (input) => (input ? input : "");

const AUTHORIZED = "Authorized";
const DEACTIVATED = "Deactivated";
const PARTIAL = "Partial";
const TEXT_STATUS_BUTTON = {
  Partial: "Authorize User",
  Pending: "Authorize User",
  Authorized: "Deactivate User",
  Rejected: "Authorize User",
  Deactivated: "Activate User",
};

const USER_STATUS = {
  Partial: "Authorized",
  Pending: "Reject",
  Authorized: "Deactivate",
  Rejected: "Authorize",
  Deactivated: "Authorized",
};

class HeaderDetail extends Component {
  statusButton(status, hasAstroUserIds) {
    if (status !== AUTHORIZED) {
      if ((hasAstroUserIds && status === DEACTIVATED) || status === PARTIAL) {
        return (
          <button
            onClick={this.props.authorizeUserStatus(
              this.props.userId,
              USER_STATUS[status]
            )}
            className="buttonStyle deactivate red"
          >
            {TEXT_STATUS_BUTTON[status]}
          </button>
        );
      }
      return (
        <button
          onClick={this.props.popAuthorize(this.props.userId)}
          className="buttonStyle deactivate red"
        >
          {TEXT_STATUS_BUTTON[status]}
        </button>
      );
    }
    return (
      <button
        onClick={this.props.popUserStatus({
          userId: this.props.userId,
          existingStatus: this.props.user.status,
          newStatus: USER_STATUS[status],
        })}
        className="buttonStyle deactivate red"
      >
        {TEXT_STATUS_BUTTON[status]}
      </button>
    );
  }

  render() {
    const user = this.props.user;

    if (user) {
      const firstInitial = user.firstName.substring(0, 1),
        hasAstroUserIds = user.astroUserIds.length > 0,
        lastInitial = user.lastName.substring(0, 1);

      return (
        <div>
          <div className="breadcrumb">
            <a href="/admin">User</a> /{" "}
            <span>
              {user.firstName} {user.lastName}
            </span>
          </div>
          <div className="head ">
            <div className="searchItem">
              <div className="iconJS">
                {firstInitial} {lastInitial}
              </div>
              <button className="buttonStyle upload" value="">
                UPLOAD PICTURE
              </button>
              {this.statusButton(user.status, hasAstroUserIds)}
            </div>
          </div>
          <div className="tabItem">
            <NavLink
              activeclassname="active"
              to={paths.build.userDetails(this.props.userId, "details")}
            >
              User Details
            </NavLink>
            <NavLink
              activeclassname="active"
              to={paths.build.userDetails(this.props.userId, "transactions")}
            >
              Activity
            </NavLink>
          </div>
        </div>
      );
    }
    return null;
  }
}

HeaderDetail.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string.isRequired,
  authorizeUserStatus: PropTypes.func.isRequired,
  popAuthorize: PropTypes.func.isRequired,
  popUserStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const easeUser = state.services.easUsers.current.user;
  const astroUserIds = easeUser ? easeUser.astroUserIds : [];

  return {
    user: easeUser
      ? {
          firstName: toSafeString(easeUser.firstName),
          lastName: toSafeString(easeUser.lastName),
          astroUserIds: astroUserIds ? astroUserIds : [],
          status: easeUser.status,
        }
      : null,
  };
};
const mapDispatchToProps = (dispatch) => ({
  popAuthorize: (userId) => () => {
    dispatch(popUserAuthorization(userId));
  },
  popUserStatus: (userStatus) => () => {
    dispatch(popUserStatusChange(userStatus));
  },
  authorizeUserStatus: (userId, userStatus) => () => {
    dispatch(execShadedUpdateUserStatus(userId, userStatus)).then(() => {
      dispatch(execShadedGetUser(userId));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDetail);
