import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router";
import { paths } from "routes";
import { firebaseLogout } from "store/actions/services/firebase-user";

type AppState = any;

export const Logout: FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(
    (state: AppState) => state.services.firebase.firebaseUser.loggedIn
  );
  const loggedInUser = useSelector(
    (state: AppState) => state.services.easUsers.loggedIn
  );
  const isLoggedOut = isLoggedIn !== "yes" && loggedInUser.exists !== "yes";

  useEffect(() => {
    // @ts-ignore
    dispatch(firebaseLogout);
  }, [dispatch]);

  if (isLoggedOut) {
    return <Navigate to={paths.build.root()} />;
  }

  return <p>You are being logged out...</p>;
};
