import clone from "clone";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  PASSWORD_RESET_EMAIL_MODAL,
  SEND_EMAIL_RESET_PASSWORD,
} from "../../actions/types";

const defaultCheckEmailState = {
  values: {
    emailAddress: "",
  },
  valid: {
    emailAddress: false,
  },
};

const defaultModal = { isOpen: false };

const checkEmail = handleAction(
  SEND_EMAIL_RESET_PASSWORD,
  {
    next(state, action) {
      const newValues = clone(state.values);
      Object.assign(newValues, action.payload);
      const valid = clone(state.valid);
      Object.assign(valid, {
        emailAddress: Boolean(newValues.emailAddress).valueOf(),
      });
      return { values: newValues, valid };
    },
  },
  defaultCheckEmailState
);

const resetPasswordModal = handleAction(
  PASSWORD_RESET_EMAIL_MODAL,
  {
    next(state, action) {
      const newState = clone(state);
      newState.isOpen = action.payload;
      return newState;
    },
  },
  defaultModal
);

export default combineReducers({ checkEmail, resetPasswordModal });
