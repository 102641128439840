import { createAction } from "redux-actions";
import {
  execGetUserFlightSurveys,
  execPutFlightSurvey,
} from "../services/eas-flight-surveys";
import {
  CLOSE_CONFIRMED_MODAL,
  NEW_SURVEY_FIELD_VALUES,
  RESET_SURVEY_FIELD_VALUES,
} from "../types";
import { wrapAsyncThunk } from "./utilities";

export const newSurveyFieldValues = createAction(NEW_SURVEY_FIELD_VALUES);
export const resetSurveyFieldValues = createAction(RESET_SURVEY_FIELD_VALUES);
export const closeConfirmedModal = createAction(CLOSE_CONFIRMED_MODAL);

export const execShadedGetUserFlightSurveys = (userId) => {
  return wrapAsyncThunk(execGetUserFlightSurveys(userId), {
    needsShade: true,
    name: "shaded-get-user-flight-surveys",
  });
};

export const execNotShadedGetUserFlightSurveys = (userId) => {
  return wrapAsyncThunk(execGetUserFlightSurveys(userId), {
    needsShade: false,
    name: "no-shade-get-user-flight-surveys",
  });
};

export const execShadedPutFlightSurvey = (initialSurveyData) => {
  return wrapAsyncThunk(execPutFlightSurvey(initialSurveyData), {
    name: "exec-shade-put-flight-survey",
  });
};
