import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useMatch } from "react-router";
import { paths } from "routes";
import {
  execShadedGetUser,
  execShadedSearchUsers,
  execShadedUpdateUserStatus,
} from "store/actions/ui/eas-users";
import { closeUserStatusChange } from "store/actions/ui/popups";

const DEACTIVATE = "Deactivate",
  REJECT = "Reject",
  USER_STATUS = {
    Authorized: DEACTIVATE,
    Pending: REJECT,
    Partial: REJECT,
  };

const USER_AFTER_UPDATED = {
  Deactivate: "Deactivated",
  Reject: "Rejected",
};

const UserStatusPopup = ({
  fetchUser,
  userId,
  updateUserStatus,
  close,
  user,
}) => {
  const userSearchMatch = useMatch({
    path: paths.definition.userSearch,
    exact: true,
  });
  const userDetailsMatch = useMatch({
    path: paths.definition.userDetails,
    exact: true,
  });

  useEffect(() => {
    fetchUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const statusButton = (status) => {
    return (
      <div className="footer">
        <button
          disabled={false}
          className="buttonStyle Authorize"
          onClick={updateUserStatus({
            currentUserId: userId,
            userStatus: USER_AFTER_UPDATED[status],
            userSearchMatch,
            userDetailsMatch,
          })}
        >
          {status}
        </button>
        <button className="buttonStyle Cancel" onClick={close}>
          Cancel
        </button>
      </div>
    );
  };

  const statusContent = (status, currentUser) => {
    let statusContent = "",
      statusText = "";

    if (status === REJECT) {
      statusText = "reject";
      statusContent =
        "they will not be able to gain access to the system. This action can be undone by searching for the user and then authorizing them.";
    } else if (status === DEACTIVATE) {
      statusText = "deactivate";
      statusContent =
        "they will no longer be able to access their trips and flights. This action can be undone by searching for the user and then authorizing them.";
    }
    return (
      <p className="text">
        If you {statusText}{" "}
        <b className="NamePop">{`${currentUser.firstName} ${currentUser.lastName} (${currentUser.emailAddress}) `}</b>
        {statusContent}
      </p>
    );
  };

  return (
    <section className="popup">
      <div className="bg_overlay"></div>
      {user ? (
        <div className="inner">
          <div className="content">
            <h2>Are you sure?</h2>
            {statusContent(USER_STATUS[user.status], user)}
            {statusButton(USER_STATUS[user.status])}
          </div>
        </div>
      ) : null}
    </section>
  );
};

UserStatusPopup.contextTypes = { router: PropTypes.object };

UserStatusPopup.propTypes = {
  user: PropTypes.object,
  fetchUser: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.ui.popups.userStatus.userId,
  user: state.services.easUsers.current.user,
  userStatusChange: state.ui.popups.userStatus.status,
  userSearch: state.services.easUsers.all.searchTerm,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(execShadedGetUser(userId)),
  close: () => dispatch(closeUserStatusChange()),
  updateUserStatus: ({
    currentUserId,
    userStatus,
    userSearchMatch,
    userDetailsMatch,
  }) => () =>
    dispatch(execShadedUpdateUserStatus(currentUserId, userStatus)).then(() => {
      if (userSearchMatch) {
        dispatch(
          execShadedSearchUsers(
            `name=${userSearchMatch.match.params.encodedSearchString}`
          )
        );
      } else if (userDetailsMatch) {
        dispatch(execShadedGetUser(userDetailsMatch.match.params.userId));
      } else {
        dispatch(execShadedSearchUsers("status=Pending,Partial"));
      }
      dispatch(closeUserStatusChange());
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusPopup);
