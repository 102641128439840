import clone from "clone";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  FIREBASE_SIGNIN_EMAIL_PASSWORD,
  FIREBASE_SIGNUP_EMAIL_PASSWORD,
} from "../../actions/types";

const defaultFirebaseSignState = {
  values: {
    emailAddress: "",
    password: "",
  },
  valid: {
    emailAddress: false,
    password: false,
    disabledForPassword: true,
    messageForPassword: "",
    messageForEmail: "",
  },
};

const fields = handleAction(
  FIREBASE_SIGNIN_EMAIL_PASSWORD,
  {
    next(state, action) {
      const newValues = clone(state.values);
      Object.assign(newValues, action.payload);

      const valid = clone(state.valid);
      Object.assign(valid, {
        emailAddress: Boolean(newValues.emailAddress).valueOf(),
        password: Boolean(newValues.password).valueOf(),
      });
      return { values: newValues, valid };
    },
  },
  defaultFirebaseSignState
);

const createAction = handleAction(
  FIREBASE_SIGNUP_EMAIL_PASSWORD,
  {
    next(state, action) {
      const newValues = clone(state.values);
      Object.assign(newValues, action.payload);

      const valid = clone(state.valid);
      Object.assign(valid, {
        emailAddress: Boolean(newValues.emailAddress).valueOf(),
        password: Boolean(newValues.password).valueOf(),
      });

      if (
        /^[\w+\-.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(newValues.emailAddress)
      ) {
        Object.assign(valid, {
          messageForEmail: "",
          disabledForPassword: false,
        });
        if (newValues.password.length < 8) {
          Object.assign(valid, {
            messageForPassword: "Password must be a minimum of 8 characters",
          });
        } else if (newValues.password.length > 64) {
          Object.assign(valid, {
            messageForPassword:
              "Password must not exceed the maximum of 64 characters.",
          });
        } else {
          Object.assign(valid, { messageForPassword: "" });
        }
      } else {
        Object.assign(valid, {
          messageForEmail: "Invalid email address.",
          disabledForPassword: true,
        });
      }
      return { values: newValues, valid };
    },
  },
  defaultFirebaseSignState
);

export default combineReducers({ fields, createAction });
