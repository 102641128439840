import "firebaseui/dist/firebaseui.css"; // this styles could be removed and later firebaseui could be removed
import { createRoot } from 'react-dom/client'; 
// import "react-image-lightbox/style.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { execBootstrapLoggedInUser } from "store/actions/ui/eas-users";
import { store } from "store/store";
import "whatwg-fetch";
import App from "./App/App";
import "./sass/all.scss";

store.dispatch(execBootstrapLoggedInUser());


const container = document.getElementById('root')!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
       <BrowserRouter>
         <App />
       </BrowserRouter>
  </Provider>
);

// render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );
