import { Component } from "react";
import { connect } from "react-redux";
import { Grid, TextArea } from "semantic-ui-react";
import {
  accordionTripQuest,
  newBookTripFieldValues,
} from "store/actions/ui/eas-book-trip";

class CateringRow extends Component {
  resetAdditionnalValues(accordionName, values) {
    this.props.newBookTripFieldValues({
      tripLegName: this.props.tripLegName,
      tripLegFields: values,
    });
    this.props.openAccordion({
      accordionName: accordionName + this.props.tripLegName,
      forced: false,
    });
  }
  render() {
    const isChangedValue = this.props.isChangedValue;
    const tmpValues = this.props.tmpValues;
    const legValues = this.props.legValues;
    return (
      <Grid
        className="transportCol cateringCol"
        style={
          this.props.accordionActive &&
          this.props.accordionActive["catering" + this.props.tripLegName]
            ? { display: "flex" }
            : { display: "none" }
        }
      >
        <Grid.Row className="borderTransportation"></Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <button
              className="button-wrapper removeLeg"
              onClick={() =>
                this.resetAdditionnalValues("catering", { catering: "" })
              }
            ></button>
            <div className="legTitle">Catering</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="chooseAirPort">
          <Grid.Column className="noPaddingR col tenCol">
            <Grid.Row>
              <span className="noTop">Catering Order</span>
              <Grid>
                <Grid.Row className="noPaddingLR">
                  <Grid.Column className="col">
                    <TextArea
                      value={
                        isChangedValue && isChangedValue["catering"]
                          ? tmpValues.catering
                          : legValues.catering
                      }
                      style={{ minHeight: 64 }}
                      className="textarea"
                      placeholder="What food or drink would you like on your flight?"
                      onChange={(event) => {
                        this.props.newBookTripFieldValues({
                          tripLegName: this.props.tripLegName,
                          field: "catering",
                          value: event.target.value,
                          saveToTmp: this.props.saveToTmp,
                        });
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accordionActive:
      Object.keys(state.ui.bookTrip.accordion).length > 0
        ? state.ui.bookTrip.accordion
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openAccordion: (accordionIndex) => {
    dispatch(accordionTripQuest(accordionIndex));
  },
  newBookTripFieldValues: (fieldAndValue) => {
    dispatch(
      newBookTripFieldValues(
        Object.assign(fieldAndValue, { isChangedValues: true })
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CateringRow);
