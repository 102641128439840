import clone from "clone";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  ADD_OR_REMOVE_PASSENGER,
  NEW_PASSENGER_FIELD_VALUES,
} from "../../actions/types";

const defaultPassengerState = {
  values: {
    name: "",
    email: "",
    default: "",
  },
  valid: {
    name: false,
    email: false,
  },
  tmpValues: {
    name: "",
    email: "",
    isChangedValue: {},
  },
};

let passengerRows = { Passenger_1: defaultPassengerState };
let getLastState = {};
const fields = handleAction(
  NEW_PASSENGER_FIELD_VALUES,
  {
    next(state, action) {
      let newState = clone(passengerRows);
      if (Object.keys(getLastState).length > 0) {
        passengerRows = getLastState;
      }
      if (action.payload.actionType === "RESET") {
        newState = {};
        passengerRows = {
          [action.payload.passengerName]: defaultPassengerState,
        };
      }
      const newValues = clone(
        passengerRows[action.payload.passengerName].values
      );
      const tmpValues = clone(
        passengerRows[action.payload.passengerName].tmpValues
      );
      if (action.payload.passengerFields && !action.payload.saveToTmp) {
        Object.assign(newValues, action.payload.passengerFields);
      }
      if (action.payload.saveToTmp) {
        if (
          action.payload.passengerFields &&
          Object.keys(action.payload.passengerFields).length > 0
        ) {
          tmpValues.isChangedValue = {};
          Object.assign(tmpValues, action.payload.passengerFields);
        } else {
          tmpValues.isChangedValue[action.payload.field] = true;

          Object.assign(tmpValues, {
            [action.payload.field]: action.payload.value,
          });
        }
      } else {
        Object.assign(newValues, {
          [action.payload.field]: action.payload.value,
        });
        Object.assign(tmpValues, {
          isChangedValue: action.payload.isChangedValue
            ? action.payload.isChangedValue
            : {},
        });
      }
      const valid = clone(passengerRows[action.payload.passengerName].valid);
      Object.assign(valid, {
        name: Boolean(newValues.name).valueOf(),
        email: Boolean(newValues.email).valueOf(),
      });
      passengerRows[action.payload.passengerName] = {
        values: newValues,
        valid,
        tmpValues: tmpValues,
      };
      Object.assign(newState, passengerRows);
      getLastState = newState;
      return newState;
    },
  },
  passengerRows
);

const valid = handleAction(
  NEW_PASSENGER_FIELD_VALUES,
  {
    next(state, action) {
      const newState = clone(state);
      return newState;
    },
  },
  passengerRows
);

const passengers = handleAction(
  ADD_OR_REMOVE_PASSENGER,
  {
    next(state, action) {
      let newState = {};
      if (Object.keys(getLastState).length > 0) {
        passengerRows = getLastState;
      }
      if (action.payload.actionType === "ADD") {
        if (action.payload.passengerName) {
          passengerRows[action.payload.passengerName] = defaultPassengerState;
          Object.assign(newState, passengerRows);
        }
      } else if (action.payload.actionType === "REMOVE") {
        delete passengerRows[action.payload.passengerName];
        Object.assign(newState, passengerRows);
      } else if (action.payload.actionType === "RESET") {
        newState = {};
        if (action.payload.step === "legs") {
          getLastState = {};
        }
        passengerRows = {
          [action.payload.passengerName]: defaultPassengerState,
        };
        Object.assign(newState, passengerRows);
      }
      return newState;
    },
  },
  passengerRows
);

export default combineReducers({ fields, valid, passengers });
