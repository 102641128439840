import dotEnv from "environment-config";
import { createAction } from "redux-actions";
import { NEW_BOOK_TRIP_FIELD_VALUES, SEARCH_AIRPORTS } from "../types";

const searchAirportsUrl = (latitude, longitude, radiusNm) =>
  `${dotEnv.apiUrl}/airports?location=${latitude},${longitude},${radiusNm}`;
export const searchAiports = createAction(SEARCH_AIRPORTS);
export const newBookTripFieldValues = createAction(NEW_BOOK_TRIP_FIELD_VALUES);

export const execSearchAirports = (
  tripLegName,
  field,
  saveToTmp,
  latitude,
  longitude,
  radiusNm
) => (dispatch, getState) => {
  const key = `${latitude},${longitude}`;
  const existing =
    Object.keys(getState().services.airports.searchAiports).length > 0 &&
    getState().services.airports.searchAiports[key];
  //Check if we already have the airports for this latitude + longitude and they are new enough
  if (
    !existing ||
    existing.status === "failed" ||
    existing.timestamp < new Date().getTime() - 60 * 60 * 1000
  ) {
    const url = searchAirportsUrl(latitude, longitude, radiusNm);
    const obj = {
      method: "GET",
      headers: {
        //Currently we can't send the auth token here becuase the server is not expecting it. However, it should be
        AuthToken: getState().services.firebase.firebaseUser.response
          .accessToken,
        //  'Authorization' : getState().firebaseUser.response.localId,
        "Content-Type": "application/json",
      },
    };
    //dispatch(newBookTripFieldValues({tripLegName: tripLegName, field: field, value: null, saveToTmp}))
    dispatch(searchAiports({ latitude, longitude }));
    return fetch(url, obj)
      .then((response) => {
        if (response.ok) {
          return response.json().then((result) => {
            dispatch(
              searchAiports({ airports: result.airports, latitude, longitude })
            );
            dispatch(
              newBookTripFieldValues({
                tripLegName: tripLegName,
                field: field,
                value: result.airports,
                saveToTmp,
              })
            );
            return result;
          });
        }
        return Promise.reject(new Error("Error getting airports"));
      })
      .catch((error) => {
        dispatch(searchAiports(error));
        dispatch(
          newBookTripFieldValues({
            tripLegName: tripLegName,
            field: field,
            value: null,
            saveToTmp,
          })
        );
        return Promise.reject(error);
      });
  } else {
    dispatch(searchAiports({ existing, latitude, longitude }));
    console.log(getState().services.airports.searchAiports[key]);
    dispatch(
      newBookTripFieldValues({
        tripLegName: tripLegName,
        field: field,
        value: getState().services.airports.searchAiports[key].airports,
        saveToTmp,
      })
    );
    return Promise.resolve(existing);
  }
};
