import clone from "clone";
import { handleActions } from "redux-actions";
import {
  ACCORDION_FLIGHT_DETAIL,
  FIREBASE_IMAGE_STORAGE,
  LIGHT_BOX_IS_OPEN,
  SELECT_FLIGHT_DETAIL,
} from "../../actions/types";

const defaultState = {
  cardDatail: {
    activedLegId: 0,
    leg: 0,
    flight: 0,
    showAccordion: false,
    class: "",
    accordionActive: [],
    aircraftImages: [],
    isOpen: false,
    photoIndex: 0,
    lightBoxType: "",
    countAccount: 0,
  },
};
let accordionActived = {};

export default handleActions(
  {
    [SELECT_FLIGHT_DETAIL]: {
      next(state, action) {
        if (action.payload) {
          const cardDatail = clone(state.cardDatail);
          Object.assign(cardDatail, {
            leg: action.payload.leg,
            flight: action.payload.flight,
            activedLegId: action.payload.leg.legId,
            countAccount: action.payload.countAccount,
          });
          return { cardDatail: cardDatail };
        } else {
          return defaultState;
        }
      },
    },
    [ACCORDION_FLIGHT_DETAIL]: {
      next(state, action) {
        if (action.payload) {
          const cardDatail = clone(state.cardDatail);
          accordionActived[action.payload.accordionName] = !accordionActived[
            action.payload.accordionName
          ];
          Object.assign(cardDatail, { accordionActive: accordionActived });
          return { cardDatail: cardDatail };
        } else {
          return defaultState;
        }
      },
    },
    [FIREBASE_IMAGE_STORAGE]: {
      next(state, action) {
        if (action.payload) {
          const cardDatail = clone(state.cardDatail);
          Object.assign(cardDatail, { aircraftImages: action.payload });
          return { cardDatail: cardDatail };
        } else {
          return defaultState;
        }
      },
    },
    [LIGHT_BOX_IS_OPEN]: {
      next(state, action) {
        if (action.payload) {
          const cardDatail = clone(state.cardDatail);
          Object.assign(cardDatail, {
            isOpen: action.payload.lightBoxOpen,
            photoIndex: action.payload.photoIndex,
            lightBoxType: action.payload.lightBoxType,
          });
          return { cardDatail: cardDatail };
        } else {
          return defaultState;
        }
      },
    },
  },
  defaultState
);
