import dotEnv from "environment-config";
import moment from "moment";
import { createAction } from "redux-actions";
import {
  GET_ITINERARY_FOR_TRIP,
  GET_MORE_FLIGHTS,
  SEARCH_TRIPS,
} from "../types";

const searchTripsUrl = (userId, startDate, endDate) =>
  `${dotEnv.apiUrl}/users/${userId}/trip-legs?from=${startDate}&to=${endDate}`;
const itineraryForTripUrl = (tripId) =>
  `${dotEnv.apiUrl}/trip/${tripId}/itinerary?format=P`;
const UPCOMING = "upcoming";
const PREVIOUS = "previous";
let groupLegs = [];

export const searchTrips = createAction(SEARCH_TRIPS);
export const getMoreTrips = createAction(GET_MORE_FLIGHTS);
export const getItineraryForTrip = createAction(GET_ITINERARY_FOR_TRIP);

function checkAccountForAllFlight(accounts) {
  let isCorrectAccount = false;
  accounts && accounts.forEach((account) => {
    if (["Owner", "Booker", "Passenger"].includes(account.role)) {
      isCorrectAccount = true;
    }
  });
  return isCorrectAccount;
}

function detectAccountIsPassenger(accounts, passengers) {
  let isPassenger = false;
  if (accounts) {
    accounts.forEach((account) => {
      if (passengers) {
        passengers.forEach((passenger) => {
          if (account.astroUserId === passenger.userId) {
            isPassenger = true;
          }
        });
      }
    });
  }
  return isPassenger;
}

function sortDate(legs, type) {
  return legs.sort((a, b) => {
    if (type === UPCOMING) {
      return new Date(a.departureTime) - new Date(b.departureTime);
    } else if (type === PREVIOUS) {
      return new Date(b.departureTime) - new Date(a.departureTime);
    }
    return 0;
  });
}

function pushFlightToList(type, arrivalDate, item, dateGroup) {
  if (
    type === UPCOMING &&
    arrivalDate.format("MMDDYYYY") >= moment().format("MMDDYYYY")
  ) {
    if (item.flights.length > 0) {
      item.flights.forEach((flight) => {
        const ETA = moment(flight.ETA).hours();
        // hours return 24h, ETA > 12 AM
        if (ETA >= 0) {
          dateGroup.push(item);
        }
      });
    } else {
      const arrivalTime = moment(item.arrivalTime).hours();
      // arrivalTime > 12 AM
      if (arrivalTime >= 0) {
        dateGroup.push(item);
      }
    }
  } else if (
    type === PREVIOUS &&
    arrivalDate.format("MMDDYYYY") < moment().format("MMDDYYYY")
  ) {
    if (item.flights.length > 0) {
      item.flights.forEach((flight) => {
        const ETAHour = moment.utc(flight.ETD).hours();
        const ETAMinutes = moment.utc(flight.ETD).minutes();
        // hours return 24h, ETA > 12 AM
        if (ETAHour <= 23 && ETAMinutes <= 59) {
          dateGroup.push(item);
        }
      });
    } else {
      const arrivalTime = moment.utc(item.departureTime).hours();
      const ETAMinutes = moment.utc(item.departureTime).minutes();
      // arrivalTime > 12 AM
      // eslint-disable-next-line
      if (arrivalTime <= 23 && ETAMinutes <= 59) {
        dateGroup.push(item);
      }
    }
  }
  return dateGroup;
}

function groupByDate(legs, filter, type, allFlightForAccount, accounts) {
  let dateGroup = {},
    flightGroupResult = {};
  legs = sortDate(legs, type);
  if (legs) {
    legs.forEach((item) => {
      let arrivalDate = moment(item.arrivalTime);
      let dayName = "";
      if (arrivalDate.format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")) {
        dayName = "Today";
      } else {
        dayName = arrivalDate.format("dddd");
      }
      let monthAndYear =
        dayName +
        ", " +
        arrivalDate.format("MMMM") +
        " " +
        arrivalDate.format("DD");
      if (dateGroup[monthAndYear] === undefined) {
        dateGroup[monthAndYear] = [];
      }
      // Passengers Checking
      let passengers = item.passenger;

      if (filter === "mine" && detectAccountIsPassenger(accounts, passengers)) {
        pushFlightToList(type, arrivalDate, item, dateGroup[monthAndYear]);
      } else if (filter === "all" && allFlightForAccount) {
        pushFlightToList(type, arrivalDate, item, dateGroup[monthAndYear]);
      }
    });
  }
  if (dateGroup && Object.keys(dateGroup).length > 0) {
    Object.keys(dateGroup).forEach((dateFlight) => {
      if (dateGroup[dateFlight].length > 0) {
        flightGroupResult[dateFlight] = dateGroup[dateFlight];
      }
    });
  }
  return flightGroupResult;
}

export const execSearchTrips = ({
  userId,
  startDate,
  endDate,
  isLoadMore,
  mineOrAll,
  timeRange,
}) => {
  return (dispatch, getState) => {
    let obj = {
      method: "GET",
      headers: {
        AuthToken: getState().services.firebase.firebaseUser.response
          .accessToken,
        "Content-Type": "application/json",
      },
    };
    isLoadMore ? dispatch(getMoreTrips()) : dispatch(searchTrips());

    console.log('trips', searchTripsUrl(userId, startDate, endDate), obj)
    return fetch(searchTripsUrl(userId, startDate, endDate), obj)
      .then((response) => {
        if (response.ok) {
          return response.json().then((result) => {
            const accounts = getState().services.easUsers.loggedInAccounts.list;
            console.log('test...', accounts)
            const allFlightForAccount = checkAccountForAllFlight(accounts);
            groupLegs = groupByDate(
              result.tripLegs,
              mineOrAll,
              timeRange,
              allFlightForAccount,
              getState().services.easUsers.loggedInAccounts.list
            );
            isLoadMore
              ? dispatch(getMoreTrips(groupLegs))
              : dispatch(
                  searchTrips({
                    tripLegs: groupLegs,
                    maximumHistoryPages: result.maximumHistoryPages,
                  })
                );
            return groupLegs;
          });
        } else {
          console.log("Error getting trips");
          return new Error("Error getting trips");
        }
      })
      .catch((error) => {
        dispatch(searchTrips(error));
        return Promise.reject(error);
      });
  };
};

export const execGetItineraryForTrip = (astroId) => (dispatch, getState) => {
  const obj = {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };

  dispatch(getItineraryForTrip());
  return fetch(itineraryForTripUrl(astroId), obj)
    .then((response) => {
      if (response.ok) {
        return response.json().then((result) => {
          dispatch(
            getItineraryForTrip({ astroId: astroId, result: result.content })
          );
          return result;
        });
      }
      return Promise.reject(new Error("Error getting json"));
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
