import iconWhiteStacked from "images/airshare_white_stacked.svg";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/withRouter";
import { paths } from "routes";
import {
  execBootstrapLoggedInUser,
  execShadedFireBaseCreateAccount,
  newFirebaseSignUpFieldValue,
} from "store/actions/ui/eas-users";

class CreateAccount extends Component {
  accountField(
    type,
    field,
    messageOnChangeField,
    errorField,
    placeholder,
    valid,
    error
  ) {
    return (
      <div>
        <input
          disabled={field === "password" && valid.disabledForPassword}
          type={type}
          placeholder={placeholder}
          onChange={(event) => {
            this.props.newFirebaseSignUpFieldValue({
              [field]: event.target.value,
            });
          }}
        ></input>
        {errorField && errorField.errorCode ? (
          <div className="validateField">{errorField.errorMessage}</div>
        ) : null}
        {messageOnChangeField && messageOnChangeField ? (
          <div className="validateField">{messageOnChangeField}</div>
        ) : null}
      </div>
    );
  }

  render() {
    const { values, valid, errorField } = this.props;
    return (
      <div className="firebase-ui login-page">
        <div className="signupBox">
          <div className="logoMain">
            <img src={iconWhiteStacked} alt="" />
          </div>
          <div className="inner">
            <div className="title">Create your account</div>
            <div className="emailPasswordAccount">
              <form className="formInformation">
                {this.accountField(
                  "email",
                  "emailAddress",
                  valid.messageForEmail,
                  errorField.emailField,
                  "Email",
                  valid
                )}
                {this.accountField(
                  "password",
                  "password",
                  valid.messageForPassword,
                  errorField.passwordField,
                  "Password",
                  valid
                )}
                <button
                  type="button"
                  disabled={
                    valid.disabledForPassword || valid.messageForPassword
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    return this.props.execShadedFireBaseCreateAccount(
                      values.emailAddress,
                      values.password
                    )
                  }
                    
                  }
                >
                  Next
                </button>
              </form>
              <div className="text">
                Already have an account?{" "}
                <Link className="linkText" to={paths.build.root()}>
                  Log in
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  newFirebaseSignUpFieldValue: (fieldAndValue) => {
    dispatch(newFirebaseSignUpFieldValue(fieldAndValue));
  },
  execShadedFireBaseCreateAccount: (emailAddress, password) => {
    dispatch(execShadedFireBaseCreateAccount(emailAddress, password)).then(
      (result) => {
        if (result) {
          dispatch(execBootstrapLoggedInUser());
          ownProps.router.navigate(paths.build.createEasAccount());
        }
      }
    );
  },
});
const mapStateToProps = (state) => {
  return {
    values: state.ui.firebaseLogin.createAction.values,
    valid: state.ui.firebaseLogin.createAction.valid,
    errorField: state.services.firebase.firebaseCreateAccount,
    isLoading: state.services.firebase.firebaseCreateAccount
      ? state.services.firebase.firebaseCreateAccount.result
      : false,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
);
