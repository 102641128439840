import { analyticsEvent } from "analytics/gtag";
import Header from "components/Header";
import envConfig from "environment-config";
import iconAccountCircle from "images/ic_account_circle.png";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "routes";
import { menuDropdown, userDropdown } from "store/actions/ui/header";
import { closeCallAirCare, popCallAirCare } from "store/actions/ui/popups";
import CallAirCarePopup from "./CallAirCarePopup";

const PendingScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const loggedInEasUser = useSelector(
    (state) => state.services.easUsers.loggedIn.user
  );
  const loggedInUser = useSelector((state) => state.services.easUsers.loggedIn);
  const userName = useSelector(
    (state) => state.services.firebase.firebaseUser.name
  );
  const visibility = useSelector((state) => state.ui.header.visibility);
  const isCallAirCare = useSelector((state) => state.ui.popups.isCallAirCare);
  const logout = useCallback(() => history(paths.build.logout()), [
    history,
  ]);
  const userDropdownAction = useCallback(() => dispatch(userDropdown()), [
    dispatch,
  ]);
  const menuDropdownAction = useCallback(() => dispatch(menuDropdown()), [
    dispatch,
  ]);
  const closeCallAirCareAction = useCallback(
    () => dispatch(closeCallAirCare()),
    [dispatch]
  );
  const callAirCareAction = useCallback(() => dispatch(popCallAirCare()), [
    dispatch,
  ]);
  const easUserPending = loggedInEasUser ? loggedInEasUser.status : "";
  const allowAdmin = loggedInEasUser.isEmployee;
  const emailVerified = loggedInEasUser.emailVerified;
  const userId = loggedInUser.user ? loggedInUser.user.systemUserId : null;
  const easUserStatus =
    loggedInUser.user && loggedInUser.exists === "yes"
      ? loggedInUser.user.status
      : "";

  useEffect(() => {
    //Tracking verifying screen
    analyticsEvent("unauthorized_" + easUserStatus.toLowerCase(), {
      event_category: "authorization",
      event_label: userId,
    });
  }, [easUserStatus, userId]);

  return (
    <div>
      {isCallAirCare && (
        <CallAirCarePopup closeCallAirCare={closeCallAirCareAction} />
      )}
      <Header
        envConfig={envConfig}
        userName={userName}
        logout={logout}
        visibility={visibility}
        toggle={{
          userDropdown: userDropdownAction,
          menuDropdown: menuDropdownAction,
        }}
        isAdmin={allowAdmin}
        easUserPending={easUserPending}
      />
      <div className="pending-screen">
        <div className="verify-block">
          <img src={iconAccountCircle} alt="" />
          {emailVerified ? (
            <div>
              <b>
                Owner Services has received your request and is working to
                verify your account.
              </b>
              <p>
                In the meantime, if you need immediate assistance with a trip
                please give AirCare a call!
              </p>
              <button className="call-link" onClick={callAirCareAction}>
                <i></i>Call AirCare
              </button>
            </div>
          ) : (
            <div>
              <b>Thanks for signing up!</b>
              <p>
                We&#x27;ve sent you a verification email, check your inbox and
                click on the link to activate your account.
                <br />
                If you have any questions, Owner Services is standing by!
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PendingScreen;
