import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { astroUserAccounts } from "store/actions/services/astro-users";
import {
  execShadedGetUser,
  execShadedRefreshSearchUsers,
} from "store/actions/ui/eas-users";
import { closeUserAuthorization } from "store/actions/ui/popups";
import {
  editedAstroIdsToAdd,
  execGetAccountsForCurrentIds,
  execSaveCurrentAstroUsersAccountsToEasUserAndAuthorize,
} from "store/actions/ui/user-astro-ids-edit";
import Account from "./AstroAccount";

class AuthorizeUserPopup extends Component {
  updateAccounts() {
    return () => {
      if (this.props.idsSubmittable) {
        this.props.getAstroUserAccounts();
      }
    };
  }

  lookupKeyPress(event) {
    if (event.key === "Enter") {
      if (this.props.idsSubmittable) {
        this.props.getAstroUserAccounts();
      }
    }
  }

  componentDidMount() {
    this.props.editIds("");
    this.props.clearAstroUserAccounts();
    this.props.fetchUser(this.props.userId);
  }

  render() {
    return (
      <section className="popup">
        <div className="bg_overlay"></div>
        {this.props.user ? (
          <div className="inner">
            <div className="content">
              <h2>Authorize User</h2>
              <p className="text">
                What UserIDs do you want to associate{" "}
                <span className="NamePop">{`${this.props.user.firstName} ${this.props.user.lastName} `}</span>
                to?
              </p>

              <div className="inputSearch">
                <input
                  type="number"
                  id="searchInput"
                  onKeyPress={this.lookupKeyPress.bind(this)}
                  onChange={(event) => this.props.editIds(event.target.value)}
                  value={this.props.idsValue}
                  className={`styleInput Search w1 border ${
                    this.props.idsInvalid ? "error" : ""
                  }`}
                  placeholder="Enter User ID"
                />
                <button
                  disabled={!this.props.idsSubmittable}
                  className="btnSerach"
                  onClick={this.updateAccounts()}
                >
                  LOOKUP
                </button>
              </div>

              <div className="showAcount">
                <div className="inner-show">
                  {this.props.accountSearchResults.map((astroUser) => (
                    <Account
                      astroUserId={astroUser.astroUserId}
                      accounts={astroUser.accounts}
                      key={astroUser.astroUserId}
                    />
                  ))}
                </div>
              </div>
              <div className="footer">
                <button
                  disabled={false}
                  className="buttonStyle Authorize"
                  onClick={this.props.saveAccountsToUser(this.props.userId)}
                >
                  Authorize
                </button>
                <button
                  className="buttonStyle Cancel"
                  onClick={this.props.close}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

AuthorizeUserPopup.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  idsInvalid: PropTypes.bool.isRequired,
  idsSubmittable: PropTypes.bool.isRequired,
  accountSearchResults: PropTypes.array.isRequired,
  idsValue: PropTypes.string.isRequired,
  fetchUser: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  getAstroUserAccounts: PropTypes.func.isRequired,
  editIds: PropTypes.func.isRequired,
  saveAccountsToUser: PropTypes.func.isRequired,
  clearAstroUserAccounts: PropTypes.func.isRequired,
};

AuthorizeUserPopup.contextTypes = { router: PropTypes.object };

const mapStateToProps = (state) => ({
  userId: state.ui.popups.authorizeUserId,
  //For Tango (EAS-583) Should this be state.services.easUsers.current?
  user: state.services.easUsers.current.user,
  loading: state.services.easUsers.current.loading,
  failed: state.services.easUsers.current.failed,
  idsValue: state.userAstroIdsEdit.edit.value,
  idsInvalid: state.userAstroIdsEdit.edit.status === "invalid",
  idsSubmittable: state.userAstroIdsEdit.edit.status === "valid",
  //We've only got one result here, but we will eventually go back to adding multiple astro user ids  at one time.
  // This popup can render an array. So, we wrap our single result as an array
  accountSearchResults: state.services.astroUsers.userAccounts.astroUserId
    ? [state.services.astroUsers.userAccounts]
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(execShadedGetUser(userId)),
  close: () => dispatch(closeUserAuthorization()),
  getAstroUserAccounts: () => dispatch(execGetAccountsForCurrentIds()),
  editIds: (newValue) => dispatch(editedAstroIdsToAdd(newValue)),
  saveAccountsToUser: (userId) => () => {
    dispatch(
      execSaveCurrentAstroUsersAccountsToEasUserAndAuthorize(userId)
    ).then(() => {
      dispatch(execShadedRefreshSearchUsers());
      dispatch(closeUserAuthorization());
    });
  },
  clearAstroUserAccounts: () => dispatch(astroUserAccounts("", [])),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeUserPopup);
