import { analyticsEvent } from "analytics/gtag";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { paths } from "routes";
import { resetTripStep } from "store/actions/ui/eas-book-trip";

class TripRequestConfirmation extends Component {
  render() {
    return (
      <section className="tripConfirmation">
        <div className="confirmBox">
          <h3>We’re on it!</h3>
          <p>
            We will plan your trip and notify you when it&#x27;s confirmed. If
            we have any questions we will contact you.
          </p>
          <div className="navLink">
            <Link
              className="flight"
              to={paths.build.bookATrip()}
              onClick={() => {
                this.props.resetBookTripRequest(
                  this.props.firstLegName,
                  this.props.firstTripLeg
                );
                analyticsEvent("Request_Another_Trip", {
                  event_category: "book_trip",
                  event_label:
                    "	User attempts to complete another trip request from the Trip Request success page.",
                });
              }}
            >
              Request Another Trip
            </Link>
            <Link
              className="flight"
              to={paths.build.bookATrip()}
              onClick={() => {
                this.props.resetBookTripRequest(
                  this.props.firstLegName,
                  this.props.firstTripLeg,
                  this.props.summary
                );
              }}
            >
              Back to Flights
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetStep: () => {
    dispatch(resetTripStep());
  },
});

export default connect(null, mapDispatchToProps)(TripRequestConfirmation);
