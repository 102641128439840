import { usePageViews } from "analytics/gtag";
import { AppLoader } from "App/AppLoader";
import { ErrorCatch } from "App/ErrorCatch";
import envConfig from "environment-config";
import CreateAccount from "pages/CreateAccount";
import { CreateEasAccount } from "pages/CreateEasAccount/CreateEasAccount";
import FirebaseUiWrapper from "pages/FirebaseUiWrapper";
import Flights from "pages/Flights/Flights";
import { Logout } from "pages/Logout";
import PendingUsers from "pages/PendingUsers";
import BookATrip from "pages/TripRequest/BookATrip";
import UnderConstruction from "pages/UnderConstruction";
import User from "pages/User/User";
import UserSearch from "pages/UserSearch";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { paths } from "routes";
import AdminRoute from "routes/AdminRoute";
import AuthenticationRoute from "routes/AuthenticationRoute";
import FlightsMineOrAllRoute from "routes/FlightsMineOrAllRoute";
import AuthorizedRoute from "routes/AuthorizedRoute";
import FlightsRoute from "routes/FlightsRoute";

function useGoogleMapsApi() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // prevent loading more than once
    if (!isLoaded) {
      const ref = window.document.getElementsByTagName("script")[0];
      const script = window.document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${envConfig.apiKey}&libraries=places`;
      script.async = true;
      ref.parentNode.insertBefore(script, ref);
      setIsLoaded(true);
    }
  }, [isLoaded]);
}

const App = () => {
  useGoogleMapsApi();
  usePageViews();

  return (
    <ErrorCatch>
      <AppLoader>
        <Routes>
          <Route exact element={<AuthenticationRoute><FirebaseUiWrapper /></AuthenticationRoute>} path={paths.definition.root} />
          <Route exact element={<AuthenticationRoute><CreateAccount /></AuthenticationRoute>} path={paths.definition.createUser} />
          <Route exact element={<AuthenticationRoute><CreateEasAccount /></AuthenticationRoute>} path={paths.definition.createEasAccount} />

d
          <Route exact element={<AdminRoute><PendingUsers /></AdminRoute>} path={paths.definition.admin} />
          <Route exact element={<AdminRoute><User /></AdminRoute>} path={paths.definition.userDetails} />
          <Route exact element={<AdminRoute><UserSearch /></AdminRoute>} path={paths.definition.userSearch} />

          <Route exact element={<AuthorizedRoute><BookATrip /></AuthorizedRoute>} path={paths.definition.bookATrip} />
          <Route exact element={<AuthorizedRoute><Flights /></AuthorizedRoute>} path={paths.definition.flightsMineOrAllWithTime} />
          <Route exact element={<FlightsRoute><Flights /></FlightsRoute>} path={paths.definition.flights} />
          <Route exact element={<FlightsMineOrAllRoute><Flights /></FlightsMineOrAllRoute>} path={paths.definition.flightsMineOrAll} />



          <Route exact element={<Logout />} path={paths.definition.logout} />
          <Route path="*" element={<UnderConstruction />} />
        </Routes>
      </AppLoader>
    </ErrorCatch>
  );
};

export default App;
