import { createAction } from "redux-actions";
import {
  CLOSE_CALL_AIRCARE,
  CLOSE_GIVE_FEEDBACK,
  CLOSE_USER_AUTHORIZATION,
  CLOSE_USER_RESET_PASSWORD,
  CLOSE_USER_STATUS_CHANGE,
  POP_CALL_AIRCARE,
  POP_GIVE_FEEDBACK,
  POP_USER_AUTHORIZATION,
  POP_USER_RESET_PASSWORD,
  POP_USER_STATUS_CHANGE,
} from "../types";

export const popUserAuthorization = createAction(POP_USER_AUTHORIZATION);
export const closeUserAuthorization = createAction(CLOSE_USER_AUTHORIZATION);

export const popUserStatusChange = createAction(POP_USER_STATUS_CHANGE);
export const closeUserStatusChange = createAction(CLOSE_USER_STATUS_CHANGE);

export const popUserResetPassword = createAction(POP_USER_RESET_PASSWORD);
export const closeUserResetPassword = createAction(CLOSE_USER_RESET_PASSWORD);

export const popCallAirCare = createAction(POP_CALL_AIRCARE);
export const closeCallAirCare = createAction(CLOSE_CALL_AIRCARE);

export const popGiveFeedback = createAction(POP_GIVE_FEEDBACK);
export const closeGiveFeedback = createAction(CLOSE_GIVE_FEEDBACK);
