import iconAvatar from "images/avatar.png";
import imageBitmapCopy from "images/BitmapCopy@3x.png";
import moment from "moment";
// import Lightbox from "react-image-lightbox";
import { Card, Grid, Image } from "semantic-ui-react";
import FlightRow from "./FlightRow";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

const FlightDetail = ({
  insertEventToCalendar,
  convertToBlob,
  props,
  sendMailModifyTripRequest,
}) => {
  let fboName = "",
    groupFBO = {},
    groundTransports = {},
    calFlightTime;
  let leg = props.selectedLeg;
  let flight = props.selectedFlight;
  if (leg.flightTime > 60) {
    const minutes = leg.flightTime % 60;
    const hours = (leg.flightTime - minutes) / 60;
    calFlightTime = hours + "hr " + minutes + "min";
  } else {
    calFlightTime = leg.flightTime + "min";
  }
  const groundTransport = leg.groundTransport;
  // Display fbo name
  let pushFboObject = (transportType) => {
    fboName = transportType === "destination" ? "From " : "To ";
    leg.FBO.forEach((fbo) => {
      if (transportType === fbo.type) {
        groupFBO[transportType] = fbo;
        if (groundTransports[transportType] !== undefined) {
          groundTransports[transportType]["fboName"] =
            fboName + groupFBO[transportType]["name"];
        }
      }
    });
    return groupFBO;
  };

  let transportDetail = (transportType, transport) => {
    const accordionName = transport.agency
      ? transport.agency.name
      : "Ground Transportation";
    const transportFBO = pushFboObject(transportType);
    if (transport) {
      if (transport.transportType === "Rental Vehicle") {
        const groundColumns = {
          Agency: accordionName,
          Vehicle: transport.vehicleType,
          "Reservation Name": transport.reservationName,
          "Cost Estimate": transport.costEstimate,
          "Confirmation #": transport.confirmationNumber,
          "Passenger Count": transport.passengerCount,
        };
        return (
          <div>
            <Grid columns={2} divided="vertically" className="card-ui">
              <Grid.Row className="gridRow">
                {Object.keys(groundColumns).map((groundCol, key) => {
                  return groundColumns[groundCol] ? (
                    <Grid.Column width={7} key={key}>
                      <Card.Header>{groundCol}</Card.Header>
                      <Card.Meta>
                        <span className="desc">{groundColumns[groundCol]}</span>
                      </Card.Meta>
                    </Grid.Column>
                  ) : null;
                })}
              </Grid.Row>
            </Grid>
            {transportFBO[transportType].telephone.length > 0
              ? transportFBO[transportType].telephone.map((phone, key) => {
                  return phone.type === "Phone - Business" ? (
                    <Grid
                      columns={1}
                      divided="vertically"
                      className="card-ui"
                      key={key}
                    >
                      <Grid.Row className="gridRow">
                        <Grid.Column>
                          <Card.Header>Phone</Card.Header>
                          <Card.Meta>
                            <span className="desc">
                              <a
                                className="link"
                                key={key}
                                href={"tel:" + phone.value}
                              >
                                {phone.value}
                              </a>
                            </span>
                          </Card.Meta>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : null;
                })
              : null}
            {transport.note ? (
              <Grid columns={1} divided="vertically" className="card-ui">
                <Grid.Row className="gridRow">
                  <Grid.Column>
                    <Card.Header>Note</Card.Header>
                    <Card.Meta>
                      <span className="desc">{transport.note}</span>
                    </Card.Meta>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : null}
          </div>
        );
      } else if (transport.transportType === "Chauffeur Service") {
        const groundColumns = {
          Agency: accordionName,
          Vehicle: transport.vehicleType,
          "Pickup Location": transport.pickupLocation,
          "Final Drop": transport.finalDrop,
        };
        return (
          <div>
            <Grid columns={2} divided="vertically" className="card-ui">
              <Grid.Row className="gridRow">
                {Object.keys(groundColumns).map((groundCol, key) => {
                  return groundColumns[groundCol] ? (
                    <Grid.Column width={7} key={key}>
                      <Card.Header>{groundCol}</Card.Header>
                      <Card.Meta>
                        <span className="desc">{groundColumns[groundCol]}</span>
                      </Card.Meta>
                    </Grid.Column>
                  ) : null;
                })}
              </Grid.Row>
            </Grid>
            {transport.note ? (
              <Grid columns={1} divided="vertically" className="card-ui">
                <Grid.Row className="gridRow">
                  <Grid.Column>
                    <Card.Header>Note</Card.Header>
                    <Card.Meta>
                      <span className="desc">{transport.note}</span>
                    </Card.Meta>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : null}
          </div>
        );
      }
    }
  };

  let airportFBODetail = (transportType) => {
    const transportFBO = pushFboObject(transportType);
    return (
      <div>
        <Grid columns={2} divided="vertically" className="card-ui">
          <Grid.Row className="gridRow">
            <Grid.Column width={7}>
              <Card.Header>Address</Card.Header>
              <Card.Meta>
                <span className="desc">
                  <a
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://maps.google.com/?q=" +
                      groupFBO[transportType].address +
                      ", " +
                      groupFBO[transportType].city +
                      ", " +
                      groupFBO[transportType].state +
                      ", " +
                      groupFBO[transportType].postalCode +
                      ", " +
                      groupFBO[transportType].country
                    }
                  >
                    {groupFBO[transportType].address +
                      ", " +
                      groupFBO[transportType].city +
                      ", " +
                      groupFBO[transportType].state +
                      ", " +
                      groupFBO[transportType].postalCode +
                      ", " +
                      groupFBO[transportType].country}
                  </a>
                </span>
              </Card.Meta>
            </Grid.Column>
            {transportFBO[transportType].telephone.length > 0
              ? groupFBO[transportType].telephone.map((phone, key) => {
                  return phone.type === "Phone - Business" ? (
                    <Grid.Column key={key} width={9}>
                      <Card.Header>Phone</Card.Header>
                      <Card.Meta>
                        <span className="desc link">
                          <a
                            className="link"
                            key={key}
                            href={"tel:" + phone.value}
                          >
                            {phone.value}
                          </a>
                        </span>
                      </Card.Meta>
                    </Grid.Column>
                  ) : null;
                })
              : null}
          </Grid.Row>
        </Grid>
        {groupFBO[transportType].webSite.length > 0 ? (
          <Grid columns={2} divided="vertically" className="card-ui">
            <Grid.Row className="gridRow">
              <Grid.Column width={7}>
                <Card.Header>Website</Card.Header>
                <Card.Meta>
                  <span className="desc link">
                    {groupFBO[transportType].webSite.map((website, key) => {
                      let url = website.value;
                      if (!/^(f|ht)tps?:\/\//i.test(website.value)) {
                        url = "http://" + website.value;
                      }
                      return (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          key={key}
                          href={url}
                        >
                          {website.value}
                        </a>
                      );
                    })}
                  </span>
                </Card.Meta>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </div>
    );
  };

  let isDisplayAirCraft = false;
  let aircraftName = "",
    aircraftTail = "";
  // get aircraft
  if (
    leg.flights.length < 0 ||
    (flight &&
      ["Not Released", "Deleted", "Canceled"].includes(flight.flightStatus))
  ) {
    aircraftName = leg.aircraftTypeName;
    isDisplayAirCraft = true;
  } else if (
    flight &&
    ["Released", "In Progress", "Completed", "Closed"].includes(
      flight.flightStatus
    )
  ) {
    aircraftName = flight.aircraftTypeName;
    aircraftTail = flight.aircraftTailNumber;
    isDisplayAirCraft = true;
  }

  if (flight) {
    if (moment().diff(moment(flight.arrivalTime), "hours") < -10) {
      isDisplayAirCraft = false;
    }
  }

  let passengersDetail = () => {
    if (leg.passenger.length > 0) {
      leg.passenger = leg.passenger.sort(function (a, b) {
        return b.isLeadPassenger - a.isLeadPassenger;
      });
      let passengerObject = leg.passenger;
      if (leg.passengerCount > passengerObject.length) {
        for (let i = passengerObject.length + 1; i <= leg.passengerCount; i++) {
          passengerObject.push({
            isPlaceHolder: true,
            displayName: "Passenger " + i,
          });
        }
      }
      return (
        <Card className="cardBanner itineraryCard passengerCard">
          <Card.Content className="">
            <h4 className="title">
              {leg.passenger.length === 1 ? "Passenger" : "Passengers"}
            </h4>
            {passengerObject.map((item, key) => {
              return (
                <Grid
                  key={key}
                  columns={3}
                  divided="vertically"
                  className="card-ui accordionTitle"
                >
                  <Grid.Row className="gridRow">
                    <Grid.Column width={2}>
                      <span className="nameText">
                        {item.firstName && item.lastName
                          ? item.firstName.substring(0, 1) +
                            item.lastName.substring(0, 1)
                          : item.isPlaceHolder
                          ? "P"
                          : ""}
                      </span>
                    </Grid.Column>
                    <Grid.Column
                      width={10}
                      verticalAlign="middle"
                      className="itineraryDesc noPaddingLeft"
                    >
                      <Card.Header>{item.displayName}</Card.Header>
                    </Grid.Column>
                    {item.isLeadPassenger ? (
                      <Grid.Column
                        width={4}
                        verticalAlign="middle"
                        textAlign="right"
                      >
                        <span className="leadText">Lead Passenger</span>
                      </Grid.Column>
                    ) : null}
                  </Grid.Row>
                </Grid>
              );
            })}
          </Card.Content>
        </Card>
      );
    }
  };

  let cateringDetail = () => {
    let cateringtext = "";
    return leg.catering.length > 0 ? (
      <div className="cateringSection">
        <div
          role="button"
          onClick={() =>
            props.openAccordionFlightDetail({
              accordionName: "cateringSection",
            })
          }
          className={`button-wrapper ${
            props.accordionActive["cateringSection"] ? "open" : ""
          }`}
        >
          <Grid
            columns={2}
            divided="vertically"
            className="card-ui accordionTitle"
          >
            <Grid.Row className="gridRow">
              <Grid.Column width={1}>
                <span className="iCon assort"></span>
              </Grid.Column>
              <Grid.Column width={15} className="itineraryDesc noPaddingLeft">
                <Card.Meta>
                  <span className="desc">
                    {leg.catering.map((cate) => {
                      if (cate.order) {
                        if (!cateringtext) {
                          cateringtext = cate.order;
                        } else {
                          cateringtext = cateringtext + ", " + cate.order;
                        }
                      }
                      return cateringtext;
                    })}
                  </span>
                </Card.Meta>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        {leg.catering.map((cate, key) => {
          return (
            <div
              key={key}
              className="accordionContent"
              style={
                props.accordionActive["cateringSection"]
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Grid
                columns={1}
                divided="vertically"
                className="card-ui accordionTitle"
              >
                <Grid.Row className="gridRow">
                  {cate.vendorName ? (
                    <Grid.Column width={15}>
                      <Card.Header className="vendorTitle">
                        {cate.vendorName}
                      </Card.Header>
                    </Grid.Column>
                  ) : null}
                  {cate.order ? (
                    <Grid.Column width={15}>
                      <Card.Header>Order</Card.Header>
                      <Card.Meta>
                        <span className="desc">{cate.order}</span>
                      </Card.Meta>
                    </Grid.Column>
                  ) : null}
                  {cate.note ? (
                    <Grid.Column width={15}>
                      <Card.Header>Note</Card.Header>
                      <Card.Meta>
                        <span className="desc">{cate.note}</span>
                      </Card.Meta>
                    </Grid.Column>
                  ) : null}
                </Grid.Row>
              </Grid>
            </div>
          );
        })}
      </div>
    ) : null;
  };

  let isDisplayTrackingTime = false;
  let trackingFlightUrl = "https://flightaware.com/live/flight/";
  if (flight) {
    const flightAwareStart = moment.utc(flight.ETDUTC).subtract(30, "minutes");
    const flightAwareEnd = moment.utc(flight.ETAUTC).add(30, "minutes");
    const flightAwareDepartureTime = moment(flight.departureTimeUTC).subtract(
      30,
      "minutes"
    );
    const flightAwareArrivalTime = moment(flight.arrivalTimeUTC).add(
      30,
      "minutes"
    );
    if (
      flight.flightStatus === "Released" &&
      moment.utc().isBetween(flightAwareStart, flightAwareEnd) &&
      flight.aircraftTailNumber
    ) {
      isDisplayTrackingTime = true;
    } else if (
      ["In Progress", "Completed", "Closed"].includes(flight.flightStatus) &&
      moment
        .utc()
        .isBetween(flightAwareDepartureTime, flightAwareArrivalTime) &&
      flight.aircraftTailNumber
    ) {
      isDisplayTrackingTime = true;
    }
    if (
      leg.destinationAirportCountry === "United States" &&
      leg.originAirportCountry === "United States"
    ) {
      let newTailNumber = flight.aircraftTailNumber.slice(1);
      newTailNumber =
        "XSR" + newTailNumber.substring(0, newTailNumber.length - 2);
      trackingFlightUrl = trackingFlightUrl + newTailNumber;
    } else {
      trackingFlightUrl = trackingFlightUrl + flight.aircraftTailNumber;
    }
  }

  let crewImages = [];
  if (flight) {
    if (flight.crew) {
      flight.crew.forEach((pilot, key) => {




        // const storage = getStorage();
        // const starsRef = ref(storage, "PilotPhotos/"+pilot.userId+".main.jpg");
        // // try {
        //   getDownloadURL(starsRef)
        //       .then(url => {
        //         crewImages.push(
        //             url
        //         );
        //         pilot.asyncImage = url
        //       })
        //       .catch(e=>{
        //         crewImages.push(
        //             iconAvatar
        //         );
        //         pilot.asyncImage = iconAvatar
        //       });



          // starsRef.getDownloadURL().then((url) => {
          //   crewImages.push(
          //       url.length > 0 ? url : iconAvatar
          //   );
          // });
        // } catch (err) {
        //   crewImages.push(
        //       iconAvatar
        //   );
        // }




      });
    }
  }

  return (
    <div style={{ paddingBottom: 20 }}>
      {props.lightBoxOpen && props.lightBoxType === "aircraftImages" ? (
        // <Lightbox
        //   mainSrc={props.aircraftImages[props.photoIndex]}
        //   nextSrc={
        //     props.aircraftImages[
        //       (props.photoIndex + 1) % props.aircraftImages.length
        //     ]
        //   }
        //   prevSrc={
        //     props.aircraftImages[
        //       (props.photoIndex + props.aircraftImages.length - 1) %
        //         props.aircraftImages.length
        //     ]
        //   }
        //   onCloseRequest={() =>
        //     props.openLightBox({
        //       photoIndex: props.photoIndex,
        //       lightBoxOpen: false,
        //       lightBoxType: "",
        //     })
        //   }
        //   onMovePrevRequest={() =>
        //     props.openLightBox({
        //       photoIndex:
        //         (props.photoIndex + props.aircraftImages.length - 1) %
        //         props.aircraftImages.length,
        //       lightBoxOpen: true,
        //       lightBoxType: "aircraftImages",
        //     })
        //   }
        //   onMoveNextRequest={() =>
        //     props.openLightBox({
        //       photoIndex: (props.photoIndex + 1) % props.aircraftImages.length,
        //       lightBoxOpen: true,
        //       lightBoxType: "aircraftImages",
        //     })
        //   }
        // />
          <div>test</div>
      ) : null}

      <Card className="cardBanner flightCards">
        <Card.Content className="cardBannerContent">
          <button
            onClick={() => {
              props.selectFlightDetail(null);
              props.goTo(props.mineOrAll, props.timeRange, {
                flight: "user-closed",
              });
            }}
            className="button-wrapper closeBanner"
          ></button>
          <Image src={imageBitmapCopy} />
          <div className="linearBg"></div>
          <div className="cardDescription">
            <Card.Header>Flight to {leg.destinationAirportCity}</Card.Header>
            <Card.Meta>
              <span className="date">
                {moment.utc(leg.arrivalTime).format("MMMM DD, YYYY")}
              </span>
            </Card.Meta>
          </div>
        </Card.Content>
        <Card.Content extra className="cardInformation trip-item">
          <FlightRow
            leg={leg}
            openFlightDetail={props.openFlightDetail}
            countAccount={props.countAccount}
            calFlightTime={calFlightTime}
            flightSurveys={props.flightSurveys}
          />
        </Card.Content>
        <Card.Content className="editControl">
          <Grid columns={3} divided="vertically" className="card-ui">
            <Grid.Row className="gridRow top">
              <Grid.Column>
                <button
                  onClick={() =>
                    sendMailModifyTripRequest(props, leg.trip.tripNumber)
                  }
                  className="button-wrapper iModify"
                >
                  Modify
                </button>
              </Grid.Column>
              <Grid.Column>
                <button
                  onClick={() => insertEventToCalendar(props, leg)}
                  className="button-wrapper iCalendar"
                >
                  Add to Calendar
                </button>
              </Grid.Column>
              <Grid.Column>
                <button
                  className={`button-wrapper ${
                    convertToBlob ? "iItinerary" : "iItinerary disabled"
                  }`}
                  onClick={() => {
                    if (convertToBlob) {
                      const urlPdf = URL.createObjectURL(convertToBlob);
                      window.open(urlPdf, "_blank");
                    }
                  }}
                >
                  View Itinerary
                </button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>

      {isDisplayTrackingTime ? (
        <Card className="cardBanner trackFlight">
          <Card.Content>
            <a href={trackingFlightUrl} target="_blank" rel="noreferrer">
              Track Flight in FlightAware
            </a>
          </Card.Content>
        </Card>
      ) : null}

      <Card className="cardBanner itineraryCard">
        <Card.Content>
          <h4 className="title">Itinerary</h4>
          {groundTransport.length > 0
            ? groundTransport.map((transport, key) => {
                const accordionName = transport.agency
                  ? transport.agency.name
                  : "Ground Transportation";
                pushFboObject(transport.locationType);
                return transport.locationType === "origin" ? (
                  <div key={key}>
                    <div
                      role="button"
                      onClick={() =>
                        props.openAccordionFlightDetail({
                          accordionName: accordionName + "_" + key,
                        })
                      }
                      className={
                        props.accordionActive[accordionName + "_" + key]
                          ? "open"
                          : ""
                      }
                    >
                      <Grid
                        columns={2}
                        divided="vertically"
                        className="card-ui accordionTitle"
                      >
                        <Grid.Row className="gridRow">
                          <Grid.Column width={1}>
                            <span className="iCon car"></span>
                          </Grid.Column>
                          <Grid.Column
                            width={15}
                            className="itineraryDesc noPaddingLeft"
                          >
                            <Card.Header>
                              {"To " + groupFBO[transport.locationType].name}
                            </Card.Header>
                            <Card.Meta>
                              <span className="desc">{accordionName}</span>
                              <span className="carName">
                                {transport.vehicleType}
                              </span>
                            </Card.Meta>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                    <div
                      className="accordionContent"
                      style={
                        props.accordionActive[accordionName + "_" + key]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {transportDetail(transport.locationType, transport)}
                    </div>
                  </div>
                ) : null;
              })
            : null}
          {pushFboObject("origin") ? (
            <div>
              <div
                role="button"
                onClick={() =>
                  props.openAccordionFlightDetail({
                    accordionName: "accordion2",
                  })
                }
                className={props.accordionActive["accordion2"] ? "open" : ""}
              >
                <Grid
                  columns={2}
                  divided="vertically"
                  className="card-ui accordionTitle"
                >
                  <Grid.Row className="gridRow">
                    <Grid.Column width={1}>
                      <span className="iCon depart"></span>
                    </Grid.Column>
                    <Grid.Column
                      width={15}
                      className="itineraryDesc noPaddingLeft"
                    >
                      <Card.Header>Depart</Card.Header>
                      <Card.Meta>
                        <span className="desc">{leg.originAirportName}</span>
                        <span className="carName">
                          {groupFBO["origin"].name}
                        </span>
                      </Card.Meta>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div
                className="accordionContent"
                style={
                  props.accordionActive["accordion2"]
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {airportFBODetail("origin")}
              </div>
            </div>
          ) : null}

          {cateringDetail()}

          {pushFboObject("destination") ? (
            <div>
              <div
                role="button"
                onClick={() =>
                  props.openAccordionFlightDetail({
                    accordionName: "accordion4",
                  })
                }
                className={props.accordionActive["accordion4"] ? "open" : ""}
              >
                <Grid
                  columns={2}
                  divided="vertically"
                  className="card-ui accordionTitle"
                >
                  <Grid.Row className="gridRow">
                    <Grid.Column width={1}>
                      <span className="iCon depart"></span>
                    </Grid.Column>
                    <Grid.Column
                      width={15}
                      className="itineraryDesc noPaddingLeft"
                    >
                      <Card.Header>Arrive</Card.Header>
                      <Card.Meta>
                        <span className="desc">
                          {leg.destinationAirportName}
                        </span>
                        <span className="carName">
                          {groupFBO["destination"].name}
                        </span>
                      </Card.Meta>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div
                className="accordionContent"
                style={
                  props.accordionActive["accordion4"]
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {airportFBODetail("destination")}
              </div>
            </div>
          ) : null}
          {groundTransport.length > 0
            ? groundTransport.map((transport, key) => {
                const accordionName = transport.agency
                  ? transport.agency.name
                  : "Ground Transportation";
                pushFboObject(transport.locationType);
                return transport.locationType === "destination" ? (
                  <div key={key}>
                    <div
                      role="button"
                      onClick={() =>
                        props.openAccordionFlightDetail({
                          accordionName: accordionName + "_" + key,
                        })
                      }
                      className={
                        props.accordionActive[accordionName + "_" + key]
                          ? "open"
                          : ""
                      }
                    >
                      <Grid
                        columns={2}
                        divided="vertically"
                        className="card-ui accordionTitle"
                      >
                        <Grid.Row className="gridRow">
                          <Grid.Column width={1}>
                            <span className="iCon car"></span>
                          </Grid.Column>
                          <Grid.Column
                            width={15}
                            className="itineraryDesc noPaddingLeft"
                          >
                            <Card.Header>
                              {"From " + groupFBO[transport.locationType].name}
                            </Card.Header>
                            <Card.Meta>
                              <span className="desc">{accordionName}</span>
                              <span className="carName">
                                {transport.vehicleType}
                              </span>
                            </Card.Meta>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                    <div
                      className="accordionContent"
                      style={
                        props.accordionActive[accordionName + "_" + key]
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {transportDetail(transport.locationType, transport)}
                    </div>
                  </div>
                ) : null;
              })
            : null}
        </Card.Content>
      </Card>

      {passengersDetail()}

      {isDisplayAirCraft ? (
        <Card className="cardBanner itineraryCard airCard">
          <Card.Content className="">
            <h4 className="title">
              {aircraftName} <br /> <span>{aircraftTail}</span>
            </h4>
            <ul className="gallery">
              {props.aircraftImages.length > 0
                ? props.aircraftImages.slice(0, 5).map((img, key) => {
                    return (
                      <li
                        key={key}
                        onClick={() =>
                          props.openLightBox({
                            photoIndex: key,
                            lightBoxOpen: true,
                            lightBoxType: "aircraftImages",
                          })
                        }
                      >
                        <Image src={img} />
                      </li>
                    );
                  })
                : null}
            </ul>
            {props.lightBoxOpen && props.lightBoxType === "crewImages" ? (
              // <Lightbox
              //   mainSrc={crewImages[props.photoIndex]}
              //   onCloseRequest={() =>
              //     props.openLightBox({
              //       photoIndex: 0,
              //       lightBoxOpen: false,
              //       lightBoxType: "",
              //     })
              //   }
              //   onMovePrevRequest={() =>
              //     props.openLightBox({
              //       photoIndex:
              //         (props.photoIndex + crewImages.length - 1) %
              //         crewImages.length,
              //       lightBoxOpen: true,
              //       lightBoxType: "crewImages",
              //     })
              //   }
              //   onMoveNextRequest={() =>
              //     props.openLightBox({
              //       photoIndex: (props.photoIndex + 1) % crewImages.length,
              //       lightBoxOpen: true,
              //       lightBoxType: "crewImages",
              //     })
              //   }
              // />
                <div>test</div>
            ) : null}
            {flight.crew.length > 0
              ? flight.crew.map((pilot, key) => {

                  return (
                    <div key={key}>
                      <Grid
                        columns={2}
                        divided="vertically"
                        className="avatarGrid accordionTitle"
                      >
                        <Grid.Row className="gridRow">
                          <Grid.Column width={2} className="noPaddingRight">
                            <div
                              className="circularForAvatar"
                              onClick={() =>
                                props.openLightBox({
                                  photoIndex: key,
                                  lightBoxOpen: true,
                                  lightBoxType: "crewImages",
                                })
                              }
                            >
                              <Image src={pilot.asyncImage} />
                              {/*{pilot.mainPhotoUrls.length > 0 ? (*/}
                              {/*  <Image src={pilot.mainPhotoUrls[0]} />*/}
                              {/*) : (*/}
                              {/*  <Image src={iconAvatar} />*/}
                              {/*)}*/}
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={14}
                            verticalAlign="middle"
                            className="itineraryDesc noPaddingLeft"
                          >
                            <div
                              onClick={() =>
                                props.openAccordionFlightDetail({
                                  accordionName: pilot.displayName,
                                })
                              }
                              className={
                                props.accordionActive[pilot.displayName]
                                  ? "open"
                                  : ""
                              }
                            >
                              <Card.Header>
                                {pilot.displayName}
                                <span>{pilot.capacityType.replace("Pilot In Command", "Captain").replace("Second In Command", "First Officer")}</span>
                              </Card.Header>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <div
                        className="accordionContent"
                        style={
                          props.accordionActive[pilot.displayName]
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <Grid
                          columns={2}
                          divided="vertically"
                          className="card-ui"
                        >
                          <Grid.Row className="gridRow">
                            {pilot.telephone.length > 0
                              ? pilot.telephone.map((phone, key) => {
                                  return phone.type === "Phone - Business" ? (
                                    <Grid.Column width={7} key={key}>
                                      <Card.Header>
                                        Phone - Business
                                      </Card.Header>
                                      <Card.Meta>
                                        <span className="desc">
                                          <a
                                            key={key}
                                            className="link"
                                            href={"tel:" + phone.value}
                                          >
                                            {phone.value}
                                          </a>
                                        </span>
                                      </Card.Meta>
                                    </Grid.Column>
                                  ) : null;
                                })
                              : null}

                            {pilot.email.length > 0
                              ? pilot.email.map((mail, key) => {
                                  return mail.type === "Email - Business" ? (
                                    <Grid.Column width={7} key={key}>
                                      <Card.Header>Email</Card.Header>
                                      <Card.Meta>
                                        <span className="desc">
                                          <a
                                            className="link"
                                            href={
                                              "mailto:" +
                                              mail.value +
                                              "?subject=Information Request from " +
                                              leg.trip.accountName +
                                              " on Trip # " +
                                              leg.trip.tripNumber +
                                              " leaving on " +
                                              moment
                                                .utc(leg.departureTime)
                                                .format("MM/DD/YYYY") +
                                              " at " +
                                              moment
                                                .utc(leg.departureTime)
                                                .format("hh:mm A")
                                            }
                                          >
                                            {mail.value}
                                          </a>
                                        </span>
                                      </Card.Meta>
                                    </Grid.Column>
                                  ) : null;
                                })
                              : null}
                          </Grid.Row>
                        </Grid>
                      </div>
                    </div>
                  );
                })
              : null}
          </Card.Content>
        </Card>
      ) : null}
    </div>
  );
};

export default FlightDetail;
