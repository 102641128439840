import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import { EDITED_ASTRO_IDS_TO_ADD } from "../actions/types";

const defaultFieldEditState = {
  status: "empty",
  value: "",
};

const validIdsRegex = /^(([0-9]+)\s*[,]?\s*)+$/;
const edit = handleAction(
  EDITED_ASTRO_IDS_TO_ADD,
  {
    next(state, action) {
      const newValue = action.payload;
      let status = "empty";

      if (newValue.length > 0) {
        status = validIdsRegex.test(newValue) ? "valid" : "invalid";
      }
      return { value: newValue, status };
    },
  },
  defaultFieldEditState
);

export default combineReducers({ edit });
