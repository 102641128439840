import icon from "images/airshare_white_stacked.svg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNetworkState } from "react-use";
import { paths } from "routes";
import { Loader } from "semantic-ui-react";

function AppLoading() {
  return (
    <div className="firebase-ui login-page">
      <div className="inner">
        <div className="logoMain">
          <img src={icon} alt="" />
        </div>
        <Loader active inline="centered" inverted className="ui-loader" />
        <div id="firebaseui-auth-container" />
      </div>
    </div>
  );
}

export const AppLoader = ({ children }) => {
  const history = useNavigate();
  // User will have 2 status: pending and authorized
  const loggedInUser = useSelector((state) => state.services.easUsers.loggedIn);
  const signUpResult = useSelector((state) => state.services.signUp.result);
  const accountsLoading = useSelector(
    (state) => !!state.services.easUsers.loggedInAccounts.accounts
  );
  const loggedIn = useSelector(
    (state) => state.services.firebase.firebaseUser.loggedIn
  );
  const { online: isNetorkAvailable } = useNetworkState();

  const online = useSelector((state) => state.network.online);
  const easUserExists = loggedInUser.exists;

  useEffect(() => { 
    if (loggedIn !== "no") {
      if (easUserExists === "no") {
        history(paths.build.createEasAccount());
      }
    }
  }, [history, easUserExists, loggedIn]);

  if (isNetorkAvailable === undefined && online === "unknown") {
    return <AppLoading />;
  }


  if ((!isNetorkAvailable && navigator.userAgent.indexOf("Firefox") === -1) || online === "no") {
    return (
      <p>
        We're sorry, you are either offline or we couldn't connect to Google
        database.
      </p>
    );
  }

  // TODO: simplify this loader logic
  if (
    (easUserExists === "unknown" || accountsLoading) &&
    !signUpResult.loading &&
    loggedIn !== "no"
  ) {
    return <AppLoading />;
  }

  return <>{children}</>;
};
