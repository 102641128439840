import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  closeGiveFeedback,
  closeUserResetPassword,
} from "store/actions/ui/popups";
import ConfirmSendResetPassword from "./ConfirmSendResetPassword";
import AuthorizeUserPopup from "./AuthorizeUserPopup";
import FeedBackForm from "./FeedBackForm";
import UserStatusPopup from "./UserStatusPopup";

const toSafeString = (input) => (input ? input : "");

const Popups = ({
  authorizeUser,
  userStatus,
  resetPasswordStatus,
  emailAddress,
  closeResetPopup,
  isOpenFeedback,
  closeGiveFeedback,
}) => {
  if (authorizeUser) {
    return <AuthorizeUserPopup />;
  } else if (userStatus) {
    return <UserStatusPopup />;
  } else if (resetPasswordStatus) {
    return (
      <ConfirmSendResetPassword
        emailAddress={emailAddress}
        closeResetPopup={closeResetPopup}
      />
    );
  } else if (isOpenFeedback) {
    return <FeedBackForm closeGiveFeedback={closeGiveFeedback} />;
  }
  return null;
};

Popups.propTypes = { authorizeUser: PropTypes.string };

const mapStateToProps = (state) => {
  const easeUser = state.services.easUsers.current.user;
  const emailAddress = easeUser ? toSafeString(easeUser.emailAddress) : null;

  return {
    authorizeUser: state.ui.popups.authorizeUserId,
    userStatus: state.ui.popups.userStatus,
    resetPasswordStatus: state.ui.popups.resetPasswordStatus,
    isOpenFeedback: state.ui.popups.isOpenFeedback,
    emailAddress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeResetPopup: () => dispatch(closeUserResetPassword()),
  closeGiveFeedback: () => dispatch(closeGiveFeedback()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
