import dotEnv from "environment-config";
import { createAction } from "redux-actions";
import { v1 } from "uuid";
import { GET_USER_FLIGHT_SURVEYS, PUT_FLIGHT_SURVEY } from "../types";

const flightSurveysUrl = (userId) =>
  `${dotEnv.apiUrl}/users/${userId}/flightSurveys/`;
const putFlightSurveyUrl = (surveyId) =>
  `${dotEnv.apiUrl}/flightSurveys/${surveyId}`;

export const getUserFlightSurveys = createAction(GET_USER_FLIGHT_SURVEYS);
export const putFlightSurvey = createAction(PUT_FLIGHT_SURVEY);

export const execGetUserFlightSurveys = (userId) => (dispatch, getState) => {
  const url = flightSurveysUrl(userId);
  const obj = {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  dispatch(getUserFlightSurveys());
  return fetch(url, obj)
    .then((response) => {
      if (response.ok) {
        return response.json().then((result) => {
          dispatch(getUserFlightSurveys(result.flightSurveys));
          return result;
        });
      }
      return Promise.reject(new Error("Error getting flight surveys"));
    })
    .catch((error) => {
      dispatch(getUserFlightSurveys(error));
      return Promise.reject(error);
    });
};

export const execPutFlightSurvey = (initialSurveyData) => (
  dispatch,
  getState
) => {
  const url = putFlightSurveyUrl(v1().toUpperCase());
  const obj = {
    method: "PUT",
    body: JSON.stringify(initialSurveyData),
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  dispatch(putFlightSurvey());
  return fetch(url, obj)
    .then((response) => {
      if (response.ok) {
        dispatch(
          putFlightSurvey({
            result: response.ok,
            tripLegId: initialSurveyData.tripLegId,
          })
        );
        return Promise.resolve();
      }
      return Promise.reject(new Error("Error putting flight survey"));
    })
    .catch((error) => {
      dispatch(putFlightSurvey(error));
      return Promise.reject(error);
    });
};
