import ContentFrame from "components/ContentFrame/ContentFrame";
import iconAccountCircle from "images/ic_account_circle.png";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Route,  RouteProps } from "react-router";
import { Link } from "react-router-dom";
import { paths } from "routes";
import AuthorizedRoute from "./AuthorizedRoute";

function NoPermissions() {
  return (
    <ContentFrame>
      <div className="pending-screen">
        <div className="verify-block">
          <img src={iconAccountCircle} alt="" />
          <b>Ooops</b>
          <p>
            It seems you don&#x27;t have access to this page.{" "}
            <Link to={paths.build.root()}>Airshare Home</Link>
          </p>
        </div>
      </div>
    </ContentFrame>
  );
}

type AppState = any;

function AdminRoute({ children }: { children: JSX.Element }) {
    const hasAdminAccess = useSelector(
      (state: AppState) => state.services.easUsers.loggedIn.user?.isEmployee
    );

  return hasAdminAccess ? children : <AuthorizedRoute><NoPermissions /></AuthorizedRoute>;
}


export default AdminRoute;
