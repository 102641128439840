import { createAction } from "redux-actions";
import { execPutTripRequest } from "../services/eas-book-trip";
import {
  ACCORDION_TRIP_REQUEST,
  ADD_OR_REMOVE_PASSENGER,
  ADD_OR_REMOVE_TRIP_LEG,
  NEW_BOOK_TRIP_FIELD_VALUES,
  NEW_PASSENGER_FIELD_VALUES,
  NEXT_STEP_TRIP_REQUEST,
  PREVIOUS_STEP_TRIP_REQUEST,
  RESET_STEP_TRIP_REQUEST,
} from "../types";
import { wrapAsyncThunk } from "./utilities";

export const newBookTripFieldValues = createAction(NEW_BOOK_TRIP_FIELD_VALUES);
export const addOrRemoveTripLeg = createAction(ADD_OR_REMOVE_TRIP_LEG);
export const addOrRemovePassenger = createAction(ADD_OR_REMOVE_PASSENGER);
export const newPassengerFieldValues = createAction(NEW_PASSENGER_FIELD_VALUES);
export const nextStep = createAction(NEXT_STEP_TRIP_REQUEST);
export const previousStep = createAction(PREVIOUS_STEP_TRIP_REQUEST);
export const accordionTripQuest = createAction(ACCORDION_TRIP_REQUEST);
export const resetTripStep = createAction(RESET_STEP_TRIP_REQUEST);

export const execShadedPutTripRequest = (initialTripRequestData) => {
  return wrapAsyncThunk(execPutTripRequest(initialTripRequestData), {
    name: "exec-shade-put-trip-request",
  });
};
