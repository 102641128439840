import envConfig from "environment-config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const app = initializeApp(envConfig);

export const fireBaseStorage = getStorage(app);
export const firebaseAuth = getAuth(app);

export default app;
