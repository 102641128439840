import { analyticsEvent } from "analytics/gtag";
import WorkingShade from "components/WorkingShade";
import moment from "moment";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { Button, Radio, TextArea } from "semantic-ui-react";
import {
  closeConfirmedModal,
  execShadedPutFlightSurvey,
  newSurveyFieldValues,
} from "store/actions/ui/eas-flight-surveys";
import { closeGiveFeedback } from "store/actions/ui/popups";

class FeedBackForm extends Component {
  componentDidMount() {
    this.props.newSurveyFieldValues({
      ratingText: this.props.ratingText,
      reviewText: this.props.reviewText,
    });
  }
  closeFeedback() {
    this.props.closeGiveFeedback();
  }

  putNewFlightSurvey() {
    return () => {
      const initialSurveyData = {
        tripNumber: this.props.tripNumber,
        tripLegId: this.props.tripLegId,
        ratingValue: this.props.ratingText === "Positive" ? 10 : 1,
        lastName: this.props.lastName,
        reviewText: this.props.reviewText,
        firstName: this.props.firstName,
        timestamp: moment.utc().format(),
        ratingText: this.props.ratingText,
      };
      this.props.putFlightSurvey(initialSurveyData);
      analyticsEvent("Survey_Submission", {
        event_category: "survey",
        event_label: "User successfully submits a flight survey.",
      });
    };
  }

  render() {
    return (
      <section className="popup feedback-popup">
        {this.props.isOpeningConfirmedModal ? (
          <WorkingShade shaded={this.props.isOpeningConfirmedModal} />
        ) : null}
        <div className="bg_overlay"></div>
        <div className="inner">
          <div className="content">
            <h2 className="title">Feedback</h2>
            <div className="feedbackBox">
              <h4>How was your flight?</h4>
              <div className="flightCity">
                <span>{this.props.fromCity}</span>
                <i className="flight-icon"></i>
                <span>{this.props.toCity}</span>
              </div>
              <div className="thumbBox">
                <div className="md-radio">
                  <Radio
                    className="thumbIcon thumbup"
                    label=""
                    name="thumb"
                    checked={this.props.ratingText === "Positive"}
                    value="1"
                    onChange={(event) => {
                      this.props.newSurveyFieldValues({
                        ratingText: "Positive",
                      });
                    }}
                  />
                </div>
                <div className="md-radio">
                  <Radio
                    className="thumbIcon thumbdown"
                    label=""
                    checked={this.props.ratingText === "Negative"}
                    name="thumb"
                    value="2"
                    onChange={(event) => {
                      this.props.newSurveyFieldValues({
                        ratingText: "Negative",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="additionalBox">
              <p>Additional Feedback</p>
              <TextArea
                onChange={(event) => {
                  this.props.newSurveyFieldValues({
                    reviewText: event.target.value,
                  });
                }}
                placeholder="Airshare appreciates your honest feedback."
              />
            </div>
          </div>
          <div className="footer">
            <Button
              className="cancel-button"
              onClick={() => {
                this.closeFeedback();
                analyticsEvent("Survey_Cancel", {
                  event_category: "survey",
                  event_label:
                    "User selects to Cancel the survey submission process.",
                });
              }}
            >
              Cancel
            </Button>
            <Button
              className="submit-button"
              onClick={this.putNewFlightSurvey()}
              disabled={
                !this.props.valid.ratingText || !this.props.valid.reviewText
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

FeedBackForm.propTypes = {
  valid: PropTypes.object.isRequired,
  ratingText: PropTypes.string.isRequired,
  reviewText: PropTypes.string.isRequired,
  newSurveyFieldValues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    firstName: state.services.easUsers.current.user.firstName,
    lastName: state.services.easUsers.current.user.lastName,
    ratingText: state.ui.flightSurveyForm.fields.values.ratingText,
    reviewText: state.ui.flightSurveyForm.fields.values.reviewText,
    fromCity: state.ui.popups.feedbackForm.fromCity,
    toCity: state.ui.popups.feedbackForm.toCity,
    tripNumber: state.ui.popups.feedbackForm.tripNumber,
    tripLegId: state.ui.popups.feedbackForm.tripLegId,
    valid: state.ui.flightSurveyForm.fields.valid,
    isOpeningConfirmedModal: state.services.easFlightSurveys.surveys.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  putFlightSurvey: (initialSurveyData) => {
    dispatch(execShadedPutFlightSurvey(initialSurveyData)).then(() => {
      dispatch(closeGiveFeedback());
      dispatch(newSurveyFieldValues({ ratingText: "", reviewText: "" }));
      dispatch(closeConfirmedModal(true));
    });
  },
  newSurveyFieldValues: (fieldAndValue) => {
    dispatch(newSurveyFieldValues(fieldAndValue));
  },
  closeGiveFeedback: () => {
    dispatch(closeGiveFeedback());
    dispatch(newSurveyFieldValues({ ratingText: "", reviewText: "" }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedBackForm);
