import PropTypes from "prop-types";

const AstroAccount = ({ astroUserId, accounts }) => (
  <div className="listAcount">
    <div className="item">
      <div className="userID">User ID: {astroUserId}</div>
      <div className="Account">
        {accounts.map((account) => (
          <div className="listItem" key={account.accountId}>
            <span className="name">
              Account: <b>{account.accountId}</b>, {account.accountName}
            </span>
            <span className="role">{account.role}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

/*We once had multiple entries that coule be removed, now we only have one so we don't need this
        button. Right now (Apr-26-2017) we are planning to go back to multiple. So, we may need te button*/
/*<div className="remove" onClick={revertProvisional}>REMOVE</div>*/

AstroAccount.propTypes = {
  astroUserId: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
};

export default AstroAccount;
