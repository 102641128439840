import ContentFrame from "components/ContentFrame/ContentFrame";
import UserRow from "components/UserRow";
import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { paths } from "routes";
import withRouter from "components/withRouter";

import {
  execShadedSearchUsers,
  execShadedUpdateUserStatus,
} from "store/actions/ui/eas-users";
import {
  popUserAuthorization,
  popUserStatusChange,
} from "store/actions/ui/popups";

const USER_STATUS = {
  pending: "Reject",
  partial: "Authorized",
  authorized: "Deactivate",
  rejected: "Authorize",
  deactivated: "Authorize",
};

class UserSearch extends Component {
  execUserSearch(searchTerm) {
    //For now we're just not searching if the string is empty. This might not be the best long term behavior
    if (searchTerm && searchTerm.length !== 0) {
      this.props.searchUsers(`name=${searchTerm}`);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.searchTerm !== prevProps.searchTerm) {
      this.execUserSearch(this.props.searchTerm);
    }
  }

  componentDidMount() {
    this.execUserSearch(this.props.searchTerm);
  }

  newTerm = (newValue) => {
    this.props.router.navigate(
      paths.build.userSearch(encodeURIComponent(newValue))
    );
  };

  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.newTerm(this.refs.query.value);
    }
  }

  updateSearch() {
    return () => {
      this.newTerm(this.refs.query.value);
    };
  }

  render() {
    return (
      <ContentFrame>
        <div className="page-user user-search">
          <section className="showUser">
            <div className="displayuser">
              <div className="head ">
                <p>Search Results</p>
                <div className="searchItem">
                  <input
                    className="app_input"
                    onKeyPress={this.handleKeyPress.bind(this)}
                    defaultValue={
                      this.props.searchTerm ? this.props.searchTerm : ""
                    }
                    ref="query"
                    type="search"
                    placeholder="Search for a person"
                  />
                  <button
                    onClick={this.updateSearch()}
                    className="buttonStyle search"
                  >
                    SEARCH
                  </button>
                </div>
                <div>
                  {this.props.users && this.props.users.length !== 0 ? (
                    <p className="textResult">
                      Results for{" "}
                      <span>&#x22;{this.props.searchTerm}&#x22;</span>
                    </p>
                  ) : null}
                  {this.props.users &&
                  this.props.users &&
                  this.props.users.length === 0 ? (
                    <p className="textResult">
                      No results were found for{" "}
                      <span>&#x22;{this.props.searchTerm}&#x22;</span>
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="content">
                <div className="Heading">
                  <div className="Cell">
                    <p>Name</p>
                  </div>
                  <div className="Cell">
                    <p>Email</p>
                  </div>
                  <div className="Cell">
                    <p>Organization</p>
                  </div>
                  <div className="Cell">
                    <p>Email Verified?</p>
                  </div>
                  <div className="Cell">
                    <p>Account Status</p>
                  </div>
                  <div className="Cell">
                    <p></p>
                  </div>
                </div>
                {this.props.users
                  ? this.props.users.map((item, index) => {
                      const currentStatus = item.status.toLowerCase();

                      return (
                        <UserRow
                          user={item}
                          manageUser={() => 
                            this.props.router.navigate(
                              paths.build.userDetails(
                                item.systemUserId,
                                "details"
                              )
                            )
                          }
                          popAuthorize={this.props.popAuthorize(
                            item.systemUserId
                          )}
                          authorizeUserStatus={this.props.authorizeUserStatus(
                            item.systemUserId,
                            USER_STATUS[currentStatus],
                            this.props.searchTerm
                          )}
                          popReject={this.props.popUserStatus({
                            userId: item.systemUserId,
                            existingStatus: item.status,
                            newStatus: USER_STATUS[currentStatus],
                          })}
                          key={index}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          </section>
        </div>
      </ContentFrame>
    );
  }
}

UserSearch.contextTypes = { router: PropTypes.object };

UserSearch.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  searchUsers: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  popAuthorize: PropTypes.func.isRequired,
  popUserStatus: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
  users: state.services.easUsers.all.list,
  loading: state.services.easUsers.all.loading,
  failed: state.services.easUsers.all.failed,
  userId: state.services.firebase.firebaseUser.response.uid,
  searchTerm: ownProps.router.params.encodedSearchString,
});

const mapDispatchToProps = (dispatch) => ({
  searchUsers: (searchString) => dispatch(execShadedSearchUsers(searchString)),
  popAuthorize: (userId) => () => {
    dispatch(popUserAuthorization(userId));
  },
  popUserStatus: (userStatus) => () => {
    dispatch(popUserStatusChange(userStatus));
  },
  authorizeUserStatus: (currentUserId, userStatus, searchTerm) => () => {
    dispatch(execShadedUpdateUserStatus(currentUserId, userStatus)).then(() => {
      dispatch(execShadedSearchUsers(`name=${searchTerm}`));
    });
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSearch)
);
