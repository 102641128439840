import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, RouteProps } from "react-router";
import { paths } from "routes";

type AppState = any;

function FlightsRoute({ children }: { children: JSX.Element }) {
    const allAccounts = useSelector(
        (state : AppState) => state.services.easUsers.loggedInAccounts.list
    );
    const isBooker =
        allAccounts &&
        allAccounts.some(
            (account : any) => account.role.toLowerCase() === "booker"
        );

    return (
        <Navigate
            to={paths.build.flightsMineOrAll(isBooker ? "all" : "mine")}
        />
    );
}

export default FlightsRoute;
