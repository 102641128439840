import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  GET_USER_FLIGHT_SURVEYS,
  PUT_FLIGHT_SURVEY,
} from "../../actions/types";

const flightSurveyDefault = {
  loading: false,
  failed: false,
  list: [],
  maximumHistoryPages: 2,
};
const putFlightSurveyDefault = { body: "", loading: true, surveyLegIds: [] };
let setSurveyLegIds = [];
//noinspection JSUnusedLocalSymbols
const surveys = handleActions(
  {
    [GET_USER_FLIGHT_SURVEYS]: {
      next(state, action) {
        if (action.payload) {
          return {
            loading: false,
            failed: false,
            list: action.payload,
            maximumHistoryPages: action.payload.maximumHistoryPages,
          };
        }
        return { loading: true, failed: state.failed, list: [] };
      },
      throw(state, action) {
        console.log(`Error fetching flight surveys: ${action.error}`);
        return { loading: false, failed: false, list: null };
      },
    },
    [PUT_FLIGHT_SURVEY]: {
      next(state, action) {
        if (action.payload) {
          setSurveyLegIds.push(action.payload.tripLegId);
          return {
            body: action.payload.result,
            loading: false,
            surveyLegIds: setSurveyLegIds,
            tripLegId: action.payload.tripLegId,
          };
        }
        return putFlightSurveyDefault;
      },
      throw(state, action) {
        console.log(`Error putting flight survey: ${action.error}`);
        return putFlightSurveyDefault;
      },
    },
  },
  flightSurveyDefault
);

export default combineReducers({ surveys });
