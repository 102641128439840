import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { PUT_TRIP_REQUEST } from "../../actions/types";

const putTripRequestDefault = { body: "", loading: true };
//noinspection JSUnusedLocalSymbols
const tripRequest = handleActions(
  {
    [PUT_TRIP_REQUEST]: {
      next(state, action) {
        if (action.payload) {
          return { body: action.payload, loading: false };
        }
        return putTripRequestDefault;
      },
      throw(state, action) {
        console.log(`Error putting trip request: ${action.error}`);
        return putTripRequestDefault;
      },
    },
  },
  putTripRequestDefault
);

export default combineReducers({ tripRequest });
