import clone from "clone";
import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import {
  CLOSE_CONFIRMED_MODAL,
  NEW_SURVEY_FIELD_VALUES,
} from "../../actions/types";

const defaultSurveyState = {
  values: {
    ratingText: "",
    reviewText: "",
  },
  valid: {
    ratingText: false,
    reviewText: false,
  },
};

const defaultConfirmedModal = { isConfirmedModal: true };

const fields = handleAction(
  NEW_SURVEY_FIELD_VALUES,
  {
    next(state, action) {
      const newValues = clone(state.values);
      Object.assign(newValues, action.payload);
      const valid = clone(state.valid);
      Object.assign(valid, {
        ratingText: Boolean(newValues.ratingText).valueOf(),
        reviewText: Boolean(newValues.reviewText).valueOf(),
      });
      return { values: newValues, valid };
    },
  },
  defaultSurveyState
);

const valid = handleAction(
  NEW_SURVEY_FIELD_VALUES,
  {
    next(state, action) {
      const newState = clone(state);

      Object.assign(newState, action.payload);
      return newState;
    },
  },
  defaultSurveyState
);

const confirmedModal = handleAction(
  CLOSE_CONFIRMED_MODAL,
  {
    next(state, action) {
      const newState = clone(state);
      console.log(action);
      newState.isConfirmedModal = action.payload;
      return newState;
    },
  },
  defaultConfirmedModal
);

export default combineReducers({ fields, valid, confirmedModal });
