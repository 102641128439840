import clone from "clone";
import { combineReducers } from "redux";
import { handleAction, handleActions } from "redux-actions";
import { v4 } from "uuid";
import {
  GET_ACCOUNTS_FOR_LOGGED_IN_USER,
  GET_LOGGED_IN_USER,
  GET_USER,
  GET_USERS,
  SEARCH_USERS,
} from "../../actions/types";

const usersDefaultState = {
  loading: false,
  failed: false,
  list: null,
  searchTerm: "",
};

const all = handleActions(
  {
    [GET_USERS]: {
      next(state, action) {
        if (action.payload) {
          return {
            loading: false,
            failed: false,
            list: action.payload,
            searchTerm: "",
          };
        }
        return {
          loading: true,
          failed: state.failed,
          list: state.users,
          searchTerm: "",
        };
      },
      throw(state, action) {
        console.log(`Error fetching users: ${action.error}`);
        return { loading: false, failed: true, list: state.users };
      },
    },
    [SEARCH_USERS]: {
      next(state, action) {
        if (typeof action.payload === "string") {
          return {
            loading: true,
            failed: state.failed,
            list: state.users,
            searchTerm: action.payload,
          };
        } else if (action.payload) {
          return {
            loading: false,
            failed: false,
            list: action.payload,
            searchTerm: state.searchTerm,
          };
        }
        console.warn("Got user search action with no payload");
        return state;
      },
      throw(state, action) {
        console.log(`Error fetching users: ${action.error}`);
        return { loading: false, failed: true, list: state.users };
      },
    },
  },
  usersDefaultState
);

const userDefaultState = { loading: false, failed: false, user: null };

//noinspection JSUnusedLocalSymbols
const current = handleActions(
  {
    [GET_USER]: {
      next(state, action) {
        if (typeof action.payload === "string") {
          //Our payload is the new user's email
          let newUser = null;
          let callId = null;
          //If we're getting the same user we already have, we keep the user. Otherwise, we throw it away

          if (state.user && state.user.systemUserId === action.payload) {
            newUser = state.user;
            callId = state.callId;
          }
          return { loading: true, failed: state.failed, user: newUser, callId };
        } else if (action.payload) {
          return {
            loading: false,
            failed: false,
            user: action.payload,
            callId: v4(),
          };
        }
        console.warn("Received GET_USER without a payload");
        return state;
      },
      throw(state, action) {
        console.log(`Error fetching user: ${action.error}`);
        return { loading: false, failed: true, user: null, callId: null };
      },
    },
  },
  userDefaultState
);

const loggedInUserDefaultState = {
  loading: false,
  failed: false,
  exists: "unknown",
  user: null,
};
const loggedIn = handleAction(
  GET_LOGGED_IN_USER,
  {
    next: (state, action) => {
      if (action.payload === false) {
        return { loading: false, failed: false, exists: "no", user: null };
      } else if (!action.payload) {
        return {
          loading: true,
          failed: state.failed,
          exists: "unknown",
          user: null,
        };
      }
      return {
        loading: false,
        failed: false,
        exists: "yes",
        user: action.payload,
      };
    },
    throw: (state, action) => ({
      loading: false,
      failed: true,
      exists: "unknown",
      user: null,
    }),
  },
  loggedInUserDefaultState
);

const loggedInUserAccountsDefaultState = {
  loading: false,
  failed: false,
  list: null,
};
/*
 * These accounts should be fetched along with the logged in user, but that part of tha API was broken. So,
 * we added this to get the accounts
 */
const loggedInAccounts = handleActions(
  {
    [GET_ACCOUNTS_FOR_LOGGED_IN_USER]: {
      next(state, action) {
        if (action.payload) {
          return { loading: false, failed: false, list: action.payload };
        } else {
          const newState = clone(state);
          newState.loading = true;
          return newState;
        }
      },
      throw(state, action) {
        console.log("Error fetching accounts: " + action.error);
        return { loading: false, failed: true, list: null };
      },
    },
  },
  loggedInUserAccountsDefaultState
);

export default combineReducers({ all, current, loggedIn, loggedInAccounts });
