import clone from "clone";
import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { GET_MORE_FLIGHTS, SEARCH_TRIPS } from "../../actions/types";
const tripsDefaultState = {
  loading: false,
  failed: false,
  list: null,
  flightStatus: "upcoming",
  isLoadMoreDone: false,
  maximumHistoryPages: 2,
};

const allTrip = handleActions(
  {
    [SEARCH_TRIPS]: {
      next(state, action) {
        if (action.payload) {
          return {
            loading: false,
            failed: false,
            list: action.payload.tripLegs,
            isLoadMoreDone: true,
            maximumHistoryPages: action.payload.maximumHistoryPages,
          };
        } else {
          return {
            loading: true,
            failed: state.failed,
            list: null,
            isLoadMoreDone: false,
          };
        }
      },
      throw(state, action) {
        console.log("Error fetching trips: " + action.error);
        return {
          loading: false,
          failed: true,
          flightStatus: "upcoming",
          list: null,
          isLoadMoreDone: false,
        };
      },
    },
    [GET_MORE_FLIGHTS]: {
      next(state, action) {
        let newState = clone(state);
        newState.isLoadMore = true;
        if (action.payload) {
          if (Object.keys(action.payload).length > 0) {
            newState.list = Object.assign(newState.list, action.payload);
          }
          newState.isLoadMore = false;
        }
        return newState;
      },
      throw(state, action) {
        console.log("Error fetching trips: " + action.error);
        return {
          loading: false,
          failed: true,
          list: null,
          isLoadMoreDone: false,
        };
      },
    },
  },
  tripsDefaultState
);

export default combineReducers({ allTrip });
