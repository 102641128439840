import { combineReducers } from "redux";
import addPassenger from "./addPassenger";
import asyncOps from "./async-ops";
import bookTrip from "./book-trip";
import firebaseLogin from "./firebase-login";
import flightSurveyForm from "./flight-surveys";
import flights from "./flights";
import header from "./header";
import itinerary from "./itinerary";
import popups from "./popups";
import resetPassword from "./reset-password";
import signUp from "./sign-up";
import steps from "./trip-steps";
import userDetails from "./user-details";

export default combineReducers({
  asyncOps,
  popups,
  signUp,
  header,
  userDetails,
  flightSurveyForm,
  flights,
  bookTrip,
  addPassenger,
  steps,
  firebaseLogin,
  itinerary,
  resetPassword,
});
