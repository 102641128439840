import ContentFrame from "components/ContentFrame/ContentFrame";
import { useParams } from "react-router";
import HeaderDetail from "./HeaderDetail";
import UserDetails from "./UserDetails";
import UserTransactions from "./UserTransactions";

const User = () => {
  const { userId, detailsOrTransactions } = useParams();

  return (
    <ContentFrame>
      <div className="page-user user-detail">
        <div className="displayuser">
          <HeaderDetail userId={userId} />
          {detailsOrTransactions === "transactions" ? (
            <UserTransactions />
          ) : (
            <UserDetails />
          )}
        </div>
      </div>
    </ContentFrame>
  );
};

export default User;
