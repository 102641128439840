import { handleActions } from "redux-actions";
import { GET_ITINERARY_FOR_TRIP } from "../../actions/types";

const itineraryFile = { astroId: 0, content: null };
export default handleActions(
  {
    [GET_ITINERARY_FOR_TRIP]: {
      next(state, action) {
        if (action.payload) {
          Object.assign(itineraryFile, {
            astroId: action.payload.astroId,
            content: action.payload.result,
          });
          return itineraryFile;
        } else {
          return { astroId: 0, content: null };
        }
      },
    },
  },
  itineraryFile
);
