import { Component } from "react";
import { connect } from "react-redux";
import { Container, Grid, Input } from "semantic-ui-react";
import { newPassengerFieldValues } from "store/actions/ui/eas-book-trip";

class PassengerRow extends Component {
  componentDidMount() {
    if (this.props.fromStep === "legs") {
      if (this.props.passengerNumber === 1 && this.props.fromStep !== "who") {
        this.props.newPassengerFieldValues({
          passengerName: this.props.passengerName,
          passengerFields: {
            name:
              this.props.loggedInUser.firstName +
              " " +
              this.props.loggedInUser.lastName,
            email: this.props.loggedInUser.emailAddress,
            default: true,
          },
        });
      }
    }
  }

  render() {
    let passValues = [],
      tmpValues = [];
    let rowPassFields = this.props.rowPassengerFields;
    if (rowPassFields[this.props.passengerName]) {
      passValues = this.props.rowPassengerFields[this.props.passengerName]
        .values;
      tmpValues = this.props.rowPassengerFields[this.props.passengerName]
        .tmpValues;
    } else {
      passValues = this.props.rowPassenger[this.props.passengerName].values;
      tmpValues = this.props.rowPassenger[this.props.passengerName].tmpValues;
    }
    const isChangedValue = tmpValues.isChangedValue;
    const passengerName =
      isChangedValue && isChangedValue["name"]
        ? tmpValues.name
        : passValues.name;
    const passengerEmail =
      isChangedValue && isChangedValue["email"]
        ? tmpValues.email
        : passValues.email;
    return (
      <Container
        className={
          "noMarginTop " + (this.props.passengerNumber === 1 ? "firstRow" : "")
        }
      >
        <Grid.Row className="passengerWidth">
          <Grid>
            <Grid.Row className="chooseAirPort" columns={2}>
              <Grid.Column>
                <div className="passNumber">
                  {" "}
                  Passenger{" "}
                  {this.props.passengerOrder === 1
                    ? 1
                    : this.props.passengerOrder}
                </div>
              </Grid.Column>
              <button
                className="button-wrapper removePass"
                onClick={() => {
                  this.props.passengerProps.addOrRemovePassenger({
                    actionType: "REMOVE",
                    passengerName: this.props.passengerName,
                  });
                }}
              ></button>
            </Grid.Row>
            <Grid.Row className="chooseAirPort" columns={2}>
              <Grid.Column>
                <span>Passenger Name</span>
                <Input
                  placeholder="Name (Optional)"
                  value={passengerName}
                  onChange={(event) => {
                    this.props.newPassengerFieldValues({
                      passengerName: this.props.passengerName,
                      field: "name",
                      value: event.target.value,
                      saveToTmp: this.props.saveToTmp,
                    });
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <span>Email for Itinerary</span>
                <Input
                  placeholder="Email (Optional)"
                  value={passengerEmail}
                  onChange={(event) => {
                    this.props.newPassengerFieldValues({
                      passengerName: this.props.passengerName,
                      field: "email",
                      value: event.target.value,
                      saveToTmp: this.props.saveToTmp,
                    });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rowPassenger: state.ui.addPassenger.passengers,
    rowPassengerFields: state.ui.addPassenger.fields,
    loggedInUser: state.services.easUsers.loggedIn.user,
    fromStep: state.ui.steps["from"],
  };
};
const mapDispatchToProps = (dispatch) => ({
  newPassengerFieldValues: (fieldAndValue) => {
    dispatch(newPassengerFieldValues(fieldAndValue));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PassengerRow);
