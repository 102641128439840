import { createAction } from "redux-actions";
import { NEW_SIGN_UP_FIELD_VALUES } from "../types";
import { execShadedCreateUser, execShadedGetLoggedInUser } from "./eas-users";
import { wrapAsyncThunk } from "./utilities";

export const newSignUpFieldValue = createAction(NEW_SIGN_UP_FIELD_VALUES);

export const execSignUp = () =>
  wrapAsyncThunk(
    (dispatch, getState) =>
      dispatch(
        execShadedCreateUser(getState().ui.signUp.fields.values)
      ).then(() => dispatch(execShadedGetLoggedInUser())),
    { name: "exec-sign-up" }
  );
