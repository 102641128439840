import { GoogleAuthProvider } from "firebase/auth";
import whiteStackedIcon from "images/airshare_white_stacked.svg";
import submitEmailIcon from "images/submit_email.png";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { paths } from "routes";
import { Button, Image, Input, Modal } from "semantic-ui-react";

import {
  excecSignInWithEmailAndPassword,
  excecSignInWithGoogleSignIn,
  firebasePasswordResetEmail,
  passwordResetEmailModal,
} from "store/actions/services/firebase-user";
import {
  execBootstrapLoggedInUser,
  execShadedFireBaseSendPasswordResetEmail,
  newFirebaseSignInFieldValue,
  newSendEmailResetPassword,
} from "store/actions/ui/eas-users";

// NOTE: We had to make this a class to support the componentDidMount lifecycle method

// Initialize the Google authentication Provider.
const provider = new GoogleAuthProvider();

class FirebaseUiWrapper extends Component {
  //noinspection JSMethodCanBeStatic
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.excecSignInWithEmailAndPassword(
        this.props.values.emailAddress,
        this.props.values.password
      );
    }
  }
  handleKeyPressForSendEmail(event) {
    if (event.key === "Enter") {
      this.props.execShadedFireBaseSendPasswordResetEmail(
        this.props.valuesSendEmail.emailAddress
      );
    }
  }

  //noinspection JSMethodCanBeStatic
  render() {
    const {
      values,
      valid,
      errorCode,
      errorMessage,
      valuesSendEmail,
      errorCodeSendEmail,
      errorMessageSendEmail,
    } = this.props;
    return (
      <div className="firebase-ui login-page">
        <div className="loginBox">
          <div className="inner">
            <div className="logoMain">
              <img src={whiteStackedIcon} alt="" />
            </div>
            <button
              className="firebaseui-idp-google firebaseui-id-idp-button"
              onClick={() => this.props.excecSignInWithGoogleSignIn(provider)}
            >
              <img
                className="firebaseui-idp-icon"
                src="https://www.gstatic.com/firebasejs/ui/0.5.0/images/auth/google.svg"
                alt=""
              />
              <span className="firebaseui-idp-text firebaseui-idp-text-long">
                Log in using Google
              </span>
            </button>
            <div className="emailPasswordAccount">
              <div className="border">
                <div className="text or">OR</div>
              </div>
              <div
                className="errorMessage"
                style={errorCode ? { display: "flex" } : { display: "none" }}
              >
                {errorMessage}
              </div>
              <form className="formInformation">
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(event) => {
                    this.props.newFirebaseSignInFieldValue({
                      emailAddress: event.target.value,
                    });
                  }}
                ></input>
                <input
                  type="Password"
                  placeholder="Password"
                  onChange={(event) => {
                    this.props.newFirebaseSignInFieldValue({
                      password: event.target.value,
                    });
                  }}
                  onKeyPress={this.handleKeyPress.bind(this)}
                ></input>
                <button
                  type="button"
                  disabled={!valid.emailAddress || !valid.password}
                  onClick={() =>
                    this.props.excecSignInWithEmailAndPassword(
                      values.emailAddress,
                      values.password
                    )
                  }
                >
                  Log in
                </button>
              </form>
              <div className="forgotPassword">
                <Modal
                  className="forgotModal"
                  size="tiny"
                  open={this.props.isOpenModal}
                  onClose={() => this.props.handleResetPassWordModal(false)}
                  trigger={
                    <button
                      onClick={() => this.props.handleResetPassWordModal(true)}
                      className="linkButton"
                    >
                      Forgot password?
                    </button>
                  }
                >
                  {errorCodeSendEmail === "success" ? (
                    <div className="boxPadding">
                      <button
                        className="btnClose"
                        onClick={() =>
                          this.props.handleResetPassWordModal(false)
                        }
                      >
                        DONE
                      </button>
                      <Modal.Header>Check Your Email</Modal.Header>
                      <Modal.Content>
                        <Image wrapped size="medium" src={submitEmailIcon} />
                        <Modal.Description>
                          <p>Weve sent you an email to</p>
                          <p>{this.props.valuesSendEmail.emailAddress}</p>
                          <p>
                            Click the link in the email to reset your password.
                          </p>
                        </Modal.Description>
                        <Button
                          className="checkEmail"
                          onClick={() =>
                            this.props.resetFireBaseResetPassword()
                          }
                        >
                          Send Email Again
                        </Button>
                      </Modal.Content>
                    </div>
                  ) : (
                    <div className="boxPadding">
                      <Modal.Header>Reset Password</Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <p>
                            Enter your email address that you used to register.
                          </p>
                          <p>
                            Well send you an email with a link to reset your
                          </p>
                          <p>password with instructions.</p>
                        </Modal.Description>
                        <div
                          className="errorMessage email"
                          style={
                            errorCodeSendEmail &&
                            errorCodeSendEmail !== "success"
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          {errorMessageSendEmail}
                        </div>
                        <Input
                          type="email"
                          placeholder="Enter email address"
                          onChange={(event) => {
                            this.props.newSendEmailResetPassword({
                              emailAddress: event.target.value,
                            });
                          }}
                          onKeyPress={this.handleKeyPressForSendEmail.bind(
                            this
                          )}
                        />
                        <Button
                          onClick={() =>
                            this.props.execShadedFireBaseSendPasswordResetEmail(
                              valuesSendEmail.emailAddress
                            )
                          }
                        >
                          Submit
                        </Button>
                      </Modal.Content>
                    </div>
                  )}
                </Modal>
              </div>
            </div>
          </div>
          <div className="text top">
            By continuing you agree to Airshare&#39;s{" "}
            <a
              target="_blank"
              href="https://flyairshare.com/privacy/"
              className="linkText"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
          </div>
          <div className="text bottom">
            Don&#39;t have an account?{" "}
            <Link className="linkText" to={paths.build.createUser()}>
              Sign up
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  excecSignInWithEmailAndPassword: (emailAddress, password) => {
    dispatch(excecSignInWithEmailAndPassword(emailAddress, password)).then(
      (result) => {
        if (result) {
          dispatch(execBootstrapLoggedInUser());
        }
      }
    );
  },
  excecSignInWithGoogleSignIn: (provider) => {
    dispatch(excecSignInWithGoogleSignIn(provider));
  },
  newFirebaseSignInFieldValue: (fieldAndValue) => {
    dispatch(newFirebaseSignInFieldValue(fieldAndValue));
  },
  newSendEmailResetPassword: (email) => {
    dispatch(newSendEmailResetPassword(email));
  },
  execShadedFireBaseSendPasswordResetEmail: (email) => {
    dispatch(execShadedFireBaseSendPasswordResetEmail(email));
  },
  resetFireBaseResetPassword: () => {
    dispatch(firebasePasswordResetEmail({ errorCode: "", errorMessage: "" }));
  },
  handleResetPassWordModal: (isOpen) => {
    dispatch(passwordResetEmailModal(isOpen));
  },
});
const mapStateToProps = (state) => {
  return {
    valid: state.ui.firebaseLogin.fields.valid,
    values: state.ui.firebaseLogin.fields.values,
    errorCode: state.services.firebase.firebaseAuthentication.errorCode,
    errorMessage: state.services.firebase.firebaseAuthentication.errorMessage,
    validSendEmail: state.ui.resetPassword.checkEmail.valid,
    valuesSendEmail: state.ui.resetPassword.checkEmail.values,
    errorCodeSendEmail: state.services.firebase.firebasePasswordReset.errorCode,
    errorMessageSendEmail:
      state.services.firebase.firebasePasswordReset.errorMessage,
    isOpenModal: state.ui.resetPassword.resetPasswordModal.isOpen,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FirebaseUiWrapper);
