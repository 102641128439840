import { createAction } from "redux-actions";
import { execGetAstroUserAccounts } from "../services/astro-users";
import {
  execSaveAstroAccountsToUser,
  execUpdateUser,
} from "../services/eas-users";
import { EDITED_ASTRO_IDS_TO_ADD } from "../types";
import { wrapAsyncThunk } from "./utilities";

export const editedAstroIdsToAdd = createAction(EDITED_ASTRO_IDS_TO_ADD);

export const execGetAccountsForCurrentIds = () =>
  wrapAsyncThunk(
    (dispatch, getState) => {
      if (getState().userAstroIdsEdit.edit.status === "valid") {
        const inputAstroUserIds = getState()
            .userAstroIdsEdit.edit.value.split(",")
            .map((notTrimed) => notTrimed.trim()),
          oneNumber = 1,
          userIdLength = inputAstroUserIds.length,
          zeroNumber = 0;

        //THIS IS A HACK FOR NOW, IF WE HAVE MORE THAN ONE ASTRO USER ID, JUST IGNORE IT
        if (userIdLength === oneNumber) {
          return dispatch(
            execGetAstroUserAccounts(inputAstroUserIds[zeroNumber])
          );
        }
      }
      return Promise.resolve(false);
    },
    { name: "exec-get-account-for-current-ids" }
  );

export const execSaveCurrentAstroUsersAccountsToEasUserAndAuthorize = (
  userId
) =>
  wrapAsyncThunk(
    (dispatch, getState) => {
      const astroAccounts = getState().services.astroUsers.userAccounts
        .accounts;
      const astroUserId = getState().services.astroUsers.userAccounts
        .astroUserId;
      return dispatch(
        execSaveAstroAccountsToUser(astroUserId, astroAccounts)
      ).then(() => dispatch(execUpdateUser(userId, { status: "Authorized" })));
    },
    { name: "save-current-astro-users-accounts" }
  );
