import PropTypes from "prop-types";

const rightTextMap = {
  pending: "Reject",
  partial: "Reject",
  authorized: "Deactivate",
  rejected: "Authorize",
  deactivated: "Activate",
};

const rightClassMap = {
  pending: "Delete",
  partial: "Delete",
  authorized: "searchUser2",
  rejected: "searchUser2",
  deactivated: "Authorize",
};

const UserRow = ({
  user,
  manageUser,
  popAuthorize,
  authorizeUserStatus,
  popReject,
}) => {
  const status = user.status.toLowerCase();

  const leftText =
    status === "pending" || status === "partial" ? "Authorize" : "Manage";

  const rightText = rightTextMap[status];

  const rowClass = status === "pending" ? "clsrowAuthorize" : "clsrowDetail";

  const leftClass = status === "pending" ? "Authorize" : "searchUser1";

  const rightClass = rightClassMap[status];

  const leftClick = (event) => {
    event.stopPropagation();
    if (status === "pending") {
      return popAuthorize();
    }
    if (status === "partial") {
      return authorizeUserStatus();
    } else {
      return manageUser();
    }
  };
  const rightClick = (event) => {
    event.stopPropagation();
    if (status === "deactivated" || status === "rejected") {
      popAuthorize();
    } else if (
      status === "pending" ||
      status === "partial" ||
      status === "authorized"
    ) {
      popReject();
    }
  };

  return (
    <div className={`row ${rowClass}`} onClick={manageUser}>
      <div className="Cell name">
        <p className="name">
          {user.firstName} {user.lastName}
        </p>
      </div>
      <div className="Cell email">
        <p>{user.emailAddress}</p>
      </div>
      <div className="Cell">
        <p>{user.userSpecifiedAccount}</p>
      </div>
      <div className="Cell">
        <p>{user.emailVerified ? "Yes" : "No"}</p>
      </div>
      <div className="Cell">
        <p>{user.status}</p>
      </div>
      <div className="Cell end">
        <div className="button-group">
          <button className={`buttonStyle ${leftClass}`} onClick={leftClick}>
            {leftText}
          </button>
          <button className={`buttonStyle ${rightClass}`} onClick={rightClick}>
            {rightText}
          </button>
        </div>
      </div>
    </div>
  );
};

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  manageUser: PropTypes.func.isRequired,
  popAuthorize: PropTypes.func.isRequired,
  popReject: PropTypes.func.isRequired,
};

export default UserRow;
