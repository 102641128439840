import clone from "clone";
import { handleActions } from "redux-actions";
import {
  CLOSE_CALL_AIRCARE,
  CLOSE_GIVE_FEEDBACK,
  CLOSE_USER_AUTHORIZATION,
  CLOSE_USER_RESET_PASSWORD,
  CLOSE_USER_STATUS_CHANGE,
  POP_CALL_AIRCARE,
  POP_GIVE_FEEDBACK,
  POP_USER_AUTHORIZATION,
  POP_USER_RESET_PASSWORD,
  POP_USER_STATUS_CHANGE,
} from "../../actions/types";

//For Tango (EAS-544): The reset password status should be in the same object as the rest.
//const popupsDefaultState = {authorizeUserId: null, userStatus: null, passwordReset:null}.
//
// The way we have this written now. Showing one popup completely hides another. That is probably ok for now but
// this logic will probably get more complicated over time. We may need a confirm popup that replaces a form popup
// but if the confirm is cancelled, the form popup needs to come back.

const popupsDefaultState = {
  authorizeUserId: null,
  userStatus: null,
  resetPasswordStatus: false,
  isCallAirCare: false,
  isOpenFeedback: false,
};

//noinspection JSUnusedLocalSymbols
export default handleActions(
  {
    [POP_USER_AUTHORIZATION]: {
      next(state, action) {
        if (action.payload) {
          return { authorizeUserId: action.payload, userStatus: null };
        }
        console.warn("Popped authorization without a payload");
        return popupsDefaultState;
      },
    },
    [CLOSE_USER_AUTHORIZATION]: {
      next(state, action) {
        return { authorizeUserId: null };
      },
    },
    [POP_USER_STATUS_CHANGE]: {
      next(state, action) {
        if (action.payload) {
          return { authorizeUserId: null, userStatus: action.payload };
        }
        console.warn("Popped user status without a payload");
        return popupsDefaultState;
      },
    },
    [CLOSE_USER_STATUS_CHANGE]: {
      next(state, action) {
        return { userStatus: null };
      },
    },
    [POP_USER_RESET_PASSWORD]: {
      next(state, action) {
        const newState = clone(state);

        newState.resetPasswordStatus = true;
        return newState;
      },
      throw(state, action) {
        return popupsDefaultState;
      },
    },
    [CLOSE_USER_RESET_PASSWORD]: {
      next(state, action) {
        const newState = clone(state);

        newState.resetPasswordStatus = false;
        return newState;
      },
    },
    [POP_CALL_AIRCARE]: {
      next(state, action) {
        let newState = clone(state);
        newState.isCallAirCare = true;
        return newState;
      },
      throw(state, action) {
        return popupsDefaultState;
      },
    },
    [CLOSE_CALL_AIRCARE]: {
      next(state, action) {
        let newState = clone(state);
        newState.isCallAirCare = false;
        return newState;
      },
    },
    [POP_GIVE_FEEDBACK]: {
      next(state, action) {
        const newState = clone(state);
        newState.isOpenFeedback = true;
        if (action.payload) {
          newState.feedbackForm = {
            fromCity: action.payload.fromCity,
            toCity: action.payload.toCity,
            tripNumber: action.payload.tripNumber,
            tripLegId: action.payload.tripLegId,
          };
        }
        return newState;
      },
      throw(state, action) {
        return popupsDefaultState;
      },
    },
    [CLOSE_GIVE_FEEDBACK]: {
      next(state, action) {
        const newState = clone(state);
        newState.isOpenFeedback = false;
        return newState;
      },
    },
  },
  popupsDefaultState
);
