import ContentLoader from "react-content-loader";

const FlightListLoading = () => {
  let flightLoading = [];
  for (var i = 0; i < 4; i++) {
    flightLoading.push(
      <div key={i} className="cardParentLoader marginBottom8">
        <ContentLoader height={151} width={394} speed={2}>
          <rect x="16" y="20" rx="0" ry="0" width="34" height="12" />
          <rect x="16" y="40" rx="0" ry="0" width="88" height="12" />
          <rect x="16" y="60" rx="0" ry="0" width="108" height="12" />
          <rect x="265" y="20" rx="0" ry="0" width="34" height="12" />
          <rect x="265" y="40" rx="0" ry="0" width="88" height="12" />
          <rect x="265" y="60" rx="0" ry="0" width="108" height="12" />
          <rect x="16" y="88" rx="0" ry="0" width="364" height="1" />
          <rect x="16" y="105" rx="0" ry="0" width="12" height="12" />
          <rect x="16" y="125" rx="0" ry="0" width="12" height="12" />
          <rect x="35" y="105" rx="0" ry="0" width="140" height="12" />
          <rect x="35" y="125" rx="0" ry="0" width="100" height="12" />
        </ContentLoader>
      </div>
    );
  }
  return <div>{flightLoading}</div>;
};

export default FlightListLoading;
