import { execSearchAirports } from "../services/airports";
import { wrapAsyncThunk } from "./utilities";

export const execNotShadedSearchAirports = (
  tripLegName,
  field,
  saveToTmp,
  latitude,
  longitude,
  radiusNm
) => {
  return wrapAsyncThunk(
    execSearchAirports(
      tripLegName,
      field,
      saveToTmp,
      latitude,
      longitude,
      radiusNm
    ),
    { needsShade: false, name: "not-shaded-search-airports" }
  );
};
