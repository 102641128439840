import clone from "clone";
import { handleActions } from "redux-actions";
import {
  AIRCARE_DROPDOWN,
  CLOSE_DROPDOWN,
  MENU_DROPDOWN,
  USER_DROPDOWN,
} from "../../actions/types";

const defaultState = {
  visibility: {
    userDropdown: false,
    menuDropdown: false,
    aircareDropdown: false,
  },
};

export default handleActions(
  {
    [USER_DROPDOWN]: {
      next(state, action) {
        const newState = clone(state);

        newState.visibility.userDropdown = !state.visibility.userDropdown;
        return newState;
      },
    },
    [MENU_DROPDOWN]: {
      next(state, action) {
        const newState = clone(state);

        newState.visibility.menuDropdown = !state.visibility.menuDropdown;
        return newState;
      },
    },
    [CLOSE_DROPDOWN]: {
      next(state, action) {
        const newState = clone(state);

        newState.visibility.menuDropdown = false;
        return newState;
      },
    },
    [AIRCARE_DROPDOWN]: {
      next(state, action) {
        const newState = clone(state);

        newState.visibility.aircareDropdown = !state.visibility.aircareDropdown;
        return newState;
      },
    },
  },
  defaultState
);
