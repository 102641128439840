import envConfig, { EnvType } from "environment-config";
import { useEffect } from "react";
import { useLocation } from "react-router";

export function analyticsEvent(name: string, parameters: any) {
  if (envConfig.envType === EnvType.Prod) {
    window.gtag("event", name, parameters);
  }
}

export function pageView(route: string) {
  if (envConfig.envType === EnvType.Prod) {
    window.gtag("config", "UA-96532132-1", {
      page_path: route,
    });
  }
}

export function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    if (envConfig.envType === EnvType.Prod) {
      pageView(location.pathname);
    }
  }, [location.pathname]);
}
