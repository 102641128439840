import dotEnv from "environment-config";
import { createAction } from "redux-actions";
import {
  LOGOUT,
  FIREBASE_SIGNUP_RESULT,
  GET_ACCOUNTS_FOR_LOGGED_IN_USER,
  GET_LOGGED_IN_USER,
  GET_USER,
  GET_USERS,
  SEARCH_USERS,
} from "../types";

const usersUrl = () => `${dotEnv.apiUrl}/users`;

const usersSearchUrl = (searchTerms) =>
  `${dotEnv.apiUrl}/users?${searchTerms.join("&")}`;

const userUrl = (userId) => `${dotEnv.apiUrl}/users/${userId}`;

const addAstroAccountUrl = (userId, astroUserId) =>
  `${dotEnv.apiUrl}/users/${userId}/astroAccount/${astroUserId}`;

const getAstroAccountsUrl = (userId, astroUserId) =>
  `${dotEnv.apiUrl}/users/${userId}/astroAccount`;

const getUserFromState = (getState) =>
  getState().services.easUsers.current.user;

export const getUsers = createAction(GET_USERS);
export const getUser = createAction(GET_USER);
export const searchUsers = createAction(SEARCH_USERS);
export const getLoggedInUser = createAction(GET_LOGGED_IN_USER);
export const logoutUser = createAction(LOGOUT);
export const getAccountsForLoggedInUser = createAction(
  GET_ACCOUNTS_FOR_LOGGED_IN_USER
);
export const firebaseSignupResult = createAction(FIREBASE_SIGNUP_RESULT);

export const execGetUsers = () => (dispatch, getState) => {
  const url = usersUrl();
  const obj = {
    method: "GET",
    headers: {
      //Currently we can't send the auth token here becuase the server is not expecting it. However, it should be
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      //  'Authorization' : getState().firebaseUser.response.localId,
      "Content-Type": "application/json",
    },
  };

  dispatch(getUsers());
  return fetch(url, obj)
    .then((response) => {
      if (response.ok) {
        return response.json().then((result) => {
          dispatch(getUsers(result.users));
          return result;
        });
      }
      return Promise.reject(new Error("Error getting users"));
    })
    .catch((error) => {
      dispatch(getUsers(error));
      return Promise.reject(error);
    });
};

export const execGetUser = (userId) => (dispatch, getState) => {
  const obj = {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };

  dispatch(getUser(userId));
  return fetch(userUrl(userId), obj)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(new Error("Error getting user"));
    })
    .then((json) => {
      dispatch(getUser(json.user));
      return json.user;
    })
    .catch((error) => {
      dispatch(getUser(error));
      return Promise.reject(error);
    });
};

let loggedInUserIdFromState = function (getState) {
  return getState().services.firebase.firebaseUser.response.uid;
};
export const execGetLoggedInUser = () => (dispatch, getState) => {
  const obj = {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  const userId = loggedInUserIdFromState(getState);

  dispatch(getLoggedInUser());
  return fetch(userUrl(userId), obj)
    .then((response) => {
      if (response.ok) {
        //We don't  get a 404 if the user is not found. We get a null value in the user field
        return response.json().then((result) => {
          if (result.user) {
            dispatch(getLoggedInUser(result.user));
            return result.user;
          }
          dispatch(getLoggedInUser(false));
          return false;
        });
      }
      return Promise.reject(new Error("Error getting user"));
    })
    .catch((error) => {
      dispatch(getLoggedInUser(error));
      return Promise.reject(error);
    });
};

export const execUpdateUser = (userId, fieldUpdates) => (
  dispatch,
  getState
) => {
  const parameters = {
    method: "PUT",
    body: JSON.stringify(fieldUpdates),
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  return fetch(userUrl(userId), parameters)
    .then((response) => {
      if (response.ok) {
        //We've been moving away from the pattern of having these low level actions do updates when they
        //are done. This should probably be moved to higher level actions that dispatch this one
        return dispatch(execGetUser(userId));
      }
      response.json().then((json) => {
        console.warn(`Error updating user: ${JSON.stringify(json)}`);
      });
      return Promise.reject(new Error("Error updating user"));
    })
    .catch((error) => Promise.reject(error));
};

export const execCreateUser = (initialUserData) => (dispatch, getState) => {
  const parameters = {
    method: "POST",
    body: JSON.stringify(initialUserData),
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };
  dispatch(firebaseSignupResult());
  return fetch(usersUrl(), parameters).then((response) => {
    if (response.ok) {
      dispatch(firebaseSignupResult(response.ok));
      //We need to return a promise from every path
      return Promise.resolve();
    }
    //We need to return a promise from every path
    return Promise.reject(
      new Error(`Error creating user. Status: ${response.status}`)
    );
  });
};

export const execSearchUsers = (searchTerm = null) => (dispatch, getState) => {
  const easSearchTerm = searchTerm
    ? searchTerm
    : getState().services.easUsers.all.searchTerm;
  const parameters = {
    method: "GET",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
  };

  dispatch(searchUsers(easSearchTerm));
  console.log("SEARCHING", usersSearchUrl([easSearchTerm]));
  console.log("SEARCHING", parameters);
  return fetch(usersSearchUrl([easSearchTerm]), parameters)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(new Error("Error searching users"));
    })
    .then((json) => {
      dispatch(searchUsers(json.users));
    })
    .catch((error) => {
      dispatch(searchUsers(error));
      return Promise.reject(error);
    });
};

export const execSaveAstroAccountsToUser = (astroUserId, astroAccounts) => (
  dispatch,
  getState
) => {
  const user = getUserFromState(getState);

  const parameters = {
    method: "PUT",
    headers: {
      AuthToken: getState().services.firebase.firebaseUser.response.accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(astroAccounts),
  };

  const url = addAstroAccountUrl(user.systemUserId, astroUserId);

  return dispatch(
    execUpdateUser(user.systemUserId, {
      astroUserIds: [
        ...user.astroUserIds.map((astro) => astro.astroUserId),
        astroUserId,
      ],
    })
  ).then(() =>
    fetch(url, parameters).then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(new Error("Error saving astro accounts to user"));
    })
  );
};

export const execGetAstroAccountsForLoggedInUser = () => {
  return (dispatch, getState) => {
    let obj = {
      method: "GET",
      headers: {
        AuthToken: getState().services.firebase.firebaseUser.response
          .accessToken,
        "Content-Type": "application/json",
      },
    };
    const userId = loggedInUserIdFromState(getState);
    dispatch(getAccountsForLoggedInUser());

    console.log("ERROR122", getAstroAccountsUrl(userId), obj)
    return fetch(getAstroAccountsUrl(userId), obj)
      .then((response) => {
        console.log("ERROR3", response)
        if (response.ok) {
          return response.json().then((result) => {
            dispatch(getAccountsForLoggedInUser(result.accounts));
            return result;
          });
        } else {
          return Promise.reject(new Error("Error getting account for user"));
        }
      })
      .catch((error) => {
        console.log("ERROR4", error)
        dispatch(getAccountsForLoggedInUser(error));
        return Promise.reject(error); 
      });
  }; 
};
