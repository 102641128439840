import envConfig from "environment-config";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "routes";
import {
  aircareDropdown,
  closeDropdown,
  menuDropdown,
  userDropdown,
} from "store/actions/ui/header";
import Header from "../Header";
import WorkingShade from "../WorkingShade";
import Popups from "./Popups";

const ContentFrame = ({ children }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const loggedInEasUser = useSelector(
    (state) => state.services.easUsers.loggedIn.user
  );
  const userName = useSelector((state) =>
    state.services.firebase.firebaseUser.name
      ? state.services.firebase.firebaseUser.name
      : `${loggedInEasUser.firstName} ${loggedInEasUser.lastName}`
  );
  const visibility = useSelector((state) => state.ui.header.visibility);
  const shaded = useSelector((state) => state.ui.asyncOps.workingShade);

  const easUserPending = loggedInEasUser ? loggedInEasUser.status : "";
  const allowAdmin = loggedInEasUser.isEmployee;
  const toggle = {
    userDropdown: () => dispatch(userDropdown()),
    menuDropdown: () => dispatch(menuDropdown()),
    closeDropdown: () => dispatch(closeDropdown()),
    aircareDropdown: () => dispatch(aircareDropdown()),
  };

  const logout = useCallback(() => {
    history(paths.build.logout());
  }, [history]);

  return (
    <div>
      <Header
        userName={userName}
        logout={logout}
        visibility={visibility}
        toggle={toggle}
        isAdmin={allowAdmin}
        easUserPending={easUserPending}
        envConfig={envConfig}
      />
      {children}
      <Popups />
      <WorkingShade shaded={shaded} />
    </div>
  );
};

export default ContentFrame;
