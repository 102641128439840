import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import withRouter from "components/withRouter";
import {
  execShadedGetUser,
  execShadedSendPasswordResetEmail,
  execShadedUpdateUserStatus,
} from "store/actions/ui/eas-users";
import {
  popUserAuthorization,
  popUserResetPassword,
  popUserStatusChange,
} from "store/actions/ui/popups";
import {
  ASTRO_ID_FIELD_PREFIX,
  newUserFieldValue,
  removeEditedAstroId,
  saveAndClearField,
  switchUserEditField,
  updateEditedUserIfNeeded,
} from "store/actions/ui/user-details";
// import UserTransactions from "./UserTransactions";

const AUTHORIZED = "Authorized",
  DEACTIVATED = "Deactivated",
  TEXT_STATUS_BUTTON = {
    Partial: "Authorize User",
    Pending: "Authorize User",
    Authorized: "Deactivate User",
    Rejected: "Authorize User",
    Deactivated: "Activate User",
  },
  USER_STATUS = {
    Partial: "Reject",
    Pending: "Reject",
    Authorized: "Deactivate",
    Rejected: "Authorize",
    Deactivated: "Authorized",
  };

class UserDetails extends Component {
  componentDidMount() {
    const { router } = this.props;
    this.props.fetchUser(router.params.userId);
  }

  componentDidUpdate() {
    if (
      this.props.editField &&
      !this.props.editField.includes(ASTRO_ID_FIELD_PREFIX)
    ) {
      const component = this.refs[this.props.editField];

      component.focus();
    }

    //This is here so that we can update our list of aastroIds after the AuthorizeUserPopup is hidden. There is
    // probably a bette way to handle this
    const updateIfNeeded = this.props.updateUserIfNeeded;

    setTimeout(() => {
      updateIfNeeded();
    }, 0);
  }

  editButtons(fieldName) {
    return this.props.editField === fieldName ? (
      <div className="save">
        <button
          className="buttonStyle usersearch cancel"
          onClick={this.props.switchEditField()}
        >
          Cancel
        </button>
        {this.props.editField.startsWith(ASTRO_ID_FIELD_PREFIX) ? (
          <button
            className="buttonStyle usersearch savedb"
            onClick={this.props.deleteAstroId}
          >
            Delete
          </button>
        ) : (
          <button
            className="buttonStyle usersearch savedb"
            onClick={this.props.saveField}
          >
            Save
          </button>
        )}
      </div>
    ) : (
      <div className="edit">
        <button
          className="buttonStyle usersearch editdata"
          onClick={this.props.switchEditField(fieldName)}
        >
          Edit
        </button>
      </div>
    );
  }

  textFieldInput(fieldName) {
    return (
      <input
        type="text"
        value={this.props.editedUser[fieldName]}
        onChange={(event) => {
          this.props.newFieldValue(event.target.value);
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            this.props.saveField();
          }
        }}
        className="inputtext"
        disabled={this.props.editField !== fieldName}
        ref={fieldName}
      />
    );
  }

  resetPasswordButton(emailAddress) {
    return (
      <div className="edit">
        <button
          className="buttonStyle usersearch savedb"
          onClick={this.props.sendPasswordResetEmail(emailAddress)}
        >
          Reset Password
        </button>
      </div>
    );
  }

  statusButton(status, hasAstroUserIds) {
    if (status !== AUTHORIZED) {
      if (hasAstroUserIds && status === DEACTIVATED) {
        return (
          <button
            onClick={this.props.authorizeUserStatus(USER_STATUS[status])}
            className="button-wrapper buttonStyle deactivate red"
          >
            {TEXT_STATUS_BUTTON[status]}
          </button>
        );
      }
      return (
        <button
          onClick={this.props.popAuthorize(this.props.userId)}
          className="button-wrapper buttonStyle deactivate red"
        >
          {TEXT_STATUS_BUTTON[status]}
        </button>
      );
    }
    return (
      <button
        onClick={this.props.popUserStatus({
          userId: this.props.userId,
          existingStatus: this.props.user.status,
          newStatus: USER_STATUS[status],
        })}
        className="button-wrapper buttonStyle deactivate red"
      >
        {TEXT_STATUS_BUTTON[status]}
      </button>
    );
  }

  render() {
    //The user variable here is just to make the code easier to read below
    const user = this.props.editedUser;

    if (user) {
      const renderedAstroIds = user.astroUserIds.map((item, index) => (
        <div className="userid row control" key={index}>
          {console.log(item)}
          {item.accounts.map((actItem, index) => (
                actItem.accounts !== undefined ? (
                    actItem.accounts.map((subActItem, index) => (
                        <div className="text" key={`${item.astroUserId} - ${index}`}>{item.astroUserId} - {subActItem.accountName} ({subActItem.role} )</div>
                    ))
                ) : (
                    <div className="text"  key={`${item.astroUserId} - ${index}`}>{item.astroUserId} - {actItem.accountName} ({actItem.role} )</div>
                )
          ))}

          <div className="control">
            <div className="deleteControl">
              <button className="buttonStyle usersearch cancel">Cancel</button>
              <button className="buttonStyle usersearch delete">Delete</button>
            </div>
            <div className="col">
              {this.editButtons(`${ASTRO_ID_FIELD_PREFIX}${item.astroUserId}`)}
            </div>
          </div>
        </div>
      ));

      return (
        <div className="row">
          <div className="block-profile ">
            <div className="title">Personal</div>
            <div className="content-block">
              <div className="row control">
                <div className="col">
                  <label>First Name</label>
                </div>
                <div className="col firstName c02">
                  {this.textFieldInput("firstName")}
                </div>
                <div className="col">{this.editButtons("firstName")}</div>
              </div>
              <div className="row control">
                <div className="col">
                  <label>Last Name</label>
                </div>
                <div className="col lastName c02">
                  {this.textFieldInput("lastName")}
                </div>
                <div className="col">{this.editButtons("lastName")}</div>
              </div>
              <div className="row control">
                <div className="col">
                  <label>Email</label>
                </div>
                <div className="col email-column">
                  <span className="inputtext Email">{user.emailAddress}</span>
                  <span className="email-verfied">
                    {user.emailVerified ? "Verified" : "Not Verified"}
                  </span>
                </div>
                <div className="col"></div>
              </div>
              <div className="row control">
                <div className="col">
                  <label>Password</label>
                </div>
                <div className="col password c02">
                  <input
                    type="text"
                    className="inputtext password"
                    disabled
                    defaultValue="********"
                  />
                </div>
                <div className="col">
                  {this.resetPasswordButton(user.emailAddress)}
                </div>
              </div>
              <div className="row control">
                <div className="col">
                  <label>Organization</label>
                </div>
                <div className="col c02">
                  {this.textFieldInput("userSpecifiedAccount")}
                </div>
                <div className="col">
                  {this.editButtons("userSpecifiedAccount")}
                </div>
              </div>
              <div className="row control">
                <div className="col">
                  <label>Account Status</label>
                </div>
                <div className="col email-column">
                  <input
                    type="text"
                    className="inputtext password"
                    disabled
                    value={user.status}
                  />
                </div>
                <div className="col"></div>
              </div>
              <div className="row control">
                <div className="col aircare">
                  <label>Owner Services Employee?</label>
                </div>
                <div className="col">
                  <select
                    disabled={this.props.editField !== "isEmployee"}
                    value={user.isEmployee}
                    onChange={(event) => {
                      this.props.newFieldValue(event.target.value === "true");
                    }}
                    ref="isEmployee"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        this.props.saveField();
                      }
                    }}
                  >
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
                <div className="col">{this.editButtons("isEmployee")}</div>
              </div>
            </div>
          </div>
          <div className="block-profile astro-detail">
            <div className="title">Astro Details</div>
            <div className="content-block">
              <div className="row astro-title">
                <div className="col middle">Astro UserID</div>
                <div className="col idUser">{renderedAstroIds}</div>
              </div>
              <div className="row control">
                <div className="col add c02">
                  <button
                    className="button-wrapper AddUserID"
                    onClick={
                      !this.props.editField &&
                      this.props.popAuthorize(this.props.userId)
                    }
                  >
                    + Add AstroUserID
                  </button>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

UserDetails.contextTypes = { router: PropTypes.object };

UserDetails.propTypes = {
  user: PropTypes.object,
  editedUser: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  fetchUser: PropTypes.func.isRequired,
  updateUserIfNeeded: PropTypes.func.isRequired,
  editField: PropTypes.string,
  saveField: PropTypes.func.isRequired,
  switchEditField: PropTypes.func.isRequired,
  popAuthorize: PropTypes.func.isRequired,
  popUserStatus: PropTypes.func.isRequired,
  authorizeUserStatus: PropTypes.func.isRequired,
  sendPasswordResetEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  userId: ownProps.router.params.userId,
  user: state.services.easUsers.current.user,
  //For Tango (EAS-583): Loading and failed should not be coming from ui.userDetails.
  //They should come from services.easUsers.currentUser right?
  loading: state.services.easUsers.current.loading,
  failed: state.services.easUsers.current.failed,
  editField: state.ui.userDetails.editing,
  editedUser: state.ui.userDetails.editedUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => {
    dispatch(execShadedGetUser(userId)).then(() =>
      dispatch(updateEditedUserIfNeeded())
    );
  },
  updateUserIfNeeded: () => dispatch(updateEditedUserIfNeeded()),
  saveField: () => {
    dispatch(saveAndClearField());
  },
  deleteAstroId: () => {
    dispatch(removeEditedAstroId());
  },
  switchEditField: (fieldName) => () =>
    dispatch(switchUserEditField(fieldName)),
  newFieldValue: (fieldValue) => {
    dispatch(newUserFieldValue(fieldValue));
  },
  popAuthorize: (userId) => () => {
    dispatch(popUserAuthorization(userId));
  },
  popUserStatus: (userStatus) => () => {
    dispatch(popUserStatusChange(userStatus));
  },
  authorizeUserStatus: (userStatus) => () => {
    dispatch(execShadedUpdateUserStatus(userStatus));
  },
  sendPasswordResetEmail: (emailAddress) => () => {
    dispatch(execShadedSendPasswordResetEmail(emailAddress)).then(() => {
      dispatch(popUserResetPassword());
    });
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
