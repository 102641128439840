import { combineReducers } from "redux";
import airports from "./airports";
import astroUsers from "./astro-users";
import easTripRequest from "./eas-book-trip";
import easFlightSurveys from "./eas-flight-surveys";
import easTrips from "./eas-trips";
import easUsers from "./eas-users";
import firebase from "./firebase-user";
import signUp from "./sign-up";

export default combineReducers({
  firebase,
  astroUsers,
  easTrips,
  easUsers,
  easFlightSurveys,
  easTripRequest,
  airports,
  signUp,
});
