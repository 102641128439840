import { combineReducers } from "redux";
import { handleAction } from "redux-actions";
import { FIREBASE_SIGNUP_RESULT } from "../../actions/types";

const defaultSignupResult = { loading: "", typeOfModal: "", isOpen: false };

const result = handleAction(
  FIREBASE_SIGNUP_RESULT,
  {
    next(state, action) {
      if (!action.payload) {
        return { loading: "unknown", typeOfModal: null };
      } else if (action.payload) {
        return { loading: true, typeOfModal: true, isOpen: true };
      }
      return { loading: false, typeOfModal: action.payload, isOpen: true };
    },
    throw(state, action) {
      console.log(`Error checking auth: ${action.error}`);
      return defaultSignupResult;
    },
  },
  defaultSignupResult
);

export default combineReducers({ result });
