import iconError from "images/error-icon.png";
import iconSuccess from "images/success-icon.png";
import { Link } from "react-router-dom";
import { paths } from "routes";
import { Button, Image, Modal } from "semantic-ui-react";

export default function SignUpModal({ isOpenModal, typeOfModal, logout }) {
  return (
    <Modal className="forgotModal signUpModal" size="tiny" open={isOpenModal}>
      {typeOfModal ? (
        <div className="boxPadding">
          <Modal.Header>Congrats!</Modal.Header>
          <Modal.Content>
            <Image centered src={iconSuccess} />
            <Modal.Description>
              <p>Welcome to Airshare! Your account has been</p>
              <p>created and you will be flying in no time</p>
            </Modal.Description>
            <Button className="checkEmail" onClick={logout}>
              Login
            </Button>
          </Modal.Content>
        </div>
      ) : (
        <div className="boxPadding">
          <button className="btnClose backToLogin" onClick={logout}>
            BACK TO LOGIN
          </button>
          <Modal.Header>Uh Oh!</Modal.Header>
          <Modal.Content>
            <Image centered src={iconError} />
            <Modal.Description>
              <p>Something went wrong while processing your account.</p>
              <p>Would you like to try again?</p>
            </Modal.Description>
            <Link
              className="checkEmail ui button"
              to={paths.build.createUser()}
            >
              Try Again
            </Link>
          </Modal.Content>
        </div>
      )}
    </Modal>
  );
}
